import { Link } from "react-router-dom";

function Test() {
    return (
        <div className=" bg-black">
        
    <header class="bg-gradient-to-r from-red-300 to-indigo-600 text-white h-screen flex items-center">
        <div class=" mx-auto text-center">
            <h1 class="text-8xl font-bold mb-4">Page Not Found</h1>
            <Link to='/'>
            <button class="mt-8 bg-white text-blue-700 py-2 px-6 rounded-full font-semibold hover:bg-blue-100 transition duration-300">Go to Home</button>
            </Link>
              </div>
    </header>
        </div>
    );
}

export default Test;