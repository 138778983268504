import React, { useContext } from "react";
import { globleinfo } from "../../App";
import images from "../Img";

function ExtraCarecular() {
  const { tempLang } = useContext(globleinfo);
  return (
    <div className=" border-t-2 p-4 xl:p-0 overflow-hidden xl:mx-20 mt-16">
      <div className="flex justify-between   py-6">
        <div data-aos="fade-left" data-aos-delay="900">
          <span className="text-xl text-black lg:text-4xl uppercase lg:font-extrabold">
            {" "}
           {tempLang.home_Header.extraCurricular.extraCurricular}
          </span>
          <span className="ml-2 text-xl text-blue-400 lg:font-normal uppercase lg:text-4xl">
          {tempLang.home_Header.extraCurricular.activity}
          </span>
        </div>
      </div>
      <div className="lg:flex lg:space-x-4 relative">
        <div>
          <div
            data-aos="fade-left"
            data-aos-delay="900"
            className="lg:flex lg:space-x-4  xl:h-96 mt-2 relative "
          >
            <img
              className="lg:w-1/2 border-2 transform hover:scale-105 duration-1000  shadow-lg"
              src={images.womenkabbadi}
              alt="abc"
            />
            <img
              className="lg:w-1/2 border-2 transform hover:scale-105 duration-1000  shadow-lg"
              src={images.bhalaphak}
              alt="abc"
            />
          </div>
          <div
            data-aos="fade-right"
            data-aos-delay="900"
            className="lg:flex lg:space-x-4 xl:h-128 mt-4 relative"
          >
            <img
              className="lg:w-1/2 border-2 transform hover:scale-105 duration-1000  shadow-lg"
              src={images.nccImage}
              alt="abc"
            />
            <img
              className="lg:w-1/2 lg:mt-0 mt-4 border-2   shadow-lg"
              src={images.athelete}
              alt="abcd"
            />
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-delay="900"
          className="border-2 lg:mt-2 transform hover:scale-105 duration-1000 shadow-lg"
        >
          <img
            className="w-full  h-64 lg:h-full  "
            src={images.nccImage2}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default ExtraCarecular;
