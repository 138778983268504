
import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default function Notification() {

const firebaseConfig = {
  apiKey: "AIzaSyD3ulrdi7emnEWJDObv9oWpIlKM2NAc0qQ",
  authDomain: "pushnotifiction-534c3.firebaseapp.com",
  projectId: "pushnotifiction-534c3",
  storageBucket: "pushnotifiction-534c3.appspot.com",
  messagingSenderId: "200821163334",
  appId: "1:200821163334:web:459bf605bb31983195d147",
  measurementId: "G-LQZG1NZELZ"
};
const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);

getToken(messaging, {
  vapidKey:"BCGftws3iJT5NUcQV18U-r8Okvr1Ohmgdh45ix2-Wk2A1Qy6bJRAN3GQGFpEyZMXiq6gTdmeXFDVUW1bwhI5BPw",
})
  .then((currentToken) => {
    if (currentToken) {
      console.log("Firebase Token", currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
  });

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
}); 
  return (
    <div></div>
  )
}
