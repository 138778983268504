import React, { useContext, useEffect, useState } from 'react'
import { useLocation, } from 'react-router-dom';
import PopUpUi from '../popup/PopUpUi';
import { globleinfo } from '../../App';
import { apiCall } from '../../ApiServices/apiCallManager';

function EventList() {
  const { tempLang,} = useContext(globleinfo);
  const [showpopup, setShowPopup] = useState(false)
  const [popuplist, setPopuplist] = useState()
  const location = useLocation();
  const [eventList, setEventList] = useState([]);

  const eventData = location.state?.eventData || null;
  console.log(eventData)
   const handlePopupUi=(item)=>{
    setShowPopup(!showpopup)
    setPopuplist(item)
    console.log("handle popup uis button is clicked")
   }
   const onClose=()=>{
    setShowPopup(false)
   }

   const fatchData = async () => {
    apiCall("application/json","get","event","get/all")
      .then(async (res) => {
        // console.log(res.data)
        var eventData = await res.data;
        setEventList(eventData);
       
      });
  };
   useEffect(()=>{
    fatchData()
   },[])
  return ( 
    <>
     <div className=' flex flex-col xl:p-6 pt-16 p-4 items-center justify-items-center'>
            <span className=' text-6xl text-center text-gray-900'>
            {tempLang.home_Header.EventList.eventDetails.heading}
            </span>
            <p className=' max-w-3xl mt-4 mb-8 text-justify'>
            {tempLang.home_Header.EventList.eventDetails.description}</p>
        </div>

        <div className="flex flex-wrap item-center justify-around">
     
    {eventList?.map((item, index) => (
        <div className=' cursor-pointer' onClick={()=>handlePopupUi(item)}>
      <div className=' flex xl:flex-row flex-col  border shadow-lg p-1 m-3 pr-4'>
        <div className="w-20 h-14 border-green-300  rounded-md border-4 mx-4 my-4 shadow-xl">
      <p className="text-green-400 font-semibold flex justify-center">{item.date.substring(item.date.length-2,item.date.length)}</p>
      <p className="text-green-400 text-sm text-center font-semibold mb -1 ">{item.date.substring(item.date.length-5,item.date.length-3)},{item.date.substring(0,4)}</p>
      </div>
    <div className='ml-2 xl:ml-0'>
      <p className="text-sm mt-4 text-gray-500 font-medium">{item.title}</p> 
      <p className="text-sm text-gray-500 font-medium">{item.discription}</p>
      <div className="flex space-x-2">
        <svg className="w-4 h-4 mt-1 text-green-400 text-sm font-semibold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <p className="text-sm text-gray-500 font-medium">{item.startTime} to {item.endTime} </p>
      </div>
    </div>
      </div>
      </div>
    ))}
 { showpopup && <PopUpUi popuplist={popuplist} onClose={onClose} />}
  </div>
    </> 
    
  )
}

export default EventList