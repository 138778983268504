import Nav from "./Nav.js";
import TopNavbar from "./TopNavbar.js";
import { BrowserRouter, Link, Route, Router } from "react-router-dom";
import React, { Fragment, useState, useEffect, useContext } from "react";
import Sidebar from "./Sidebar";
import About from "../about/About.js";
import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "./logo.jpg";
import { globleinfo } from "../../App.js";





function Navbar(props) {
  const { languages, tempLang, setTempLang } = useContext(globleinfo);
  const history = useNavigate();
  const [show, setshow] = useState(1);

  const ShowMenu = () => {
    setshow(!show);
    console.log("Show");
  };

  const handleSideBaarItemClickHandler = (item) => {
    setshow(!show);
    history("/" + item);
    console.error("/" + item);
  };

  const HideMenu = () => {
    setshow(!show);
  };

  return (
    <div className="fixed top-0 z-20 flex-col bg-blue-900  py-1 lg:sticky lg:w-full w-full">
      {/* <TopNavbar /> */}
      <div className="flex relative xl:justify-end xl:items-center items-center justify-between w-full px-2 xl:py-2">
        <div className=" xl:absolute xl:top-0 xl:left-4  flex">
          <Link className=" bg-blue-900 xl:pt-4 xl:p-2 rounded-full" to="/">
            {/* <svg className="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z">
                            </path>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222">
                            </path>
                        </svg> */}
            <img
              className="xl:w-16 xl:h-16 w-10 h-10 rounded-full"
              src={logo}
              alt="home"
            />
          </Link>
          <span class="self-center xl:block hidden text-xl mb-4 text-white font-semibold whitespace-nowrap dark:text-white">
            {tempLang.footer.school_Name1}{" "}
            <span>{tempLang.footer.school_Name2}</span>{" "}
            <span>{tempLang.footer.school_Name3}</span>{" "}
            <span>{tempLang.footer.school_Name4}</span>
          </span>
        </div>

        <Nav />

        <div className="block lg:hidden">
          {show ? (
            // <svg className="w-16 h-16 lg:hidden" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            //     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16">
            //     </path>
            // </svg>
            <div className="">
              <div className="">
                <svg
                  className="w-12 h-12 text-slate-200  cursor-pointer lg:hidden"
                  onClick={() => ShowMenu()}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                {/*{ <div className="fixed"><Sidebar handleClick={handleSideBaarItemClickHandler} /></div>}*/}
              </div>
            </div>
          ) : (
            <div className=" w-full">
              <Sidebar
                HideMenu={HideMenu}
                handleClick={handleSideBaarItemClickHandler}
              />
            </div>
          )}
        </div>
        {
          // Mobile sidebar component to display here
        }
      </div>
    </div>
  );
}

export default Navbar;
