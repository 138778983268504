function generatePassword(length) {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#$%^&*()_-+=<>?/[]{},.:;";

  const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;
  const totalCharSets = 4;

  if (length < totalCharSets) {
    throw new Error(
      "Password length must be at least equal to the number of character sets."
    );
  }

  let password = "";

  // Include at least one character from each character set
  password += getRandomChar(uppercaseChars);
  password += getRandomChar(lowercaseChars);
  password += getRandomChar(numberChars);
  password += getRandomChar(specialChars);

  // Fill the remaining characters with a mix of all character sets
  for (let i = totalCharSets; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    password += allChars.charAt(randomIndex);
  }

  return password;
}

function getRandomChar(characters) {
  const randomIndex = Math.floor(Math.random() * characters.length);
  return characters.charAt(randomIndex);
}

function generateStrongPassword(length = 12) {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const specialChars = "!@#$%^&*()_-+=<>?/[]{},.:;";

  const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

  const getRandomChar = (characters) => {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters.charAt(randomIndex);
  };

  const password =
    getRandomChar(uppercaseChars) +
    getRandomChar(lowercaseChars) +
    getRandomChar(numberChars) +
    getRandomChar(specialChars) +
    Array.from({ length: length - 4 }, () => getRandomChar(allChars)).join("");

  return password;
}

function isStrongPassword(password) {
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_\-+=<>?/[{\]}.:;]/.test(password);

  console.log("hasUppercase:", hasUppercase);
  console.log("hasLowercase:", hasLowercase);
  console.log("hasDigit:", hasDigit);
  console.log("hasSpecialChar:", hasSpecialChar);

  const isValid =
    hasUppercase &&
    hasLowercase &&
    hasDigit &&
    hasSpecialChar &&
    password.length >= 12;

  console.log("Is Password Valid:", isValid);

  return isValid;
}

const passwordHelper = {
  isStrongPassword,
  generateStrongPassword,
  generatePassword,
};

export default passwordHelper;
