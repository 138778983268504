import React, { useEffect, useState } from "react";

function Animation1({text}) {
    const [visibleWords, setVisibleWords] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const element = document.getElementById('fadeElement'); // Replace with your actual element ID
        const elementPosition = element?.offsetTop;
  
        // Calculate the number of words to show based on scroll position
        const wordsToShow = Math.floor(
          (scrollPosition - elementPosition + window.innerHeight* 1.16) /
            (window.innerHeight / words.length)
        );
  
        // Update the state to control the visibility
        setVisibleWords(Math.min(words.length, Math.max(0, wordsToShow)));
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    var wordArray = text.split(' ');
    console.log(wordArray)
    const words = text.split(' ');
  return (
    <div>
      <div class="text-center flex items-center justify-center flex-col h-screen">
      <h2 id="fadeElement" className="font-title mx-auto font-black leading-none">
          {words.map((word, index) => (
            <span
              key={index}
              className={`font-title mx-auto text-[clamp(2.5rem,6vw,4.5rem)] font-black leading-none transition-opacity ${
                index < visibleWords ? 'opacity-100' : 'opacity-25'
              }`}
            >
              {word}
              {index < words.length - 1 && ' '}
            </span>
          ))}
        </h2>       
      </div>
    </div>
  );
}

export default Animation1;
