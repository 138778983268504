import React,{useContext, useEffect, useState} from 'react';
import images from '../Img';
import { apiCall } from '../../ApiServices/apiCallManager';
import { globleinfo } from '../../App';

function HeadofDept() {
    const { tempLang,} = useContext(globleinfo);
    const [data, setData] = useState([]);

    const fatchData = async()=>{
        apiCall(null,"get","teacher","get/all").then((res) => {
            setData([...res.data]);
          });
        };

        useEffect(() => {
           fatchData();
        }, []);
    
        const getImagePath = (filename) => {
            var  basePath = "/upload/";
            return basePath.concat(filename)

        }

    return(
        <div className="mx-4 mb-8 lg:mx-20 flex flex-col space-y-5">
             <h1 className="text-3xl border-b-2 border-gray-400 pb-3 font-bold">
             {tempLang.faculty. heads_of_Departments.heading}
            </h1>

            <div className="bg-gray-100  my-4">

    <div class="container mx-auto xl:flex-row flex-col  flex items-center justify-center">

      
        <div class="xl:w-1/2 w-full">
            <img src={images.principal} alt="Principal Image" class="rounded-lg shadow-lg"/>
        </div>

        
        <div class="xl:w-1/2 w-full xl:p-8 p-4">
            <h2 class="text-3xl font-bold mb-2"> {tempLang.faculty. heads_of_Departments.heading}</h2>
            <p class="text-gray-600 mb-4"> {tempLang.faculty. heads_of_Departments.principal_Name}</p>
            <p class="text-gray-600 mb-4"> {tempLang.faculty. heads_of_Departments.Education_Details}</p>

            <div class="mb-4">
                <h3 class="text-xl font-semibold mb-2"> {tempLang.faculty. heads_of_Departments.Personal_Details}</h3>
                <p class="text-gray-700"> {tempLang.faculty. heads_of_Departments.Principal_Statement}</p>
            </div>

           
            <div class="flex items-center space-x-4">
                <a href="#" class="text-gray-600 hover:text-blue-500">
                    <i class="fab fa-facebook-square text-2xl"></i>
                </a>
                <a href="#" class="text-gray-600 hover:text-blue-500">
                    <i class="fab fa-twitter-square text-2xl"></i>
                </a>
                <a href="#" class="text-gray-600 hover:text-blue-500">
                    <i class="fab fa-linkedin text-2xl"></i>
                </a>
                
            </div>
        </div>

    </div>

</div>

           
            
            
        </div>
    )
}

export default HeadofDept;