import React, { useState, useEffect ,useContext} from "react";
import { apiCall } from "../../ApiServices/apiCallManager";
import { globleinfo } from '../../App';


function TableCalender() {
  const [event, setEvent] = useState([]);
  const { tempLang,} = useContext(globleinfo);
  const fatchData = async () => {
    apiCall(null,"get","event","get/all")
      .then(async (res) => {
        const eventData = await res.data;
        const eventLength = eventData?.length >= 5 ? 5 : eventData?.length;
        const slicedEvent = eventData.slice(0, eventLength);
        setEvent([...slicedEvent]);
        console.log(event);
      });
  };

  useEffect(() => {
    fatchData();
  }, []);
  return (
    <div className="flex xl:mt-0 mt-16 ">
        <div className=" flex items-center justify-center p-6  flex-col w-full">
      <div className=" flex flex-col items-center justify-items-center">
        <span className=" xl:text-6xl text-4xl text-center text-gray-900">
         {tempLang.acedemic_Calender.heading}
        </span>
        <p className=" max-w-3xl mt-4 mb-8 text-justify">
          {tempLang.acedemic_Calender.description}</p>
      </div>

      <div class="relative overflow-x-auto w-full mx-auto ">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3 flex flex-row whitespace-nowrap">
              {tempLang.acedemic_Calender.acedemic_Table.date}
              </th>
              <th scope="col" class="px-6 py-3">
              {tempLang.acedemic_Calender.acedemic_Table.event}
              </th>
              <th scope="col" class="px-6 py-3">
                {tempLang.acedemic_Calender.acedemic_Table.eventDescription}
              </th>
            </tr>
          </thead>
          <tbody>
            {event.map((item) => (
              <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.startTime} <p className=" font-bold px-2"> to </p>
                  {item.endTime}
                </th>
                <td class="px-6 py-4">{item.title}</td>
                <td class="px-6 py-4">{item.discription}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
    
  );
}

export default TableCalender;
