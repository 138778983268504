import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiCall } from "../../ApiServices/apiCallManager";
import { globleinfo } from '../../App';
function News() {
  const {tempLang} = useContext(globleinfo);
  const [notice, setNotice] = useState([]);
  const [event, setEvent] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [eventList, setEventList] = useState([]);

  const fatchData = async () => {
    apiCall("application/json","get","notice","get/all")
      .then(async (res) => {
        const jsonData = await res.data;
        setNoticeList(jsonData);
        const noticeData = jsonData.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        console.log(noticeData);
        const noticeLength = noticeData?.length >= 5 ? 5 : noticeData?.length;
        const slicedNotice = noticeData.slice(0, noticeLength);
        setNotice([...slicedNotice]);
        console.log(slicedNotice)
        console.log(notice)
      });
      apiCall("application/json","get","event","get/all")
      .then(async (res) => {
        // console.log(res.data)
        var eventData = await res.data;
        setEventList(eventData);
        const eventLength = eventData?.length >= 6 ? 6 : eventData?.length;
        const slicedEvent = eventData.slice(eventData.length - eventLength);

        // const slicedEvent = eventData.slice(0, eventLength);
        setEvent([...slicedEvent]);
        console.log(slicedEvent)
        console.log(eventData);
        console.log(event);
      });
  };
  useEffect(() => {
    fatchData();
  }, []);

  return (
    <div className="lg:flex lg:justify-around px-4 mt-4 ">
      <div className="my-4 w-full xl:w-2/3">
        { event.length !== 0 && <div className="flex justify-between">
          <h className="font-semibold border-l-4 border-green-400 mx-2 px-2 text-xl">
            {tempLang.home_Header.EventList.event}
          </h>
          <button className="text-green-400 z-10 font-medium text-lg">
            {console.log(eventList)}
            <Link
              to={{ 
                pathname: "/eventlist", 
                state: { eventData: eventList },
                 }}>
            {tempLang.home_Header.EventList.readMore}..
            </Link>
          </button>
        </div>}
        
        <div className="flex xl:flex-wrap flex-row justify-between overflow-x-auto w-full">
          {event.map((item, index) => (
            <div key={index}  className=" flex flex-row border shadow-lg p-1 m-3 pr-4">
              <div className="w-20 h-14 border-green-300  rounded-md border-4 mx-4 my-4 shadow-xl">
                <p className="text-green-400 font-semibold flex justify-center">
                  {item.date.substring(item.date.length - 2, item.date.length)}
                </p>
                <p className="text-green-400 text-sm text-center font-semibold mb -1 ">
                  {item.date.substring(
                    item.date.length - 5,
                    item.date.length - 3
                  )}
                  ,{item.date.substring(0, 4)}
                </p>
              </div>
              <div className="">
                <p className="text-sm mt-4 text-gray-500 font-medium">
                  {item.title}
                </p>
                <p className="text-sm text-gray-500 font-medium">
                  {item.discription}
                </p>
                <div className="flex space-x-2">
                  <svg
                    className="w-4 h-4 mt-1 text-green-400 text-sm font-semibold"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <p className="text-sm text-gray-500 font-medium">
                    {item.startTime} to {item.endTime}{" "}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="my-4 ">
      { notice !== 0 &&   <div className="flex justify-between">
          <h className="px-4 mx-4 text-xl font-semibold border-l-4 border-green-400">
           {tempLang.home_Header.NoticeList.notice}
          </h>
          <button className="text-lg font-medium text-green-400">
            {/* {console.log(noticeList)} */}
            <Link
              to={{
                pathname: "/noticelist",
                state: { noticeData: noticeList },
              }}
            >
            {tempLang.home_Header.NoticeList.readMore}
            </Link>
          </button>
        </div>}
        <div className="flex xl:flex-col flex-row justify-between overflow-x-auto w-full">
        {notice.map((element, index) => (
          <div
            key={index}
            className=" flex flex-row items-center justify-start border p-1 m-3 pr-4"
          >
            <div className="flex px-4 space-x-2">
              <svg
                className="w-4 h-4 mt-1 text-sm font-semibold text-green-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <p className="text-sm font-medium text-gray-500">
                {element.date}{" "}
              </p>
            </div>
            <p className="px-4 text-sm font-medium text-gray-500">
              {element.title}{" "}
            </p>
            <p className="px-4 text-sm font-medium text-gray-500">
              {element.discripton}{" "}
            </p>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}
export default News;
