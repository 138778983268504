import { useContext } from "react";
import { globleinfo } from "../../App.js";
function Departments(params) {
  const { languages, tempLang, setTempLang } = useContext(globleinfo);
       return (
         <div className="">
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 w-full text-sm px-2  lg:w-1/5 bg-green-600 cursor-wait font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_5}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full relative mb-1  bg-gray-200 lg:mx-8  flex justify-start p-8 xl:pl-8 rounded-lg  text-white font-serif">
               <span className=" space-y-2 grid xl:grid-cols-3 xl:mt-0 mt-8  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.hamara_parivesh}
                     </div>
                   </div>
                 </li>
                 
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 w-full text-sm px-2  lg:w-1/5 bg-green-600 cursor-wait font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_6}
             </h>
             <section className="w-full h-full relative xl:mt-0 mt-2 mb-1 bg-gray-200 lg:mx-8 flex justify-start p-8 rounded-lg  text-white font-serif">
               
                <span className=" space-y-2 grid xl:grid-cols-3 xl:mt-0 mt-8  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.health}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.science}
                     </div>
                   </div>
                 </li>  
               </span>
                 {/* <img className="w-52 h-52 border-4 border-white origin-bottom-right transform rotate-45" src="https://images.unsplash.com/photo-1582637564544-936320f66d9b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80" alt="flag"/>
             <img className="w-52 h-52  border-4 border-white origin-center transform rotate-45" src="https://images.unsplash.com/photo-1581094787965-53e07f34bb6f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="flag"/>
             <img className="w-52 h-52  border-4 border-white  transform rotate-45" src="https://images.pexels.com/photos/769525/pexels-photo-769525.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="flag"/>
              */}
               
               {/* <p className="bg-gray-900  hidden lg:block transform hover:scale-110 duration-1000 p-2 absolute bottom-0 left-5 rounded-lg w-56 order-50"> Civil engineering is a professional engineering discipline that deals with the design, construction </p> */}
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 w-full text-sm px-2  lg:w-1/5 bg-green-600 cursor-wait font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_7}
             </h>
             <section className="w-full h-full relative xl:mt-0 mt-2 mb-1 bg-gray-200 lg:mx-8 flex justify-start p-8 rounded-lg  text-white font-serif">
              
                <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.health}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.science}
                     </div>
                   </div>
                 </li>
                 
               </span>
                 {/* <img className="w-52 h-52 border-4 border-white origin-bottom-right transform rotate-45" src="https://images.unsplash.com/photo-1582637564544-936320f66d9b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80" alt="flag"/>
             <img className="w-52 h-52  border-4 border-white origin-center transform rotate-45" src="https://images.unsplash.com/photo-1581094787965-53e07f34bb6f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="flag"/>
             <img className="w-52 h-52  border-4 border-white  transform rotate-45" src="https://images.pexels.com/photos/769525/pexels-photo-769525.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="flag"/>
              */}
               
               {/* <p className="bg-gray-900  hidden lg:block transform hover:scale-110 duration-1000 p-2 absolute bottom-0 left-5 rounded-lg w-56 order-50"> Civil engineering is a professional engineering discipline that deals with the design, construction </p> */}
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 w-full text-sm px-2  lg:w-1/5 bg-green-600 cursor-wait font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_8}
             </h>
             <section className="w-full h-full relative xl:mt-0 mt-2 mb-1 bg-gray-200 lg:mx-8 flex justify-start p-8 rounded-lg  text-white font-serif">
               
               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.health}
                     </div>
                   </div>
                 </li>
                 
               </span>
                 {/* <img className="w-52 h-52 border-4 border-white origin-bottom-right transform rotate-45" src="https://images.unsplash.com/photo-1582637564544-936320f66d9b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80" alt="flag"/>
             <img className="w-52 h-52  border-4 border-white origin-center transform rotate-45" src="https://images.unsplash.com/photo-1581094787965-53e07f34bb6f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="flag"/>
             <img className="w-52 h-52  border-4 border-white  transform rotate-45" src="https://images.pexels.com/photos/769525/pexels-photo-769525.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="flag"/>
              */}
              
               {/* <p className="bg-gray-900  hidden lg:block transform hover:scale-110 duration-1000 p-2 absolute bottom-0 left-5 rounded-lg w-56 order-50"> Civil engineering is a professional engineering discipline that deals with the design, construction </p> */}
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_9}
             </h>
             <section className="w-full h-full relative xl:mt-0 mt-2 mb-1 bg-gray-200 lg:mx-8 flex justify-start p-8 rounded-lg  text-white font-serif">
               
               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.life_science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                       {tempLang.department.physical_science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_10}
             </h>
             <section className="w-full h-full relative xl:mt-0 mt-2 mb-1 bg-gray-200 lg:mx-8 flex justify-start p-8 rounded-lg  text-white font-serif">
              
               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.life_science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                       {tempLang.department.physical_science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_11_arts}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full mb-1 bg-gray-200 lg:mx-8  flex justify-start p-8 rounded-lg  text-white font-serif">
               {/* <img className="w-full mx-10 border-white border-4 hidden lg:block" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg"/> */}

               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.political_science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                      {tempLang.department.economics}

                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.environment}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_11_science}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full mb-1 bg-gray-200 lg:mx-8  flex justify-start p-8 rounded-lg  text-white font-serif">
               {/* <img className="w-full mx-10 border-white border-4 hidden lg:block" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg"/> */}

               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                       {tempLang.department.physics}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.chemistry}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.biology}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_11_commerce}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full mb-1 bg-gray-200 lg:mx-8  flex justify-start p-8 rounded-lg  text-white font-serif">
               {/* <img className="w-full mx-10 border-white border-4 hidden lg:block" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg"/> */}

               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                      {tempLang.department.economics}

                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.business_studies}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.environment}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.accountancy}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_12_arts}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full mb-1 bg-gray-200 lg:mx-8  flex justify-start p-8 rounded-lg  text-white font-serif">
               {/* <img className="w-full mx-10 border-white border-4 hidden lg:block" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg"/> */}

               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.political_science}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                      {tempLang.department.economics}

                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.geography}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.environment}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.history}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_12_science}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full mb-1 bg-gray-200 lg:mx-8  flex justify-start p-8 rounded-lg  text-white font-serif">
               {/* <img className="w-full mx-10 border-white border-4 hidden lg:block" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg"/> */}

               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                       {tempLang.department.physics}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.chemistry}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.biology}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
           <div className="flex flex-col xl:mt-0 mt-4 xl:flex xl:flex-row">
             <h className="h-10 z-10 lg:w-1/5 w-full px-2 text-sm bg-green-600 font-semibold text-white rounded-md sticky lg:top-28 top-16 lg:left-4 flex justify-center items-center">
             {tempLang.department.class_12_commerce}
             </h>
             <section className="w-full xl:mt-0 mt-2 h-full mb-1 bg-gray-200 lg:mx-8  flex justify-start p-8 rounded-lg  text-white font-serif">
               {/* <img className="w-full mx-10 border-white border-4 hidden lg:block" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg"/> */}

               <span className=" space-y-2 grid xl:grid-cols-3  grid-cols-1 gap-y-4 w-full">
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.hindi}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.english}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.math}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                      {tempLang.department.economics}

                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.business_studies}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                     {tempLang.department.environment}
                     </div>
                   </div>
                 </li>
                 <li className=" text-lg  list-none ">
                   <div className="flex flex-row space-x-4">
                     <div className="mt-3  cursor-pointer">
                       <img
                       className=" rounded-full"
                         width="20"
                         height="20"
                         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU"
                         alt="programDetails image1"
                       ></img>
                     </div>
                     <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 text-gray-900 hover:text-yellow-400 hover:scale-110 ">
                        {tempLang.department.accountancy}
                     </div>
                   </div>
                 </li>
               </span>
             </section>
           </div>
         </div>

       );
   }
   
   export default Departments