import React from "react";
import images from "../Img";
function Event(params) {
    return(
        <div className="h-auto lg:mx-20 mx-4">
           <h1 className="font-bold text-2xl mb-4">Our Cultural Event</h1>
           <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.sport4} alt="event"></img>
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.sport1} alt="event1"></img>
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.sport3} alt="event2"></img>
                
           </div>

           <h1 className="font-bold text-2xl my-5">Our Sports</h1>
           <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.sport5} alt="event3"></img>
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.womenkabbadi} alt="event4"></img>
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.athelete} alt="event5"></img>
                <img className="h-56 w-96 transform hover:scale-110 motion-reduce:transform-none" src={images.sport} alt="event6"></img>
               
           </div>
          
        </div>
    );
}

export default Event;