import React, { useState } from 'react'

function CookiesConsent() {
    const [showBanner, setShowBanner] = useState(true);

    const handleAccept = () => {
        // Set a cookie to record that the user has accepted the cookies policy
        document.cookie = "cookiesAccepted=true; max-age=31536000;";
        document.cookie = "myData=hello World; cookiesAccepted=true; max-age=31536000;";
        document.cookie = "langauge=en; cookiesAccepted=true; max-age=31536000;";
        // Hide the banner
        setShowBanner(false);
      };
    
      const cookiesAccepted = document.cookie.includes("cookiesAccepted=true");
    
  return (
    <div>
         {showBanner && !cookiesAccepted && (
         <div className="fixed w-full bottom-0 z-50 transform hover:scale-100 duration-1000">
         <div class="bg-green-100 border-t-4 border-green-500 rounded-b text-black-900 px-4 py-3 shadow-md" role="alert">
 <div className=' flex xl:flex-row flex-col xl:items-start items-end justify-end xl:justify-between'>
 <div class="flex">
    <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <div>
      <p class="xl:font-bold font-semibold">collect data to improve your experience on our site</p>
      <p class="text-xs xl:text-sm">We use cookies & other similar technology to collect data to improve your experience on our site, as described in our Privacy Policy.</p>
    </div>
  </div>
  <div className=' flex flex-row items-end justify-end xl:mt-0 mt-4 space-x-8 xl:mr-10'>
  <button className=' focus:outline-none'>Manage Reference</button>
    <button onClick={handleAccept} className=' px-6 focus:outline-none py-1 rounded-lg text-white bg-blue-900'>Accept</button>
   
  </div>
 </div>
</div>
</div>
         )}
    </div>
  )
}

export default CookiesConsent