import React, { useContext } from "react";
import images  from "../Img/index";
import { globleinfo } from "../../App";
//  const about = `/about.jpg`

function About() {
  const { tempLang,} = useContext(globleinfo);
  return (
    <div className=" flex flex-col">
      <header class="bg-center bg-fixed bg-no-repeat  bg-cover h-96 relative">
        <div>
          <img
            className=" h-96 w-full object-fill"
            src="https://images.unsplash.com/photo-1494949649109-ecfc3b8c35df?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="about images"
          ></img>
        </div>
        <div class=" bg-opacity-50 absolute inset-x-0 inset-y-0 bg-black flex items-center justify-center">
          <div class="mx-4 text-center">
            <h1 class="text-gray-100 font-extrabold text-3xl mt-8 xl:mt-0 xs:text-4xl md:text-5xl">
              <span class="text-white"></span>{tempLang.about.about_Hader.heading}
            </h1>
            <h2 class="text-gray-200 font-normal  text-justify max-w-xl  p-2 text-lg leading-tight">
            {tempLang.about.about_Hader.description}</h2>
          </div>
        </div>
      </header>

      <div className=" flex xl:flex-row mt-8 xl:mt-0 flex-col-reverse xl:space-x-10 space-x-1 items-center justify-between xl:mx-20 mx-1">
        <div class="flex xl:flex-row p-2 xl:p-0 flex-col items-center mt-6 xl:-mt-24 xl:mt-0 justify-center w-full max-w-2xl ">
          <div class=" h-96  overflow-hidden bg-white rounded-lg shadow-lg xl:scale-110">
            <img
              className="w-full h-full object-cover border-2 shadow-lg p-1 "
              src={images.study1}
              alt="studentLifeImages2"
            ></img>
          </div>
          <div class=" h-96  z-10 xl:-ml-40 ml-0 bg-white rounded-lg shadow-lg mt-8 xl:mt-64">
            <img
              className="w-full h-full  border-2 object-cover shadow-lg p-1 "
              src={images.learn}
              alt="studentLifeImages2"
            ></img>
          </div>
        </div>
        <div className=" flex flex-col items-center justify-between">
          <div class="flex-1 xl:p-10 px-4">
            {/* <h1 className=" pb-2 underline text-blue-900">About School</h1> */}
            <h3 class="text-2xl font-medium text-gray-700">
            {tempLang.about.educational_excelence.heading}
            </h3>
            <li class="mt-2 list-inside text-lg text-slate-500 text-justify">
            {tempLang.about.educational_excelence.description}
            </li>
            <li class="mt-2 list-inside text-slate-500 text-lg text-justify">
            {tempLang.about.educational_excelence.description1}
            </li>
            <li class="mt-2 list-inside text-slate-500 text-lg text-justify">
            {tempLang.about.educational_excelence.description2}
            </li>
            <li class="mt-2 list-inside text-slate-500 text-lg text-justify">
            {tempLang.about.educational_excelence.description3}
            </li>
          </div>
        </div>
      </div>

      <div class=" text-gray-100 xl:mt-0 mt-8 xl:mx-20 xl:py-6 px-2 py-2">
        <div class="  px-4 xl:pl-8 grid gap-4 grid-cols-1 md:grid-cols-2 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
          <div class="flex-1 xl:p-4 pt-2">
            <h3 class="xl:text-xl text-2xl font-medium text-gray-700">
            {tempLang.about. school_Value.heading}
            </h3>
            <p class="py-4 text-justify  text-black text-lg w-full">
            {tempLang.about. school_Value.description}
            </p>

            <p class="mt-2 text-justify text-slate-500 text-lg">
              <span className=" text-2xl font-extrabold px-2">.</span>{" "}
              {tempLang.about. school_Value.description1}
            </p>
            <p class="mt-2 text-justify text-slate-500 text-lg">
              <span className=" text-2xl font-extrabold px-2">.</span>
              {tempLang.about. school_Value.description2}
            </p>
            <p class="mt-2 text-justify text-slate-500 text-lg">
              <span className=" text-2xl font-extrabold px-2">
              {tempLang.about. school_Value.description3}
              </span>
            </p>
          </div>
          <header class="bg-center bg-fixed bg-no-repeat  bg-cover relative">
            <div>
              <img
                className=" xl:h-96 h-5/6 w-full object-fill"
                src="https://wallpaperaccess.com/full/3119143.png"
              ></img>
            </div>
            <div class=" bg-opacity-70 absolute inset-x-0 inset-y-0 bg-black flex items-center justify-center">
              <div class="mt-8 max-w-sm md:mt-0 ml-0 xl:ml-10 md:w-2/3">
                <div class="relative flex pb-6">
                  <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                    <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                  </div>
                  <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-white">
                    {tempLang.about.step.step_1.heading}
                    </h2>
                    <p class="py-4 text-white text-sm">
                    {tempLang.about.step.step_1.description}</p>
                  </div>
                </div>
                <div class="relative flex pb-6">
                  <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                    <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                  </div>
                  <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-white">
                    {tempLang.about.step.step_2.heading}
                    </h2>
                    <p class="py-4 text-white text-sm">
                    {tempLang.about.step.step_2.description}</p>
                  </div>
                </div>
                <div class="relative flex pb-6">
                  <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="5" r="3"></circle>
                      <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                    </svg>
                  </div>
                  <div class="flex-grow pl-4">
                    <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-white">
                    {tempLang.about.step.step_3.heading}
                    </h2>
                    <p class="py-4 text-white w-full text-sm">
                    {tempLang.about.step.step_3.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>

      <div className=" flex xl:flex-row mt-8 xl:mt-0 flex-col xl:space-x-10 space-x-1 items-center justify-between xl:mx-20 mx-1">
        <div className=" flex flex-col items-center justify-between">
          <div class="flex-1 xl:p-10 px-4">
            {/* <h1 className=" pb-2 underline text-blue-900">About School</h1> */}
            <h3 class="text-2xl font-medium text-gray-700">
            {tempLang.about.community.heading}
            </h3>
            <li class="mt-2 list-inside text-justify text-lg text-slate-500">
            {tempLang.about.community.description}
            </li>
            <li class="mt-2 list-inside text-slate-500 text-justify text-lg">
            {tempLang.about.community.description1}
            </li>
            <li class="mt-2 list-inside text-slate-500 text-justify text-lg">
              {" "}
              {tempLang.about.community.description2}
            </li>
            <li class="mt-2 list-inside text-slate-500 text-justify text-lg">
            {tempLang.about.community.description3}
            </li>

            {/* <a
              href=""
              class="mt-2 inline-flex hover:text-blue-600 text-sky-500"
            >
              Read More →
            </a> */}
          </div>
        </div>
        <div class="flex xl:flex-row flex-col  mt-6 xl:mt-0 items-center justify-center w-full xl:max-w-2xl ">
          <div class=" h-96 xl:p-0 p-2 overflow-hidden transform transition bg-white rounded-lg shadow-lg xl:scale-110">
            <img
              className="w-full h-full  border-2 shadow-lg p-1 "
              src={images.learn}
              alt="studentLifeImages2"
            ></img>
          </div>
          <div class=" h-96  z-10 xl:-ml-40 ml-0 bg-white rounded-lg shadow-lg mt-0 xl:mt-64">
            <img
              className="w-full h-full  border-2 shadow-lg p-1 "
              src={images.learn}
              alt="studentLifeImages2"
            ></img>
          </div>
        </div>
      </div>
      <div className=" w-full border border-gray-200 mt-10 "></div>

      {/* <div class="py-20 xl:mx-20 bg-gray-50">
        <div class="container mx-auto px-6 md:px-12 xl:px-32">
          <div class="mb-16 text-center">
            <h2 class="mb-4 text-center text-2xl text-gray-900 font-bold md:text-4xl">
              Let's Meet Our Member
            </h2>
            <p class="text-gray-600 lg:w-8/12 text-justify lg:mx-auto">
              Introducing our team members – a dedicated group with diverse
              skills and expertise. Each member brings a unique perspective,
              contributing to our collective success. With a shared commitment
              to excellence, innovation, and collaboration, we work together to
              achieve our goals. Get to know the faces behind our mission as we
              continue to make a positive impact.
            </p>
          </div>
          <div class="grid gap-12 items-center md:grid-cols-3">
            <div class="space-y-4 text-center">
              <img
                class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
                src={imagess.teacher1}
                alt="woman"
                loading="lazy"
                width="640"
                height="805"
              ></img>
              <div>
                <h4 class="text-2xl">XYZ Singh Roy</h4>
                <span class="block text-sm text-gray-500">CEO-Founder</span>
              </div>
            </div>
            <div class="space-y-4 text-center">
              <img
                class="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80"
                src={imagess.maddem1}
                alt="man"
                loading="lazy"
                width="1000"
                height="667"
              ></img>
              <div>
                <h4 class="text-2xl">ABC devi</h4>
                <span class="block text-sm text-gray-500">
                  Chief Technical Officer
                </span>
              </div>
            </div>
            <div class="space-y-4 text-center">
              <img
                class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64"
                src={imagess.maddem2}
                alt="woman"
                loading="lazy"
                width="1000"
                height="667"
              ></img>
              <div>
                <h4 class="text-2xl">ABC Singh</h4>
                <span class="block text-sm text-gray-500">
                  Chief Operations Officer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div class="bg-center bg-fixed bg-no-repeat mx-auto  bg-cover mt-6 h-96 relative">
        <div>
          <img
            className=" h-96 w-full object-fill"
            src="https://thumbs.dreamstime.com/z/electronic-technical-support-concept-wrenches-217283767.jpg"
          ></img>
        </div>
        <div class=" bg-opacity-50 absolute inset-x-0 inset-y-0 bg-black flex items-center justify-center">
          <div class="mx-2 text-center">
            <div class="inline-flex">
              <a href="#about">
                <button class="p-2  bg-transparent border-2 bg-indigo-200 bg-opacity-75 hover:bg-opacity-100 border-indigo-700 rounded-full hover:border-indigo-800 font-bold text-indigo-800 shadow-md transition duration-500 md:text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-10 h-10"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                    />
                  </svg>
                </button>
              </a>
            </div>
            <h1 class="text-gray-100 font-semibold text-3xl py-2">
              <span class="text-white"></span> See Our Working Vodeo
            </h1>
            <h2 class="text-gray-200 font-normal text-lg leading-tight">
              See & Get the Details of the School
            </h2>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default About;
