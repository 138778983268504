import React, { useState } from 'react'
function Library(params) {

  var TableList = [
    {
      name: "Data Stratucher",
      title: "RS Agrawal",
      page: "1282",
      language: "Englis",
      genres: "CSE"
    },
    {
      name: "DBSM",
      title: "Rk Roy",
      page: "3564",
      language: "Banglih",
      genres: "CSE"
    },
    {
      name: "C++",
      title: "As.Khan",
      page: "3564",
      language: "Tamil",
      genres: "CSE"
    },
    {
      name: "Java",
      title: "S Sukala",
      page: "3564",
      language: "English",
      genres: "CSE"
    },
    {
      name: "Python",
      title: "Guddio",
      page: "3564",
      language: "Karnatak",
      genres: "CSE"
    },
    {
      name: "Account",
      title: "SK Dutta",
      page: "1254",
      language: "Gujarati",
      genres: "B.COM"
    }
  ]

  const [variable, setvariable] = useState(TableList);

  const dropdownEventHandler = (e) => {

    console.log(e.target.value);
    setvariable(e.target.value);
    filterData(e.target.value);

  }


  // filter records by search text
  const filterData = (value) => {
    console.log('-----value----');
    console.log(value)
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setvariable(TableList);
    else {
      const filteredData = TableList.filter(item => {
        return Object.keys(item).some(key =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setvariable(filteredData);
    }
  }

  return (
    <div>
      <div className="relative text-center">
        <img className="w-full h-96 border-2 border-gray-200 rounded-md " src="https://wallpaperaccess.com/full/253332.jpg" alt="Labaryphoto" />
        <p className="lg:w-1/2 text-white font-medium lg:text-2xl font-serif absolute lg:bottom-6 lg:right-72 bottom-32 lg:mb-16">
          A library is a curated collection of sources of information and similar resources, selected by experts and made accessible to a
          defined community for reference or borrowing. It provides physical or digital access to material, and may be a physical location or
          a virtual space, or both.
                      </p>
        <div className="my-8">
          <h className="text-white bg-gradient-to-r mr-2 from-purple-400 to-red-500 lg:font-extrabold font-medium lg:text-4xl  rounded-md px-2 font-serif  py-2 ">WELLCOME TO SYLVAN POLYTECHNIC COLLEGE</h>
        </div>
      </div>
      <div className="text-lg font-medium font-serif px-8 text-gray-500 text-center">
      Founded to accelerate the nation’s industrial revolution, MIT is profoundly American. With ingenuity and drive, our graduates have invented fundamental technologies, launched new 
      industries, and created millions of American jobs. At the same time, and without the slightest sense of contradiction, MIT is profoundly global(opens in new window). Our community gains tremendous strength as a
       magnet for talent from around the world. Through teaching, research, and innovation, MIT’s exceptional community pursues its mission of service to the nation and the world.
      </div>
      <div className="lg:flex lg:space-x-6 lg:mx-auto mx-16  px-4 my-8 lg:my-12">
        <div className="text-gray-500">
          <select onChange={dropdownEventHandler} className="lg:w-96 h-12 w-full  border-2 mr-2 px-2 border-gray-500 my-2  rounded-lg focus:outline-none">
            <option value="Data Stratucheri">Data Stratucheri</option>
            <option value="DBSM">DBSM</option>
            <option value="C++">C++</option>
            <option value="Java">Java</option>
            <option value="Python">Python</option>
            <option value="Account">Account</option>

          </select>
        </div>
        <div className="">
          <input className="lg:w-96 h-12  w-full border-2  border-gray-500 relative shadow-sm mt-2 mb-6 lg:mb-0  rounded-l-full rounded-r-full focus:outline-none placeholder-indigo-300 text-center lg:font-medium font-semibold" type="text" placeholder="Search or typ URL ...." />
          <div className="">
            <div className="-mt-14 lg:mt-8 "><svg className="w-6 h-6 text-indigo-500 absolute left-2/5 lg:-bottom-16  ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></div>
           
          </div>
        </div>
      </div>
      <div className=" w-full bg-gray-200 mx-4 mb-4">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                         <div className="flex ">
                        <span className="mt-1">NAME</span>
                          <div className="ml-2 mb-2 -space-y-2 ">
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex">
                      <span className="mt-1">Author's</span>
                          <div className="ml-2 -space-y-2">
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                        </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex space-x-2">
                      <span className="mt-1">language</span>
                          <div className="ml-2 -space-y-2">
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                        </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex space-x-2">
                      <span className="mt-1">pages</span>
                          <div className="ml-2 -space-y-2">
                          <svg class="w-4 h-4 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                        </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex ">
                      <span className="mt-1">publiced on</span>
                          <div className="ml-2 mb-2 -space-y-2 ">
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                        </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex ">
                      <span className="mt-1">genres</span>
                          <div className="ml-2 mb-2 -space-y-2 ">
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                        </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex ">
                      <span className="mt-1">view</span>
                          <div className="ml-2 mb-2 -space-y-2 ">
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                        </div>
                        </th>

                    </tr>
                  </thead>
                  {
                    variable.map((item, indexNo) => (
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                          <td class="px-6 py-2 whitespace-nowrap">
                            <div class="text-sm font-medium text-gray-900">
                              {item.name}
                            </div>
                          </td>
                          <td class="px-6 py-2 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">
                              {item.title}
                            </div>
                          </td>
                          <td class="px-6 py-2 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900 px-2">
                              {item.language}
                            </div>
                          </td>
                          <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                            {item.page}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            <div><p className="text-sm font-medium text-gray-900">25/04/2021</p></div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <p className="text-sm font-medium text-gray-900">{item.genres}</p>
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap">
                            <button>
                              <a href="/C:\Users\Gopal Prasad\Desktop4"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg></a>

                            </button>
                          </td>
                        </tr>
                      </tbody>
                    )
                    )
                  }

                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  )

}
export default Library;