import { benagli, english, hindi } from "../locales/Language.js";
export const getTranslation = (key, language) => {
  console.log("Get language " + key);
  var value = null;
  if (language === "bn") {
    value = getBengaliTranslatedValue(key);
  } else if (language === "hi") {
    value = getHindiTranslatedValue(key);
  } else if (language === "en") {
    value = getEnglishTranslatedValue(key);
  }
  return value !== null ? value : "TempValue";
};

const getBengaliTranslatedValue = (key) => {
  return benagli[key];
};

const getEnglishTranslatedValue = (key) => {
  return english[key];
};

const getHindiTranslatedValue = (key) => {
  return hindi[key];
};

export const getLanguageTemplate = (lang) => {
  var template = english;
  if (lang === "bn") {
    template = benagli;
  } else if (lang === "hi") {
    template = hindi;
  }

  return template;
};
