import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CollegeCampus from "./CollegeCampus";
import constant from "../../Constant/constant.js";
import Faculty from "./Faculty";
import Event from "./Events";
import Alumni from "./Alumni";

function Gallery() {
  var active =
    "h-full border-b-4 border-blue-500  px-3 font-semibold border-blue-500 hover:border-blue-500 text-yellow-400 cursor-pointer";
  var notactive =
    "h-full border-b-4 border-white px-3 font-semibold hover:border-blue-500 cursor-pointer";
  const [options, setOptions] = useState(["Campus", "Faculty", "Events"]);
  const [colors, setColors] = useState([]);
  const [current, setCurrent] = useState(0);
  const [gallery, setGallery] = useState([]);
  const history = useNavigate();
  const { id } = useParams();

  async function fetchData(path, params = {}) {
    try {
      const url = `${constant.BACKEND_URL}` + path;
      const queryParams = new URLSearchParams(params);
      const response = await fetch(`${url}?${queryParams}`);
      // console.log(response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  
  const fetchGalleryData = async () => {
    if (gallery.length === 0) {
      const tempGallery = await fetchData("/api/v1/gallery/get/all");
      setGallery(tempGallery);
      console.log(tempGallery);
    }
  };

  useEffect(() => {
    basicSetup();
    fetchGalleryData();
  }, []);

  const location = useLocation();

  const basicSetup = () => {
    var colors = [active, notactive, notactive];
    const path = location.pathname.split("/")[2];
    setColors(colors);
    if (path === "faculty") {
      setCurrent(1);
      setCurrentColors("faculty");
    }
    if (path === "events") {
      setCurrent(2);
      setCurrentColors("events");
    }
    // if (path === "alumni") {
    //     setCurrent(3)
    //     setCurrentColors("alumni")
    // }

    console.log(options);
  };

  const setCurrentColors = (type) => {
    colors[0] = notactive;
    colors[1] = notactive;
    colors[2] = notactive;

    if (type === "faculty") {
      colors[1] = active;
    } else if (type === "events") {
      colors[2] = active;
    } else {
      colors[0] = active;
    }
    setColors(colors);
  };

  const optionChangeHandler = (params) => {
    if (params !== current) {
      setCurrent(params);
    }
    let path = "/gallery/";
    switch (params) {
      case 1:
        path = path + "faculty";
        RenderSwitch("faculty");
        break;
      case 2:
        path = path + "events";
        RenderSwitch("events");
        break;
      default:
        path = path + "campus";
        RenderSwitch("campus");
        break;
    }
    history(path);
  };

  const RenderSwitch = (param) => {
    switch (id) {
      case "faculty":
        setCurrentColors("faculty");
        setCurrent(1);
        return <Faculty />;

      case "events":
        setCurrentColors("events");
        setCurrent(2);
        return <Event />;

      default:
        setCurrentColors("campus");
        setCurrent(0);
        return <CollegeCampus />;
    }
  };

  return (
    <div className="flex-col mb-6">
      <div className="flex xl:flex-row flex-col space-x-6 xl:mx-20 border-b-2 mt-12 mb-6 xl:my-12">
        <div className="mb-4  xl:mt-0 mt-4 ml-2">
          <h1 className="h-full pl-2 text-xl">Gallery</h1>
        </div>
        <div className="flex  lg:justify-between space-x-2 lg:space-x-4 lg:border-l-2 items-center lg:ml-2 lg:pl-8 border-blue-300">
          {options.map((option, index) => (
            <a
              onClick={() => optionChangeHandler(index)}
              className={colors[index]}
            >
              {option}
            </a>
          ))}
        </div>
      </div>
      <div>
        <RenderSwitch data={options[current]} />
      </div>
      <div className="h-auto lg:mx-20 mx-2">
        {gallery.map(
          (data, index) =>
            data.type === options[current] && (
              <div className=" mt-6">
              <p className="font-bold text-2xl mb-4">{data.name}</p>
              {console.log(data)}
              <div className="grid grid-cols-1 gap-4 justify-around lg:grid-cols-4">
                {data.images.map((image, index) => (  
                  <div className="border border-gray-200 m-2 xl:m-0 border-3 transform hover:scale-105 duration-1000 rounded-xl shadow-2xl">
                    <img
                    src={`${constant.BACKEND_URL}/api/` + image}
                    alt="image-section"
                    className="w-full h-60"
                  />
                  </div>
                ))}
              
              </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Gallery;
