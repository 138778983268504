import StudentLife from "./StudentLife.js";
import AcademicWorkComponent from "./AcademicWorkComponent.js";
import News from "./News.js";
import Carausal from "./Carausal.js";
import BasisSchool from "./BasisSchool";
import RoundSlider from "./RoundSlider.js";
import CardSlider from "./CardSlider.js";
import { useContext } from "react";
import { globleinfo } from "../../App.js";
import images from "../Img/index.js";
import ExtraCarecular from "./ExtraCarecular.js";
import King from "./King.js";

function Home() {
  const { tempLang,} = useContext(globleinfo);
  return (
    <div>
      <Carausal />
      <div>
        {/* <div className="text-2xl font-bold leading-tight text-gray-800"> {tempLang.name}</div> */}
        <div class="flex overflow-hidden flex-col h-full  bg-blue-800 relative items-start justify-start xl:my-10  my-8 md:flex-row">
          <div
            data-aos="fade-right"
            data-aos-delay="400"
            class="flex flex-col relative  py-2 xl:py-6 justify-start mx-4 xl:mx-8  md:mt-0 sm:w-1/2 md:w-1/2 xl:pl-0 sm:pl-16"
          >
            <h3 class="mt-2 xl:mt-6 font-medium text-white xl:text-4xl text-3xl sm:text-left md:text-4xl">
              {tempLang.home_Header.principle_Content.heading}
            </h3>
            <p class="mt-2 xl:mt-4 pb-10 xl:pb-8 text-lg xl:text-xl text-justify  text-white xl:text-justify md:text-left">
              {tempLang.home_Header.principle_Content.discription}
            </p>
            <div className=" absolute  right-0  border-gray-200 text-white border-b-4 font-serif cursor-pointer flex flex-row space-x-3  bottom-4">
              <span> {tempLang.home_Header.principle_Content.signature}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="400"
            class="w-full h-full md:w-1/2"
          >
            <div class="flex xl:h-full justify-center xl:justify-center">
              <img
                class="   xl:h-full   border-l-4 border-gray-700 w-full h-56 md:h-full object-fill xl:w-full object-center md:mb-0 xl:mb-0"
                src={images.principal}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <RoundSlider /> */}
       <RoundSlider />
      <News />
      {/* <Slider/> */}
      {/* <King /> */}
      <CardSlider />
      
      <StudentLife />
      {/* <LibraryResearch /> */}
      <AcademicWorkComponent />
      <ExtraCarecular />
      <BasisSchool />
      <section class="text-gray-600  bg-blue-800 body-font">
        <div class=" px-5 py-5">
          <div class="flex xl:flex-row flex-col w-full m-2">
            <div class="p-4 md:w-1/3">
              <div class="h-full lg:h-48 flex items-center justify-center  md:h-36 w-full border-dashed box-border border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <div className=" flex flex-row items-center text-white justify-around w-full">
                  <div className=" flex  flex-col">
                    <h1 className="text-6xl font-semibold">1</h1>
                    <div className=" border-yellow-500 pt-4 border-b-4"></div>
                  </div>
                  <div className=" text-3xl">  {tempLang.home_Header.campusDetails.campus}</div>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="h-full lg:h-48 md:h-36 flex items-center justify-center border-dashed w-full box-border border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <div className=" flex flex-row items-center text-white justify-around w-full">
                  <div className=" flex  flex-col">
                    <h1 className="text-6xl font-semibold">5-12</h1>
                    <div className=" border-yellow-500 pt-4 border-b-4"></div>
                  </div>
                  <div className=" text-3xl">{tempLang.home_Header.campusDetails.classes}</div>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="h-full lg:h-48 md:h-36 flex items-center justify-center w-full border-dashed box-border border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <div className=" flex flex-row items-center text-white justify-around w-full">
                  <div className=" flex  flex-col">
                    <h1 className="text-6xl font-semibold">20</h1>
                    <div className=" border-yellow-500 pt-4 border-b-4"></div>
                  </div>
                  <div className=" text-3xl">{tempLang.home_Header.campusDetails.staf}</div>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="h-full lg:h-48 md:h-36 flex items-center justify-center w-full border-dashed box-border border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <div className=" flex flex-row items-center text-white justify-around w-full">
                  <div className=" flex  flex-col">
                    <h1 className="text-6xl font-semibold">1500+</h1>
                    <div className=" border-yellow-500 pt-4 border-b-4"></div>
                  </div>
                  <div className=" text-3xl">{tempLang.home_Header.campusDetails.student}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </div>
  );
}
export default Home;
