import userEvent from '@testing-library/user-event';
import React, { Fragment, useState, useEffect } from 'react';
import images from '../Img';

function CollegeCampus() {

    var isVisible = false

    let CampasImg = [
        images.student,
        images.study1,
        images.studentlibrary
    ];
    let Hostel = [
        images.students, images.study, images.study3
        ];
    let ClassRoom = [
        images.students, images.study, images.study3
    ];
    let library = [
        images.library, images.library1, images.students
    ];
    let lab = [
        images.laboratories, images.study3, images.study10 ];

    const [show, setshow] = useState(0)
    let [currentImage, setCurrentImage] = useState(0)
    const [currentImglist , setCurrentImgList ] = useState(null)
    const [currentindex , setCurrenIndex] = useState(null)
    const [click, setClick] = useState(0)
    const GetImageURL = (type , index, isStart) => {
        if (type === "campus")
        {
            if (isStart === false)
            {
               if(index < 0)
               {
                   index = CampasImg.length - 1
               }
               else if (index > CampasImg.length - 1)
               {
                    index = 0
               }
               else
               {
                    index = click === 0 ? index - 1 : index + 1
               }
            }
            return CampasImg[index]
        }
        else if (type === "hostel")
        {
            return Hostel[index]
        }
        else if (type === "classroom")
        {
            return ClassRoom[index]
        }
        else if (type === "library")
        {
            return library[index]
        }
        else
        {
            return lab[index]
        }
    }

    const ShowPopUp = (index , type ) => {
        setshow(!show)
        setCurrentImage(GetImageURL(type , index, true))
        setCurrenIndex(index)
        setCurrentImgList(type)
        console.log("TEST " + currentImage)
    }

    const HidePopUp = () => {
        setshow(!show)
        setCurrenIndex(null)
        setCurrentImgList(null)
    }

    const rightClickHanlder = () => {
        setClick(1)
        setCurrenIndex(currentindex + 1)
        setCurrentImage(GetImageURL(currentImglist, currentindex, false))
        console.log ("right click")
    }

    const leftClickHandler = () => {
        setClick(0)
        setCurrenIndex(currentindex - 1)
        setCurrentImage(GetImageURL(currentImglist, currentindex, false))
        console.log ("Left Click")
    }

    return (
        <div className="h-auto lg:mx-20 mx-4">
            <h1 className="font-bold text-2xl  mb-4">
                Our School Campus
            </h1>
            {/* <p>A college is an educational institution or a constituent part of one. A college may be a degree-awarding tertiary educational institution, a part of a collegiate or federal university, an institution offering vocational education, or a secondary school.</p> */}
            <div className="grid grid-cols-1 gap-4 my-5  mx-0 lg:mx-0 lg:grid-cols-3">
                {
                    CampasImg.map((image, index) => (
                        <div className="relative w-full h-full" >
                            {/* <button className="absolute top-0 right-0" onClick={() => ShowPopUp(index, "campus")}>
                                <svg class="w-6 h-6 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>
                            </button> */}
                            <img className="h-60 w-full" src={image} alt="collegecamps"></img>
                        </div>
                    ))
                }


            </div>

            {/* <h1 className="font-bold text-2xl  mb-4 mt-5">
                Our Hostels
            </h1>
            <p>Hostel life is one of the most exciting thought for majority of college students. They are of the belief that hostel life is all fun and parties with friends. Freedom, friends and no boundaries that what it seems at first but that's not all. In reality, hostel life is more bounding that life at home.</p>
            <div className="grid grid-cols-1 gap-4 my-5 mx-10 lg:mx-28 lg:grid-cols-3">

                {
                    Hostel.map((image, index) => (
                        <div className="relative w-full h-full" >
                            <button className="absolute top-0 right-0" onClick={() => ShowPopUp(image)}>
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>
                            </button>
                            <img className="h-60 w-full" src={image} alt="collegecamps"></img>
                        </div>
                    ))
                }
                <svg class="absolute top-0 right-5 text-gray-800 w-6 h-6 cursor-pointer" onClick={ShowPopUp} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>

            </div> */}

            <h1 className="font-bold text-2xl  mb-4 mt-5">
                Our Class Rooms
            </h1>
            {/* <p>Students may not exactly have the same group of students in each class depending on the students schedule. Then college classrooms are set up in a lecture hall or auditorium with one teacher, also called a professor. Typically this teacher has a Teacher Assistant (TA), which is a grad student.</p> */}
            <div className="grid grid-cols-1 gap-4 my-5 mx-0  lg:mx-0 lg:grid-cols-3">

                {
                    ClassRoom.map((image, index) => (
                        <div className="relative w-full h-full" >
                            {/* <button className="absolute top-0 right-0" onClick={() => ShowPopUp(image)}>
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>
                            </button> */}
                            <img className="h-60 w-full" src={image} alt="collegecamps"></img>
                        </div>
                    ))
                }
                <svg class="absolute top-0 right-5 text-gray-800 w-6 h-6 cursor-pointer" onClick={ShowPopUp} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>

            </div>

            <h1 className="font-bold text-2xl  mb-4 mt-5">
                Our Library
            </h1>
            {/* <p>In colleges, the library occupies a prominent position and it is an important and integral part of the teaching programme. It is not merely a depository of books, but an active workshop instrument in the production of or original thinking. The aim of college education and college libraries in inter-related.</p> */}
            <div className="grid grid-cols-1 gap-4 my-5  mx-0 lg:mx-0 lg:grid-cols-3">

                {
                    library.map((image, index) => (
                        <div className="relative w-full h-full" >
                            {/* <button className="absolute top-0 right-0" onClick={() => ShowPopUp(image)}>
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>
                            </button> */}
                            <img className="h-60 w-full" src={image} alt="collegecapsy5"></img>
                        </div>
                    ))
                }
                <svg class="absolute top-0 right-5 text-gray-800 w-6 h-6 cursor-pointer" onClick={ShowPopUp} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>

            </div>

            <h1 className="font-bold text-2xl  mb-4 mt-5">
                Our Laboratory
            </h1>
            {/* <p>The aim is to provide students a conceptual as well as practical understanding of the subject through hands-on training. KIIT allocates considerable resources on a regular basis in enhancing all its engineering facilities to meet cutting edge, international standards.</p> */}
            <div className="grid grid-cols-1 gap-4 my-5  mx-0 lg:mx-0 lg:grid-cols-3">

                {
                    lab.map((image, index) => (
                        <div className="relative w-full h-full" >
                            {/* <button className="absolute top-0 right-0" onClick={() => ShowPopUp(image)}>
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg>
                            </button> */}
                            <img className="h-60 w-full" src={image} alt="collegeCamps6"></img>
                        </div>
                    ))
                }
                {/* <svg class="absolute top-4 xl:top-28 right-5 text-gray-800 w-6 h-6 cursor-pointer" onClick={ShowPopUp} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path></svg> */}
                {/* <div>
                    {show &&

                        <div className="fixed z-20  inset-0 overflow-y-auto">
                            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                                    <div class="absolute inset-0 bg-gray-100 opacity-75"></div>
                                </div>
                                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                <div class=" inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                    <div class="flex flex-row justify-around  bg-white">
                                        <div className="h-full my-48 bg-gray-300 rounded-full cursor-pointer shadow-lg" onClick={leftClickHandler}>
                                            <svg className="w-8 h-8 text-black"  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                            </svg>
                                        </div>
                                        <div>
                                        <div className="flex flex-col">
                                                <button className="ml-96 mx-10 pl-10" onClick={HidePopUp}>                                                    
                                                    <svg class="w-6 h-6 text-gray-500 outline-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                </button>
                                                <img className="mx-2 my-5" src={currentImage} alt="collegecamps7"></img>
                                            </div>    
                                        </div>
                                        <div className="h-full my-48 bg-gray-300 rounded-full cursor-pointer shadow-lg" onClick={rightClickHanlder}>
                                            <svg className="w-8 h-8  text-black"  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                </div> */}
            </div>
        </div>
    );
}

export default CollegeCampus;