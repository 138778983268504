import React from "react";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { apiCall } from "../../ApiServices/apiCallManager";
const cookies = new Cookies();
const Settings = ({ users, setUsers }) => {
  const cookieToken = cookies.get("TOKEN");
  const [data, setData] = useState(null);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const fetchData = async () => {
    // console.log(cookieToken)
    try {
      const response = await apiCall("application/json","get","admin/user","get/list",null,cookieToken);
      if (!response.ok) {
        console.log(`HTTP error! Status: ${response.status}`);
      }
      const result = response.data;

      setData(result);
    }
    catch (err) {
      if (err.message === "Request failed with status code 500") {
        alert("Somthing went Wrong")
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const changeRole = (event, email) => {
    const formData = {
      email: email,
      role: event.target.value
    }
    apiCall('Application/JSON',"post","admin/user","update/role",null,cookieToken,formData)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data);
        } else {
          alert(res.data);
        }
      })
      .catch((err) => {
        alert("Student is not updated succesfully");
      });
  };

  function updateUserList(email) {
    if (deleteUsers.includes(email)) {
      let iniData = [...deleteUsers];
      let data = iniData.filter((item) => item !== email);
      setDeleteUsers(data);
    } else {
      let data = [...deleteUsers];
      data.push(email);
      setDeleteUsers(data);
    }
  }

  async function deleteSelectedUsers() {
    var answer = window.confirm(
      `Are sure you want to delete ${deleteUsers.length} users ?`
    );
    console.log(deleteUsers);
    if (!answer) {
      return;
    }

    const formData = { emails: deleteUsers };

    const response = await apiCall('Application/JSON',"Delete","admin/user","delete",null,cookieToken,formData)
    const deletedData = await response.json();

    if (deletedData.status === 200) {
      alert(deletedData.message);
    } else {
      alert("unable to delete users");
    }
  }

  return (
    <>
    
      <body>
        <div className="flex justify-between">
          <p className="text-black text-3xl">Registered User</p>
          <button
            type="button"
            className="col-span-2 md:col-span-1 bg-red-500 text-white px-2 py-2 rounded"
            onClick={() => deleteSelectedUsers()}
          >
            Delete
          </button>
        </div>

        <table
          className="border-collapse w-full border border-black"
          id="dataTable"
        >
          <thead>
            <tr>
              <th className="border border-black  p-2 text-left text-black">
                ID
              </th>
              <th className="border border-black  p-2 text-left text-black">
                First Name
              </th>
              <th className="border border-black  p-2 text-left text-black">
                Last Name
              </th>
              <th className="border border-black  p-2 text-left text-black">
                Email
              </th>
              <th className="border border-black  p-2 text-left text-black">
                Gender
              </th>
              <th className="border border-black  p-2 text-left text-black">
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            {console.log(users)}
            {users.map((item, index) => (
              <tr key={index}>
                <td className="border border-black  p-2 text-left text-black">
                  <input
                    type="checkbox"
                    onChange={() => updateUserList(item.email)}
                    className="checkbox bg-black"
                  />
                </td>
                <td className="border border-black  p-2 text-left text-black">
                  {item.firstname}
                </td>
                <td className="border border-black  p-2 text-left text-black">
                  {item.lastname}
                </td>
                <td className="border border-black  p-2 text-left text-black ">
                  {item.email}
                </td>
                <td className="border border-black  p-2 text-left text-black">
                  {item.gender}
                </td>
                <td className="border border-black  p-2 text-left text-black">
                  <select
                    className="role-dropdown"
                    onChange={(e) => changeRole(e, item.email)}
                    defaultValue={item.role}
                  >
                    <option className="w-12" value={false}>
                      user
                    </option>
                    <option className="w-12" value={true}>
                      admin
                    </option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
          <tbody id="dataBody"></tbody>
        </table>
      </body>
    </>
  );
};
export default React.memo(Settings);
