import React, { useContext, useEffect, useState } from "react";
import { globleinfo } from "../../App";
import Animation1 from "../new_design/Animation1";
import constant from "../../Constant/constant";
import images from "../Img/index";
import { apiCall } from "../../ApiServices/apiCallManager";
function RoundSlider() {
  const { tempLang } = useContext(globleinfo);
  const [index, setIndex] = useState(0);
  const [teacherList, setTeacherList] = useState([]);
  const [newList, setNewList] = useState([]);

  const fatchData = async () => {
    apiCall("application/json","get","teacher","get/all")
      .then((res) => {
        setTeacherList([...res.data]);
        setNewList(res.data);
        console.log(newList);
      });
  };

  useEffect(() => {
     fatchData();
  }, []);

  var currentWindow = [0, 1, 2, 3, 4, 5];
  var Small = [0, 1];

  const rightClick = () => {
    // if(CardList.length - 1 > index)
    setIndex(index + 1);
  };

  const lefttClick = () => {
    console.log("Left click " + index);
    setIndex(index - 1);
  };

  const getImagePath = (filename) => {
    var basePath = "/uploads/";
    return basePath.concat(filename);
  };

  return newList.length !== 0 ? (
    <div className="lg:mt-10  mt-6">
      <p className="flex items-center justify-center   h-12 w-full opacity-60 text-2xl font-serif font-bold text-blue-5000 lg:text-4xl">
        <Animation1
          text={tempLang.home_Header.teacher_of_School.teacher_of_School}
        />
      </p>

      <div className="flex items-center xl:mt-12 mt-6 justify-center">
        <div className="  relative xl:w-8/12 w-full ">
          {newList.length !== 0 && (
            <div className="xl:flex flex justify-evenly items-center xl:flex-row xl:items-center xl:justify-around space-x-6 sm:top-0 lg:top-52 ">
              <button
                className={
                  index <= 0
                    ? "hidden"
                    : "block z-10 w-8 h-8 bg-purple-400 rounded-full focus:outline-none animate-bounce absolute top-12 lg:left-2 left-0"
                }
                onClick={lefttClick}
              >
                <svg
                  class="w-6 h-6 text-white ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
              {/* var arr = [1,2,3,4,5,6] */}
              <button
                className={
                  index + currentWindow.length >= newList.length
                    ? "hidden"
                    : "block z-10  w-8 h-8 bg-purple-400 rounded-full animate-bounce focus:outline-none absolute top-12 lg:-right-4 right-0"
                }
                onClick={rightClick}
              >
                <svg
                  class="w-6 h-6 text-white ml-1 "
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </button>
              {console.log(newList)}
              {currentWindow.map((val, i) => (
                <div key={i} className=" hidden duration-1000 lg:block">
                  <div className="transform  flex items-center justify-center  transition duration-1000 hover:shadow-lg  hover:scale-110 hover:-rotate-6 bg-gradient-to-tr from-purple-400 to-green-600 p-1 rounded-full">
                    <img
                      className="h-28 w-28 object-cover border-4 border-white rounded-full "
                      src={
                        newList[index + i] !== undefined
                          ? // ? getImagePath(newList[index + i].filename)
                            `${constant.BACKEND_URL}/api/upload/${
                              newList[index + i].teacherImage
                            }`
                          : `${images.userIcon}`
                      }
                      alt="abc"
                    />
                  </div>
                  <p className="font-bold pt-2  w-28 truncate  text-center text-sm font-serif">
                    {newList[index + i] && newList[index + i].name}
                  </p>
                  <p className="text-xs font-semibold text-gray-500 text-center">
                    {newList[index + i] && newList[index + i].qualification}{" "}
                  </p>
                </div>
              ))}
              {Small.map((val, i) => (
                <div key={i} className="static block mb-10  lg:hidden ">
                  <div className="transform duration-1000 transition hover:-rotate-6 bg-gradient-to-tr from-purple-400 to-green-600 p-1  rounded-full">
                    <img
                      className="h-28 w-28 border-4 border-white rounded-full"
                      src={
                        newList[index + i] !== undefined
                          ? // ? getImagePath(newList[index + i].filename)
                            `${constant.BACKEND_URL}/api/upload/${
                              newList[index + i].teacherImage
                            }`
                          : `${images.userIcon}`
                      }
                      alt="abc"
                    />
                  </div>
                  <p className="font-bold truncate w-28 text-center pt-2 text-sm font-serif">
                    {newList[index + i] && newList[i + index].name}
                  </p>
                  <p className="text-xs font-semibold text-gray-500 text-center">
                    {newList[index + i] && newList[i + index].qualification}{" "}
                  </p>
                </div>
              ))}
            </div>
          )}

          {/* <div className=" h-32">
         </div> */}
        </div>
      </div>
    </div>
  ) : null;
}
export default RoundSlider;
