import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FacultyDirectory from "./FacultyDirectory";
import HeadofDept from "./HeadofDept";

function Faculty() {
  const active =
    "h-full border-b-4 border-blue-500 lg:px-3 font-semibold border-blue-500 hover:border-blue-500 text-yellow-400 cursor-pointer";
  const notactive =
    "h-full border-b-4 border-white px-3 font-semibold hover:border-blue-500 cursor-pointer";
  const [options, setOptions] = useState(["Directory", "Principal"]);
  let [colors, setColors] = useState([]);
  let [current, setCurrent] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    basicSetup();

  }, []);

  const setCurrentColors = (type) => {

    colors[0] = notactive;
    colors[1] = notactive;
    colors[2] = notactive;

    if (type === "headofdept") {
      colors[1] = active;
    } else if (type === "directory") {
      colors[0] = active;
    } else {
      colors[0] = active;
    }
    setColors(colors);
  };


  const basicSetup = () => {
    var colors = [active, notactive];
    const path = location.pathname.split("/")[2];
    setColors(colors);
    // if (path === "directory") {
    //     setCurrent(1)
    //     setCurrentColors("directory")
    // }
    if (path === "headofdept") {
      setCurrent(1);
      setCurrentColors("headofdept");
    }

    console.log(options);
  };

 
  const optionChangeHandler = (params) => {
    console.log("Option change handler " + params);
    if (params != current) {
      setCurrent(params);
    }
    var path = "/faculty/";
    switch (params) {
      case 1:
        path = path + "headofdept";
        RenderSwitch("headofdept");
        setCurrentColors("headofdept");
        break;
      default:
        path = path + "directory";
        RenderSwitch("directory");
        setCurrentColors("directory");
        break;
    }
    navigate(path);
  };

  const RenderSwitch = (param) => {
    switch (id) {
      case "headofdept":
        setCurrentColors("headofdept");
        setCurrent(1);
        return <HeadofDept />;

      default:
        setCurrentColors("directory");
        setCurrent(0);
        return <FacultyDirectory />;
      // setCurrentColors("news")
      // setCurrent(0)
      // return <FacultyInNews />;
    }
  };

  return (
    <div className="flex-col">
      <div className="flex xl:flex-row flex-col space-x-6 xl:mx-20 border-b-2 mt-12 mb-4 xl:my-12">
        <div className="mb-4  xl:mt-0 mt-4 ml-4">
          <h1 className="h-full text-xl">Faculty</h1>
        </div>
        <div className="flex  lg:justify-between space-x-2 lg:space-x-4 lg:border-l-2 items-center lg:ml-2 pl-0 lg:pl-8 border-blue-300">
          {options.map((option, index) => (
            <a
              onClick={() => optionChangeHandler(index)}
              className={colors[index]}
            >
              {option}
            </a>
          ))}
        </div>
      </div>
      <div>
        <RenderSwitch data={options[current]} />
      </div>
    </div>
  );
}
export default Faculty;
