function Departments(params) {
   return (
      <div className="">
         <div className="flex">
            <h className="h-10 w-1/5 bg-green-600 font-semibold text-gray-800 rounded-md sticky top-28 left-4 flex justify-center items-center">Arts</h>
            <section className="w-full h-96 relative mb-1 bg-gradient-to-r from-yellow-400 via-red-500 to-red-500 mx-8 flex justify-center p-4 rounded-lg  text-white font-serif">
               <div className="w-1/2">
                  <img className="w-64 h-64 border-4 absolute top-6 left-6  border-white" src="https://images.pexels.com/photos/4974914/pexels-photo-4974914.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="abcd" />
                  <img className="w-52 h-44 absolute top-36 left-52 border-4 transform skew-x-6 border-white" src="https://images.pexels.com/photos/4144294/pexels-photo-4144294.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="abcd" />
               </div>
               <div className="1/2">
                  <img className="w-44 h-44 transform hover:scale-125 absolute left-2/4 rounded-full border-4 border-white" src="https://images.pexels.com/photos/326508/pexels-photo-326508.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="abcd" />
                  <p className="bg-gray-900 transform hover:scale-125 p-4 absolute top-16 right-16 rounded-lg w-72 order-50">Computer Science & Engineering is an academic program at many universities which comprises scientific and engineering aspects of computing. CSE is also a term often used in Europe to translate the name of engineering informatics academic programs.</p>
               </div>
            </section>
         </div>
         <div className="flex">
            <h className="h-10 w-1/5 bg-green-600 font-semibold text-gray-800 rounded-md sticky top-28 left-4 flex justify-center items-center">Science </h>
            <section className="w-full h-96 relative mb-1 bg-gradient-to-r from-pink-400 via-indigo-500 to-white mx-8 flex justify-center p-8 rounded-lg  text-white font-serif">
               <div className="w-1/2">
                  <img className="w-52 h-52 rounded-full border-4 absolute top-8 left-10  border-white" src="https://hiet.co.in/wp-content/uploads/2019/03/B.Tech_Mech.jpg" alt="abcd" />
                  <img className="w-44 h-44 rounded-full absolute top-36 left-48  border-4  border-white" src="https://cdn.thomasnet.com/insights-images/fb967835-d98a-4eb9-aa06-be09f2b1ed12/750px.png" alt="abcd" />
               </div>
               <div className="1/2">
                  <img className="w-44 h-44 transform hover:scale-125 absolute left-2/4 rounded-full border-4 border-white" src="https://inteng-storage.s3.amazonaws.com/images/uploads/sizes/gears-1236578_960_720_resize_md.jpg" alt="abcd" />
                  <p className="bg-pink-900 transform hover:scale-125 p-4 absolute top-16 right-16 rounded-lg w-72 order-50">A machine is a man-made device that uses power to apply forces and control movement to perform an action. ... They can also include computers and sensors that monitor performance and plan movement, often called mechanical systems.s</p>
               </div>
            </section>
         </div>
         <div className="flex">
            <h className="h-10 w-1/5 bg-green-600 cursor-wait font-semibold text-gray-800 rounded-md sticky top-28 left-4 flex justify-center items-center">Electrical Engineering</h>
            <section className="w-full h-96 relative  mb-1 bg-gradient-to-r from-purple-400 via-red-500 to-indigo-500 mx-8 flex justify-center p-8 rounded-lg  text-white font-serif">
               <img className="w-52 h-52 mt-10 transform hover:scale-125 rounded-full border-4 border-white" src="https://images.unsplash.com/photo-1547004188-83e2bf446b24?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=850&q=80" alt="" />
               <img className="w-44 h-44 absolute top-5 left-56 transform hover:scale-125 rounded-full border-4 border-white" src="https://images.unsplash.com/photo-1592201539766-b87540901769?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=968&q=80" alt="" />
               <img className="w-44 h-44 absolute bottom-10 right-56 transform hover:scale-125 rounded-full border-4 border-white" src="https://images.unsplash.com/photo-1563068261-13ebbdf16aa3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" />
               <p className="bg-green-900 transform hover:scale-125 p-2 absolute bottom-6 left-6 rounded-lg w-56 order-50"> Electrical Engineering is that branch of Engineering which deals with the study of application of electricity, </p>
            </section>
         </div>
         <div className="flex">
            <h className="h-10 w-1/5 bg-green-600 cursor-not-allowed font-semibold text-gray-800 rounded-md sticky top-28 left-4 flex justify-center items-center">	Civil Engineering</h>
            <section className="w-full h-96 relative mb-1 bg-gradient-to-r from-green-400 via-red-500 to-gray-500 mx-8 flex justify-center p-8 rounded-lg  text-white font-serif">
               <div className="flex space-x-28 mt-14">
                  <img className="w-52 h-52 border-4 border-white origin-bottom-right transform rotate-45" src="https://images.unsplash.com/photo-1582637564544-936320f66d9b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80" alt="flag" />
                  <img className="w-52 h-52  border-4 border-white origin-center transform rotate-45" src="https://images.unsplash.com/photo-1581094787965-53e07f34bb6f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="flag" />
                  <img className="w-52 h-52  border-4 border-white  transform rotate-45" src="https://images.pexels.com/photos/769525/pexels-photo-769525.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="flag" />
                  <p className="bg-red-900 transform hover:scale-125 p-2 absolute bottom-6 left-0 rounded-lg w-56 order-50"> Civil engineering is a professional engineering discipline that deals with the design, construction </p>
               </div>
            </section>
         </div>
         <div className="flex">
            <h className="h-10 w-1/5 bg-green-600 font-semibold text-gray-800 rounded-md sticky top-28 left-4 flex justify-center items-center">Ceramic Engineering</h>
            <section className="w-full h-96 relative mb-1 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 mx-8 flex justify-center p-8 rounded-lg  text-white font-serif">
               <h className="w-52 h-52 absolute top-6 left-10 border-white border-4 rounded-full"></h>
               <h className="w-44 h-44 absolute top-8 left-48 border-white border-4 rounded-full"></h>
               <h className="w-36 h-36 absolute top-10 left-80 border-white border-4 rounded-full"></h>
               <h className="w-48 h-48 absolute bottom-10 right-10 transform rotate-45 border-white border-4 "></h>
               <h className="w-36 h-36 absolute transform skew-y-12 bottom-12 right-52 border-white border-4 "></h>
               <p className="bg-gradient-to-r from-indigo-400 via-red-500 to-gray-500 transform hover:scale-125 p-2 absolute bottom-6 right-2/2 rounded-lg w-56 order-50"> Civil engineering is a professional engineering discipline that deals with the design, construction </p>
            </section>
         </div>
         <div className="flex">
            <h className="h-10 w-1/5 bg-green-600 font-semibold text-gray-800 rounded-md sticky top-28 left-4 flex justify-center items-center">Petroleum Engineering</h>
            <section className="w-full h-96 mb-1 bg-yellow-700 mx-8 flex justify-center p-8 rounded-lg  text-white font-serif">
               <img className="w-full mx-10 border-white border-4" src="https://inteng-storage.s3.amazonaws.com/img/iea/4N618ylRGJ/sizes/graduatetips-petroleumengineering_md.jpg" alt="fullimg" />
            </section>
         </div>

      </div>
   )
}

export default Departments