import { useParams,useNavigate } from "react-router-dom";
import { apiCall } from "../../ApiServices/apiCallManager";

const VerifyMail = () => {
    const navigate = useNavigate()
    const {token} = useParams();
    
      apiCall(null,"POST","admin/user","verify",null,token,null).then((response) => {
        console.log(response);
        if (response.status !== 200) {
          alert("verification failed")
        }
        else{
          alert("verified")
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error validating token:", error);
      });

  return (
    <div className="text-700 text-center">
      Verifying...
    </div>
  );
};

export default VerifyMail;
