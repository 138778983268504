import { useContext } from "react";
import images from "../Img";
import { globleinfo } from "../../App";

function Other() {
  const { tempLang,} = useContext(globleinfo);
  return (
    <div className="h-auto lg:mx-20">
      <div>
        <div className="sticky flex flex-wrap top-0 lg:mt-7 lg:h-16 text-3xl font-bold bg-gray-400">
          <p className="animate-fade-in-left-slow">L</p>
          <p className="animate-fade-in-left-slower">A</p>
          <p className="animate-fade-in-left-slowest">B</p>
          <p className="animate-fade-in-left-slow">O</p>
          <p className="animate-fade-in-left-slower">R</p>
          <p className="animate-fade-in-left-slowest">A</p>
          <p className="animate-fade-in-left-slow">T</p>
          <p className="animate-fade-in-left-slow">O</p>
          <p className="animate-fade-in-left-slow">R</p>
          <p className="animate-fade-in-left-slow">I</p>
          <p className="animate-fade-in-left-slow">E</p>
          <p className="animate-fade-in-left-slow">S</p>
          <p className=" text-gray-400">.</p>
          <p className="">&</p>
          <p className=" text-gray-400">.</p>
          <p className="animate-fade-in-left-slow">M</p>
          <p className="animate-fade-in-left-slower">E</p>
          <p className="animate-fade-in-left-slowest">S</p>
          <p className="animate-fade-in-left-slow">S</p>
          <p className=" text-gray-400">..</p>
          <p className="animate-fade-in-left-slow">F</p>
          <p className="animate-fade-in-left-slower">A</p>
          <p className="animate-fade-in-left-slowest">C</p>
          <p className="animate-fade-in-left-slow">I</p>
          <p className="animate-fade-in-left-slower">L</p>
          <p className="animate-fade-in-left-slowest">I</p>
          <p className="animate-fade-in-left-slow">T</p>
          <p className="animate-fade-in-left-slower">I</p>
          <p className="animate-fade-in-left-slowest">E</p>
          <p className="animate-fade-in-left-slow">S</p>
        </div>
        <div className="font-gray-700 text-lg mt-3">
         {tempLang.student.other.laboratories.subheading}
        </div>
        <div className="mt-3 lg:mt-14">
          <div className="text-lg text-gray-800 text-justify mb-5">
          {tempLang.student.other.laboratories.description}
          </div>
          <div className="text-black text-lg text-justify">
          {tempLang.student.other.laboratories.description1}
          </div>
        </div>
        <div className="flex flex-col space-y-4 mt-4 lg:flex-row lg:mt-10 lg:space-x-4 mb-10">
          <div className="lg:h-1/2 lg:w-1/2">
            <img
              className="h-full w-full animate-fade-in-downx"
              src={images.laboratories}
              alt=""
            ></img>
          </div>
          <div className="lg:h-1/2 lg:w-1/2">
            <img
              className="h-full w-full animate-fade-in-downr"
              src={images.mess}
              alt=""
            ></img>
          </div>
        </div>
      </div>
      <div>
        <div className="sticky top-0 mt-7 lg:h-16 xl:text-5xl py-1 text-3xl font-bold bg-gray-400">
        {tempLang.student.other.security_Parking.heading}
        </div>
        <div className="font-gray-900 text-xl font-medium mt-3">
        {tempLang.student.other.security_Parking.subheading}
        </div>
        <div className="mt-3 lg:mt-10 font-gray-800">
          <div className=" text-lg text-justify">
          {tempLang.student.other.security_Parking.description}
          </div>
          <div className="mt-5 text-lg text-justify">
          {tempLang.student.other.security_Parking.description1}
          </div>
        </div>
        <div className="mt-10">
          <div className="flex flex-col space-y-4 lg:flex-row mt-5 mb-5">
            <div className="lg:w-1/2">
              <img
                className="lg:w-full lg:h-full animate-fade-in-downx"
                src={images.play10}
                alt=""
              ></img>
            </div>
            <div className=" lg:ml-4 lg:w-2/3">
              <img
                className="lg:w-full lg:h-full animate-fade-in-downr"
                src={images.play9}
                alt=""
              ></img>
            </div>
            <div className=" lg:ml-4 lg:-2/3 xl:w-80">
              <img
                className="lg:w-full lg:h-full animate-fade-in-left-slow"
                src={images.students}
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Other;
