import { useContext } from "react";
import { globleinfo } from "../../App";

function Support(params) {
    const { tempLang,} = useContext(globleinfo);
    return (
     <div>
        

      <h1 className=" text-center xl:text-4xl text-3xl xl:mt-16 mt-20 font-bold text-gray-500"> {tempLang.student.support_Center.heading}</h1>

      <div>
        <div className=" flex flex-col xl:flex-row xl:mt-12 mt-6 space-y-4 items-center mb-8 justify-around">
            <div className=" bg-white p-4 border rounded-md shadow-lg">
                <div className=" p-2 w-full flex flex-col items-center justify-center">
                    <div className=" text-xl font-semibold mb-4">{tempLang.student.support_Center.card1.heading}</div>
                    <p className=" w-56 text-justify mb-8">{tempLang.student.support_Center.card2.description}</p>
                    <h1>{tempLang.student.support_Center.card2.mobileNo}</h1>
                    
                </div>
            </div>
            <div className=" bg-white p-4 border rounded-md shadow-lg">
                <div className=" p-2 w-full flex flex-col items-center justify-center">
                    <div className=" text-xl font-semibold mb-4">{tempLang.student.support_Center.card2.heading}</div>
                    <p className=" w-56 text-justify mb-8">{tempLang.student.support_Center.card2.description}</p>
                    <h1>{tempLang.student.support_Center.card2.mobileNo}</h1>
                    
                </div>
            </div>
            <div className=" bg-white p-4 border rounded-md shadow-lg">
                <div className=" p-2 w-full flex flex-col items-center justify-center">
                    <div className=" text-xl font-semibold mb-4">{tempLang.student.support_Center.card3.heading}</div>
                    <p className=" w-56 text-justify mb-8">{tempLang.student.support_Center.card2.description}</p>
                    <h1>{tempLang.student.support_Center.card2.mobileNo}</h1>
                    
                </div>
            </div>
            <div className=" bg-white p-4 border rounded-md shadow-lg">
                <div className=" p-2 w-full flex flex-col items-center justify-center">
                    <div className=" text-xl font-semibold mb-4">{tempLang.student.support_Center.card4.heading}</div>
                    <p className=" w-56 text-justify mb-8">{tempLang.student.support_Center.card2.description}</p>
                    <h1>{tempLang.student.support_Center.card2.mobileNo}</h1>
                    
                </div>
            </div>

        </div>
      </div>
     </div>
    );
}

export default Support