import React, { useState } from 'react'

function HelpBox() {
    const [show, setshow] = useState(false)
    // const style = "mx-12 w-72 h-10 border-2 border-gray-500  placeholder-current bg-green-100 pl-4 my-4";
    const style = "mx-4 w-64 h-10 border-2 border-gray-500  pl-1 my-4 bg-green-100"
    return (
        <div className="fixed bottom-4 right-0 z-40">

            {
                show && 
                <div className="bg-white w-72  h-full border-2 shadow-xl rounded-xl py-4 mr-4 ">
                    <div className="flex justify-between">
                        <div className="ml-4">
                            <p className="h-10 w-10 bg-purple-500 rounded-full  font-bold flex justify-center items-center text-2xl"> <img className="w-10 h-10" src="https://www.iconpacks.net/icons/2/free-customer-support-icon-1713-thumb.png" alt="fb" /></p>
                        </div>
                        <div className="flex  mr-2">
                            <button className="h-8 w-8  bg-indigo-500 rounded-full animate-bounce font-bold focus:outline-none flex justify-center items-center " onClick={() => setshow(false)}><svg className="w-6 h-6 text-white font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                        </div>
                    </div>
                    <input className={style} type="text" placeholder="Enter Your Name" />
                    <input className={style} type="text" placeholder="Enter Your Number" />
                    <input className={style} type="text" placeholder="Enter Your E-mail" />
                    <textarea className="mx-4 w-64 lg:h-24 h-16  border-2 border-gray-500  pl-1 my-4 bg-green-100 " type="textarea" placeholder="Type your Qustion...." />
                    <button className="bg-indigo-500 text-white w-64 h-10 rounded-lg py-2 font-bold mx-4 focus:outline-none">Sumbit</button>
                </div>
            }

            <div className=" flex justify-end mx-4 ">
                <button className="w-12 h-12 bg-gradient-to-tr from-purple-400 to-green-600 rounded-full flex justify-center items-center   focus:outline-none" onClick={() => setshow(!show)}> <img className="w-8 h-8" src="https://www.iconpacks.net/icons/2/free-customer-support-icon-1713-thumb.png" alt="fb" /></button>
            </div>
        </div>
    )
}
export default HelpBox
