import React from "react";
import image from "../Img/index";
import constant from "../../Constant/constant";

function PopupUI(params) {
  return (
    <div>
      <div>
        <div className="fixed   inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute  inset-0 bg-black  opacity-75"></div>
        </div>
        <div className="bg-grey-lighter   min-h-screen flex flex-col  fixed inset-0 overflow-y-scroll">
          <div className="container max-w-xl mb-20 xl:mb-0 mx-auto flex-1 flex flex-col items-center justify-center px-2">
            <div className="bg-white dark:bgcolor dark:text-white px-4 py-2 rounded text-black w-full  shadow-lg border border-gray-400 relative">
              <div className=" flex flex-row items-center py-4 mb-4 justify-between">
                {params.type === "student" ? (
                  <div className=" font-bold text-2xl"> Student Details</div>
                ) : (
                  <div className=" font-bold text-2xl"> Teacher Details</div>
                )}
                {params.type === "student" && params.buttonText === "Update" ? (
                  <div class="relative z-0 w-full mb-6 group">
                    <p>Student Id:{params.studentData.id}</p>
                  </div>
                ) : (
                  ""
                )}
                <div
                  onClick={() => params.openPopup()}
                  className=" cursor-pointer text-4xl absolute right-4 top-2 font-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <form onSubmit={params.handleSubmit}>
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      onChange={(e) => params.handleInputChange(e)}
                      name="name"
                      value={
                        params.type === "student"
                          ? params.studentData.name
                          : params.data.name
                      }
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      for="floating_first_name"
                      class="peer-focus:font-medium  absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First name
                    </label>
                  </div>

                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      onChange={(e) => params.handleInputChange(e)}
                      name="phone"
                      value={
                        params.type === "student"
                          ? params.studentData.phone
                          : params.data.phone
                      }
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      {...(params.buttonText === "Update" &&
                      params.type !== "student"
                        ? { disabled: true }
                        : {})}
                    />
                    <label
                      for="floating_last_name"
                      class="peer-focus:font-medium  absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Phone number(+91)
                    </label>
                  </div>
                </div>
                {params.type === "student" ? (
                  <div class="grid md:grid-cols-2 md:gap-6">
                    <div class="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        onChange={(e) => params.handleInputChange(e)}
                        name="father"
                        value={params.studentData.father}
                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        for="floating_first_name"
                        class="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Father's Name
                      </label>
                    </div>
                    <div class="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        onChange={(e) => params.handleInputChange(e)}
                        name="mother"
                        value={params.studentData.mother}
                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        for="floating_last_name"
                        class="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Mother's Name
                      </label>
                    </div>
                  </div>
                ) : (
                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      type="email"
                      onChange={(e) => params.handleInputChange(e)}
                      name="email"
                      value={params.data.email}
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      for="floating_email"
                      class="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {params.type === "student"
                        ? "Email address"
                        : "Email address"}
                    </label>
                  </div>
                )}

                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      type="date"
                      onChange={(e) => params.handleInputChange(e)}
                      name="dob"
                      value={
                        params.type === "student"
                          ? params.studentData.dob
                          : params.data.dob
                      }
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      for="floating_first_name"
                      class="peer-focus:font-medium absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Date of Birth
                    </label>
                  </div>
                  <div class="relative z-0 w-full xl:mb-6 mb-4 group">
                    <input
                      type="date"
                      onChange={(e) => params.handleInputChange(e)}
                      name={params.type === "student" ? "doa" : "doj"}
                      value={
                        params.type === "student"
                          ? params.studentData.doa
                          : params.data.doj
                      }
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      for="floating_last_name"
                      class="peer-focus:font-medium  absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {params.type === "student"
                        ? "Date of Addmission"
                        : "Date of Joining"}
                    </label>
                  </div>
                </div>

                {params.type === "student" ? (
                  <div class="grid md:grid-cols-3 md:gap-6">
                    <div class=" flex flex-col z-0 w-full mb-6 group">
                      <label
                        for="floating_first_name"
                        class=" pb-2 text-sm text-gray-800 dark:text-gray-400"
                      >
                        Class
                      </label>

                      <select
                        onChange={(e) => params.handleInputChange(e)}
                        name="classes"
                        value={params.studentData.classes}
                        class="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-2 bg-white hover:border-gray-400 focus:outline-none"
                        {...(params.buttonText === "Update"
                          ? { disabled: true }
                          : {})}
                      >
                        <option>Choose your class</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </select>
                    </div>
                    <div class=" flex flex-col z-0 w-full mb-6 group">
                      <label
                        for="floating_first_name"
                        class=" pb-2 text-sm text-gray-800 dark:text-gray-400"
                      >
                        Roll Number
                      </label>

                      <input
                        type="number"
                        onChange={(e) => params.handleInputChange(e)}
                        name="roll"
                        value={params.studentData.roll}
                        class="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-2 bg-white hover:border-gray-400 focus:outline-none"
                        {...(params.buttonText === "Update"
                          ? { disabled: true }
                          : {})}
                      />
                    </div>
                    <div class=" flex flex-col z-0 w-full mb-6 group">
                      <label
                        for="floating_first_name"
                        class=" pb-2 text-sm text-gray-800 dark:text-gray-400"
                      >
                        Section
                      </label>

                      <select
                        onChange={(e) => params.handleInputChange(e)}
                        name="section"
                        value={params.studentData.section}
                        class="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-2 bg-white hover:border-gray-400 focus:outline-none"
                        {...(params.buttonText === "Update"
                          ? { disabled: true }
                          : {})}
                      >
                        <option>Choose your Section</option>
                        <option>A</option>
                        <option>B</option>
                        <option>C</option>
                        <option>Arts</option>
                        <option>Commerce</option>
                        <option>Science</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div class="grid xl:grid-cols-3 xl:gap-6">
                    <div class=" flex flex-col col-span-2 xl:col-span-1 z-0 w-full xl:mb-6 mb-1 group">
                      <label
                        for="floating_first_name"
                        class=" pb-2 text-sm text-gray-800 dark:text-gray-400"
                      >
                        Best In Subject
                      </label>

                      <select
                        onChange={(e) => params.handleInputChange(e)}
                        name="bestInSubject"
                        value={
                          params.data.bestInSubject === "null" || undefined
                            ? "Choose"
                            : params.data.bestInSubject
                        }
                        class="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-2 bg-white hover:border-gray-400 focus:outline-none"
                      >
                        <option>Choose a Subject</option>
                        <option>Hindi</option>
                        <option>English</option>
                        <option>Math</option>
                        <option>Physics</option>
                        <option>Physical Science</option>
                        <option>Chemistry</option>
                        <option>Biology</option>
                        <option>Life Science</option>
                        <option>Geography</option>
                        <option>Political Science</option>
                        <option>Economics</option>
                        <option>History</option>
                        <option>Physical Education</option>
                        <option>Computer Science</option>
                        <option>Bengali</option>
                        <option>Sanskrit</option>
                        <option>Environmental Science</option>
                      </select>
                    </div>
                    <div class=" z-0 col-span-2 w-full overflow-x-auto  bg-gray-200 xl:mb-6 mb-4 xl:mt-6 mt-2 flex flex-row items-center justify-start space-x-2 group">
                      {params.data.subjectList.map((item, index) => (
                        <div
                          class="bg-gray-200 border border-green-400 text-gray-700 px-2 py-1  rounded relative"
                          role="alert"
                        >
                          <strong class="font-semibold whitespace-nowrap px-2">
                            {item}
                          </strong>
                          <span
                            onClick={() => params.removeSubjectList(index)}
                            class="  absolute right-0  py-1 "
                          >
                            <svg
                              class="fill-current h-4 w-4 text-gray-800"
                              role="button"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <title>Close</title>
                              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div
                  class={
                    params.type === "student"
                      ? "  w-full grid grid-cols-2 mt-4 md:gap-6"
                      : "grid md:grid-cols-2 mt-4 md:gap-6"
                  }
                >
                  {params.type === "student" ? (
                    <div className="order-last   md:flex  justify-end">
                      <div className="relative flex flex-col  ">
                        <div className="">
                          <img
                            className="w-28 h-28 border-2 border-green-500  m-0.5"
                            src={
                              params.selectedFile
                                ? URL.createObjectURL(params.selectedFile)
                                : params.studentData.studentImage?.length === 0
                                ? image.user
                                : `${constant.BACKEND_URL}/api/upload/${params.studentData.studentImage}`
                            }
                            title="Student Image"
                            alt="Student Image"
                          />
                        </div>
                        <div className="absolute flex justify-end top-20 left-20">
                          <div className="flex flex-row">
                            <div data-tip="Select Image from Local ">
                              {" "}
                              <svg
                                className="w-10 h-10 text-black cursor-pointer"
                                fill="gray"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                              {/* <button className=" bg-green-300 px-2" onClick={partyData.handleClickData}>save data</button> */}
                            </div>
                            {/* <partyData.ReactTooltip globalEventOff="click" /> */}
                            <div>
                              {" "}
                              <input
                                type="file"
                                name="studentImage"
                                className="w-8 h-6 mt-2 -ml-10 bg-gray-600 rounded opacity-25 "
                                onChange={(e) => params.handleInputChange(e)}
                                {...(params.buttonText !== "Update"
                                  ? { required: true }
                                  : {})}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div class=" flex flex-col -mt-4">
                        <div class=" flex flex-col z-0 w-full group">
                          <label
                            for="floating_first_name"
                            class=" pb-2 text-sm text-gray-800 dark:text-gray-400"
                          >
                            Qualification
                          </label>

                          <select
                            onChange={(e) => params.handleInputChange(e)}
                            name="qualification"
                            value={params.data.qualification}
                            class="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-2 bg-white hover:border-gray-400 w-56 focus:outline-none"
                          >
                            <option>Bsc(Bacholer of Science)</option>
                            <option>B.A(Bacholer of Art)</option>
                            <option>B.Ed</option>
                            <option>B.Tech</option>
                          </select>
                        </div>
                        <div class=" z-0 col-span-2 w-full overflow-x-auto bg-gray-200 xl:mb-6 mb-4 xl:mt-1 mt-2 flex flex-row items-start justify-start space-x-2 group">
                          {params.data.qualificationList.map((item, index) => (
                            <div
                              class="bg-gray-200 border border-green-400 text-gray-700 px-2 py-1  rounded relative"
                              role="alert"
                            >
                              <strong class="font-semibold whitespace-nowrap px-2">
                                {item}
                              </strong>
                              <span
                                onClick={() => params.removeCourseList(index)}
                                class="  absolute right-0  py-1 "
                              >
                                <svg
                                  class="fill-current h-4 w-4 text-gray-800"
                                  role="button"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <title>Close</title>
                                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="relative flex flex-col">
                        <div className="">
                          <img
                            className="w-28 h-28 border-2 border-green-500  m-0.5"
                            src={
                              params.selectedFile
                                ? URL.createObjectURL(params.selectedFile)
                                : params.data.teacherImage.length === 0
                                ? image.user
                                : `${constant.BACKEND_URL}/api/upload/${params.data.teacherImage}`
                            }
                            title="selectedFile"
                            alt="selectedFile"
                          />
                        </div>
                        <div className="absolute flex justify-end top-20 left-20">
                          <div className="flex flex-row">
                            <div data-tip="Select Image from Local ">
                              {" "}
                              <svg
                                className="w-10 h-10 text-black cursor-pointer"
                                fill="gray"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                              {/* <button className=" bg-green-300 px-2" onClick={partyData.handleClickData}>save data</button> */}
                            </div>
                            {/* <partyData.ReactTooltip globalEventOff="click" /> */}
                            <div>
                              {" "}
                              <input
                                type="file"
                                name="teacherImage"
                                className="w-8 h-6 mt-2 -ml-10 bg-gray-600 rounded opacity-25 "
                                onChange={(e) => params.handleInputChange(e)}
                                {...(params.buttonText !== "Update"
                                  ? { required: true }
                                  : {})}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                         
                    </>
                  )}

                  <div class="relative z-0   mb-6 mt-4 xl:mt-0 group ">
                    <textarea
                      type="text  "
                      onChange={(e) => params.handleInputChange(e)}
                      name="address"
                      value={
                        params.type === "student"
                          ? params.studentData.address
                          : params.data.address
                      }
                      rows={3}
                      class="block w-96 h-[10rem] md:min-w-96 md:h-[15rem]  py-1 mt-2 rounded-sm px-0  text-lg text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      for="floating_company"
                      class="peer-focus:font-medium m-2 text-md absolute text-lg text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Address
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {params.buttonText}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupUI;
