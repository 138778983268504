import React, { useContext, useEffect, useState } from 'react'
import ReactCardSlider from 'react-card-slider-component';
import { globleinfo } from '../../App';
import constant from '../../Constant/constant';
import { apiCall } from '../../ApiServices/apiCallManager';

function CardSlider() {
  const [student, setStudent] = useState([]);
  const [newList, setNewList] = useState([])
  const { tempLang } = useContext(globleinfo);
  //   const newArray = []
  const fatchData = async () => {
    
    apiCall("application/json","get","student","get/all").then((res) => {
        setNewList([...res.data])
        setStudent([...res.data]);

        const result = res.data !== undefined && res.data.map((arr) => {
          return {
            // image: "uploads/1683522613577.JPG",

            image: `${constant.BACKEND_URL}/api/upload/${arr.studentImage}`,
            title: arr.name,
            description: arr.address,
            clickEvent: sliderClick


          }
        })
        console.log(result)
        setNewList([...result])
        console.log(newList)
      });
  };

  useEffect(() => {
    fatchData();
  }, []);

  const sliderClick = (slider) => {
  }

  return (
    newList.length !== 0 ?
      (<div className=''>
        <div className="flex items-center  xl:mt-24 mt-0 justify-center h-16 font-serif font-bold bg-blue-400">
          <p className="flex items-center justify-center text-2xl text-white lg:text-4xl">{tempLang.home_Header.student_of_College.student_of_College}</p>
        </div>

        <div className=' flex items-center overflow-hidden mt-4 mx-0 justify-center'>
          <ReactCardSlider slides={newList} />
        </div>
      </div>) : null
  )
}
export default CardSlider;
