import { useContext } from "react"
import constant from "../../Constant/constant"
import { globleinfo } from "../../App"
const play = `${constant.BACKEND_URL}/api/upload/play.jpeg`
const play1 = `${constant.BACKEND_URL}/api/upload/play1.jpeg`
const play2 = `${constant.BACKEND_URL}/api/upload/play2.jpeg`
const play3 = `${constant.BACKEND_URL}/api/upload/play3.jpeg`
const play4 = `${constant.BACKEND_URL}/api/upload/play4.jpeg`
const play5 = `${constant.BACKEND_URL}/api/upload/play5.jpeg`
const play6 = `${constant.BACKEND_URL}/api/upload/play6.jpeg`
const play7 = `${constant.BACKEND_URL}/api/upload/play7.jpeg`
const play8 = `${constant.BACKEND_URL}/api/upload/play8.jpeg`
const play9 = `${constant.BACKEND_URL}/api/upload/play9.jpeg`
const play10 = `${constant.BACKEND_URL}/api/upload/play10.jpeg`
function Play () {
    const { tempLang,} = useContext(globleinfo);
    return(
        <div className=" h-auto text-xl lg:mx-20 lg:text-sm">
        <div>
         <div>
         <div className=" mt-7 font-bold text-black xl:text-5xl text-3xl animate-fade-in-downr">
             {tempLang.student.play.Sangathan.heading}             
         </div>
         <div className="font-semibold text-gray-700 mt-2  animate-fade-in-top-slow">
         {tempLang.student.play.Sangathan.subheading}
         </div>
         </div>
         <div className="mt-10 text-lg text-gray-600 text-justify mb-8">
         {tempLang.student.play.Sangathan.description}</div>
         <div>
             <img className="w-full h-96 animate-fade-in-down" src={play} alt=""></img>
         </div>
         <div className="flex flex-col lg:flex-row mt-5">
             <div className="content-center text-lg mb-4 lg:w-1/2 text-gray-600 text-justify animate-fade-in-left-slow">
             {tempLang.student.play.Sangathan.description1}
             </div>
             <div className=" lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-left-slower" src={play1} alt=""></img>
             </div>
             <div className="mb-4 lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-left-slowest" src={play2} alt=""></img>
             </div>
         </div>
         <div className="flex flex-col lg:flex-row mt-5 mb-5">
             <div className="mb-4 lg:w-1/2">
             <img className="w-full h-full animate-fade-in-right-slowest" src={play4} alt=""></img>
             </div>
             <div className="mb-4 lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-right-slower" src={play5} alt=""></img>
             </div>
             <div className=" lg:ml-4 lg:w-2/3 text-lg text-gray-600 text-justify animate-fade-in-right-slow">                    
             <p> {tempLang.student.play.Sangathan.description2}</p>
             <br/>
             <p> {tempLang.student.play.Sangathan.description3}</p>
             </div>
          </div>           
     </div>
     <div>      
         <div>
         <div className=" mt-7 font-bold text-black text-3xl xl:text-5xl">
         {tempLang.student.play.sport_facelity.heading}               
         </div>
         <div className="font-semibold text-gray-700 mt-2">
         {tempLang.student.play.sport_facelity.subheading}
         </div>
         </div>
         <div className="mt-10 mb-8 ">
             <p className=" text-gray-600 text-justify"> {tempLang.student.play.sport_facelity.description}</p>
             <p className="mt-5 text-gray-600 text-justify"> {tempLang.student.play.sport_facelity.description1}</p>
         </div>
         <div>
             <img className="lg:w-screen lg:h-96" src={play3} alt=""></img>
             <p className="mt-3 text-gray-600 text-justify text-lg"> {tempLang.student.play.sport_facelity.description2}</p>
         </div>
         <div>
             <p className="mt-5 font-bold text-gray-900 text-xl"> {tempLang.student.play.sport_facelity.heading2}</p>
             <p className="mt-5 text-gray-600 text-lg text-justify"> {tempLang.student.play.sport_facelity.description3}</p>
             <p className="mt-5 mb-5 text-gray-600 text-lg text-justify"> {tempLang.student.play.sport_facelity.description4}</p>
         </div>
     </div>
     <div>
     <div className="flex flex-col lg:flex-row mt-5 mb-5">
             <div className="mb-4 lg:w-1/2">
             <img className="w-full h-full animate-fade-in-left-slow" src={play9} alt=""></img>
             </div>
             <div className="mb-4 lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-left-slower" src={play10} alt=""></img>
             </div>
             <div className="mb-4 lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-left-slowest" src={play9} alt=""></img>
             </div>
          </div> 

          <div className="flex flex-col lg:flex-row mt-5 mb-5">
             <div className="mb-4 lg:w-1/2">
             <img className="w-full h-full animate-fade-in-right-slow" src={play8} alt=""></img>
             </div>
             <div className="mb-4 lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-right-slower" src={play7} alt=""></img>
             </div>
             <div className="mb-4 lg:ml-4 lg:w-2/3">
                 <img className="w-full h-full animate-fade-in-right-slowest" src={play6} alt=""></img>
             </div>
          </div> 
     </div>
     <div>
     <div className=" mb-6">
         <div className=" mt-7 font-bold text-black xl:text-5xl text-3xl animate-fade-in-downr">
         {tempLang.student.play.sport_initiatives.heading}               
         </div>
         <div className="font-semibold text-gray-700 mt-3">
         {tempLang.student.play.sport_initiatives.subheading}
         <p className="mt-4 font-normal text-lg">{tempLang.student.play.sport_initiatives.description}</p>
         <p className="mt-4 font-normal text-lg">{tempLang.student.play.sport_initiatives.description1}</p>
         </div>
         {/* <div className="mt-4 justify-between grid grid-cols-2 lg:grid-cols-3  text-lg px-4 lg:py-3">
             <div className="animate-fade-in-top">Sports Foundation</div>
             <div className="animate-fade-in-top">Sports Scholarships</div>
             <div className="animate-fade-in-top">United Football Club</div>
             <div className="animate-fade-in-top-slow">Chess Academy</div>
             <div className="animate-fade-in-top-slow">Shooting Club</div>
             <div className="animate-fade-in-top-slow">Cricket Academy</div>
         </div> */}
         </div>
     </div>
    </div> 
    );
}

export default Play;