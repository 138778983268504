import React, { useEffect, useState,useContext } from 'react';
import { apiCall } from '../../ApiServices/apiCallManager';
import { globleinfo } from '../../App';

function NoticeBoard() {
  const {tempLang} = useContext(globleinfo);
  // const{ filteredData} = useContext(globleinfo)
  const [listOfNotice, setNoticeOfList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const fatchData = async () => {
    apiCall("application/json","get","notice","get/all").then(async (res) => {
        // console.log(res.data)
        const data = await res.data
        setNoticeOfList([...data]);
        setFilteredData([...data])
        console.log(listOfNotice?.length)
        if (listOfNotice?.length !== 0) {
          const currentDate = new Date();
          const tenDaysAgo = new Date();
          tenDaysAgo.setDate(currentDate.getDate() - 10);
          const filtered = listOfNotice.filter((item) => {
            const itemDate = new Date(item.date);
            return (itemDate >= tenDaysAgo && itemDate <= currentDate);
          });
          console.log(filtered)

          setFilteredData([...filtered]);
          // console.log(filteredData)

        } else {
          setNoticeOfList([...data]);
        }
      });
  };

  useEffect(() => {
    fatchData();
  }, []);


  return (
    <div className=' '>
      <div class="slider">
        <div className=' absolute left-0 bg-blue-800 text-white h-full z-10 text-center flex items-center justify-center text-xl font-semibold px-4'>{tempLang.home_Header.Notification.notification}</div>
        <div class="slide-track">
          {filteredData.map((item, index) => (
            <div key={index} class="slide w-full flex flex-row items-center justify-center">
              <p className=' px-4 text-white font-normal text-xl tracking-wider'>{item.title} : {item.discription} ;</p>
              <img src='https://nta.ac.in/img/newicon.gif'></img>

            </div>
          ))}

        </div>
      </div>
    </div>
  )
}

export default NoticeBoard