// import React from 'react'
// import Calendar from 'react-calendar'
// import 'react-calendar/dist/Calendar.css'

function Calendar(params) {
    return(
        <div>
            <Calendar />
        </div>
    )
}

export default Calendar