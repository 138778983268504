import React from "react";
import images from "../Img";
function Faculty(params) {
    const teacherData = [
        {name:"Mr. ABC Sigh",
         degizanation:"Principal",
        image:images.principal},
        {name:"Ms. XYZ Kumari",
         degizanation:"Class Teacher 5-8",
        image:images.maddem2},
        {name:"Ms. XYZ Devi",
         degizanation:"Cordinater 5-8",
        image:images.maddem1},
        {name:"Mr. ABC Sharma",
         degizanation:"Teacher",
        image:images.teacher1}
    ]
    return(
        <div className="h-auto lg:mx-20 mx-4">
            <h1 className="font-bold text-2xl mb-4">Our Faculty</h1>
            <div className="grid grid-cols-1 gap-4 justify-around lg:grid-cols-4">
                {teacherData.map((list, index)=>(
                    <div className="border border-gray-200  xl:m-0 border-3 transform hover:scale-105 duration-1000 rounded-xl shadow-2xl">
            <img className="h-72 w-full" src={list.image} alt="facultyImage"></img>
            {/* <div className=" h-36 p-4 text-left">
            <p className="font-semibold text-blue-900 text-xl">{list.name}</p>
            <p className=" mt-2 max-w-xs font-normal text-gray-600 text-sm pb-1">{list.degizanation}</p>
            <p className=" mt-6 max-w-xs font-normal cursor-pointer text-gray-800 text-md pb-1">View Profile</p>
            </div> */}
            </div>
                ))}
            </div>
           
        </div>
    );
}

export default Faculty;