import React, { useEffect, useState } from "react";
import PopupUI from "./PopupUI";
import TeacherTable from "./TeacherTable";
import Cookies from "universal-cookie";
import { apiCall } from "../../ApiServices/apiCallManager";
const cookies = new Cookies();
const cookieToken = cookies.get("TOKEN");
function Member({ teachers, setTeachers }) {
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(teachers);
  const [selectedRowINdex, setSelectedRowIndex] = useState(-1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonText, setButtonText] = useState("Save");
  const tempData = {
    name: "",
    email: "",
    dob: "",
    doj: "",
    phone: "",
    qualification: "",
    qualificationList: [],
    bestInSubject: "",
    subjectList: [],
    address: "",
    teacherImage: "",
  };
  const [data, setData] = useState(tempData);

  const requestSearch = (searchedVal) => {
    console.log("search ");
    console.log(searchedVal);
    const filteredRows = teachers.filter((row) => {
      return row.name
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    console.log(filteredRows);
    if (searchedVal.length < 1) {
      setFilter(teachers);
    } else {
      setFilter(filteredRows);
    }
  };
  const openPopup = () => {
    setShow(!show);
    setData(tempData);
    setButtonText("Save");
  };

  const handleInputChange = (e) => {
    data[e.target.name] = e.target.value;
    if (e.target.name === "bestInSubject") {
      if (!data.subjectList.includes(data.bestInSubject))
        data.subjectList.push(data.bestInSubject);
    } else if (e.target.name === "qualification") {
      if (!data.qualificationList.includes(data.qualification))
        data.qualificationList.push(data.qualification);
    } else if (e.target.name === "teacherImage") {
      setSelectedFile(e.target.files[0]);
    }
    setData({ ...data });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const cookieToken = cookies.get("TOKEN");
    formData.append("file", selectedFile);
    setData({ ...data });
    formData.append("teacherData", JSON.stringify(data));
    if (buttonText === "Save") {
      apiCall('multipart/form-data',"post","teacher","create",null,cookieToken,formData).then((res) => {
          if (res.status === 200) {
            setData(data);
            setFilter([...filter,res.data]);
            setTeachers([...teachers,res.data]);
            setShow(!show);
          } else {
            return alert(res);
          }
        })
        .catch((err) => {
          alert("teacher is not created successfully");
        });
    } else if (buttonText === "Update") {
      apiCall('multipart/form-data',"post","teacher","update",null,cookieToken,formData)
        .then((res) => {
          console.log(res.status===200)
          if (res.status === 200) {
            setData(data);
            console.log(res.data);
            setShow(!show);
          } else {
            return alert(res);
          }
        })
        .catch((err) => {
          console.log(err)
          alert("teacher is not updated succesfully");
          setShow(!show);
        });
    }

    setData({ ...data });
  };

  const handlerUpdated = (index) => {
    setSelectedRowIndex(index);
    setData(teachers[index]);
    setShow(!show);
    setButtonText("Update");
  };
  const handlerDelete = (id) => {
    apiCall(null,"DELETE","teacher","delete",id,cookieToken)
    .then((res) => {
        if (res.status === 200) {
          setTeachers(teachers.filter((teacher) => teacher._id !== id));
        } else {
          alert(res);
        }
      })
      .catch((err) => console.log(err));
  };
  const removeSubjectList = (index) => {
    const newPeople = data.subjectList.filter(
      (person, itemIndex) => index !== itemIndex
    );
    data.subjectList = newPeople;
    setData({ ...data });
    console.log(newPeople);
  };
  const removeCourseList = (index) => {
    const newPeople = data.qualificationList.filter(
      (person, itemIndex) => index !== itemIndex
    );

    //  setData(newPeople)
    data.qualificationList = newPeople;
    setData({ ...data });
    console.log(newPeople);
  };
  return (
    <div class=" ">
      <div class="shadow w-full  border-b border-gray-200 sm:rounded-lg">
        <div>
          <div className="flex xl:flex-row py-2 flex-row-reverse bg-gray-300 border-b-2 border-gray-500  rounded-r-md">
            <div className=" mx-4">
              <button
                onClick={() => openPopup()}
                className=" px-3 py-2 bg-green-600 text-black border rounded-md"
              >
                Add Teacher
              </button>
            </div>

            <div>
              <input
                type="text"
                placeholder="Type to search..."
                onChange={(e) => requestSearch(e.target.value)}
                className="w-56 h-10 px-10 mx-2 border border-black rounded-lg focus:outline-none"
              />
            </div>
          </div>
          <TeacherTable
            handlerUpdated={handlerUpdated}
            handlerDelete={handlerDelete}
            teacher={filter}
          />
        </div>
      </div>
      {show && (
        <PopupUI
          selectedFile={selectedFile}
          role="subject"
          removeCourseList={removeCourseList}
          removeSubjectList={removeSubjectList}
          buttonText={buttonText}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          data={data}
          openPopup={openPopup}
        />
      )}
    </div>
  );
}

export default Member;
