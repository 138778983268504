import React from "react";
function Home({
  users,
  teachers,
  students,
  admissions,
  notices,
  gallery,
  events,
}) {
  return (
    <div>
      <div
        id="main"
        class="main-content flex-1 bg-gray-100 h-screen pb-24 xl:mt-2 md:pb-5"
      >
        <div class="bg-gray-800 lg:block xl:block hidden">
          <div class=" bg-gradient-to-r from-blue-900 to-gray-800 p-4 text-2xl text-white shadow">
            <h1 class="pl-2 font-bold">Admin Dashboard</h1>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-b-4 border-green-600 bg-gradient-to-b from-green-200 to-green-100 p-5 shadow-xl">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full bg-green-600 p-5">
                    <i class="fa fa-wallet fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h2 class="font-bold text-gray-600">Total Users</h2>
                  <p class="text-3xl font-bold">
                    {users.length}
                    <span class="text-green-500">
                      <i class="fas fa-caret-up"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-b-4 border-pink-500 bg-gradient-to-b from-pink-200 to-pink-100 p-5 shadow-xl">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full bg-pink-600 p-5">
                    <i class="fas fa-users fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h2 class="font-bold text-gray-600">Total Teacher</h2>
                  <p class="text-3xl font-bold">
                    {teachers.length}
                    <span class="text-pink-500">
                      <i class="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-b-4 border-yellow-600 bg-gradient-to-b from-yellow-200 to-yellow-100 p-5 shadow-xl">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full bg-yellow-600 p-5">
                    <i class="fas fa-user-plus fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h2 class="font-bold text-gray-600">No of Notice Posted</h2>
                  <p class="text-3xl font-bold">
                    {notices.length}
                    <span class="text-yellow-600">
                      <i class="fas fa-caret-up"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-b-4 border-yellow-600 bg-gradient-to-b from-yellow-200 to-yellow-100 p-5 shadow-xl">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full bg-yellow-600 p-5">
                    <i class="fas fa-user-plus fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h2 class="font-bold text-gray-600">Total Student</h2>
                  <p class="text-3xl font-bold">
                    {students.length}
                    <span class="text-yellow-600">
                      <i class="fas fa-caret-up"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-b-4 border-pink-500 bg-gradient-to-b from-pink-200 to-pink-100 p-5 shadow-xl">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full bg-pink-600 p-5">
                    <i class="fas fa-users fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h2 class="font-bold text-gray-600">
                    Admission form uploaded
                  </h2>
                  <p class="text-3xl font-bold">
                    {admissions.length}
                    <span class="text-pink-500">
                      <i class="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-b-4 border-green-600 bg-gradient-to-b from-green-200 to-green-100 p-5 shadow-xl">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full bg-green-600 p-5">
                    <i class="fa fa-wallet fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h2 class="font-bold text-gray-600">Total Events</h2>
                  <p class="text-3xl font-bold">
                    {events.length}
                    <span class="text-green-500">
                      <i class="fas fa-caret-up"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="mt-2 flex flex-grow flex-row flex-wrap">
          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-transparent bg-white shadow-xl">
              <div class="rounded-tl-lg rounded-tr-lg border-b-2 border-gray-300 bg-gradient-to-b from-gray-300 to-gray-100 p-2 uppercase text-gray-800">
                <h class="font-bold uppercase text-gray-600">Graph</h>
              </div>
              <div class="p-5">
                <canvas
                  id="chartjs-7"
                  class="chartjs"
                  width="undefined"
                  height="undefined"
                ></canvas>
              </div>
            </div>
          </div>

          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-transparent bg-white shadow-xl">
              <div class="rounded-tl-lg rounded-tr-lg border-b-2 border-gray-300 bg-gradient-to-b from-gray-300 to-gray-100 p-2 uppercase text-gray-800">
                <h2 class="font-bold uppercase text-gray-600">Graph</h2>
              </div>
              <div class="p-5">
                <canvas
                  id="chartjs-0"
                  class="chartjs"
                  width="undefined"
                  height="undefined"
                ></canvas>
              </div>
            </div>
          </div>

          <div class="w-full p-6 md:w-1/2 xl:w-1/3">
            <div class="rounded-lg border-transparent bg-white shadow-xl">
              <div class="rounded-tl-lg rounded-tr-lg border-b-2 border-gray-300 bg-gradient-to-b from-gray-300 to-gray-100 p-2 uppercase text-gray-800">
                <h2 class="font-bold uppercase text-gray-600">Graph</h2>
              </div>
              <div class="p-5">
                <canvas
                  id="chartjs-1"
                  class="chartjs"
                  width="undefined"
                  height="undefined"
                ></canvas>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default Home;
