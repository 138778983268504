import React, { Fragment, useState, useEffect } from 'react';
// import './style.css';
function ProgrammsDetails(props) {
    let [valS, setValS] = useState([]);
    let [val, setVal] = useState([]);
    // var active = "h-full font-semibold text-yellow-400 cursor-pointer"
    // var notactive = "h-full font-semibold cursor-pointer"

    const [colors, setColors] = useState([])
    const [current, setCurrent] = useState(0)
    // const [currentItem, setCurrentItem] = useState(null)

    useEffect(() => {
        console.log("USE EFFECT" + valS);
        basicSetup();
    }, [props.data]);

    const basicSetup = () => {
        const size = props.data.length % 2 === 0 ? props.data.length / 2 : Math.round(props.data.length / 2 + 1);
        var tempVisible = [],
            valS = [],
            val = []
        for (var i = 0; i < size; i++) {
            tempVisible.push(false)
            valS.push(false)
            val.push(false)

        }
        setValS(tempVisible)
        setValS(valS)
        setVal(tempVisible)
        setVal(val)
        console.log(tempVisible)
    }

    const ChangeVisibility = (index) => {
        console.error("TEST " + index)
        var temp = []
        valS.map((v, i) => {
            if ((index != null) && (i === parseInt(index / 2))) {
                temp.push(true)
            } else {
                temp.push(false)
            }
        })
        console.log("KKKKKKKK " + temp)
        setValS(temp)
    }
    const ChangeVisibilityHandler = (index) => {
        if (index !== current) {
            // colors[current] = notactive
            // colors[index] = active
            setCurrent(index)
            setColors(colors)
        }
        var temp = []
        val.map((v, i) => {
            if ((index != null) && (i === parseInt(index / 2))) {
                temp.push(true)
            } else {
                temp.push(false)
            }
        })
        console.log("KKKKKKKK " + temp)
        setVal(temp)
    }

    return (
        <div className="py-8 mx-4">
            <div>
                <h4 className="-mt-4 text-2xl font-bold text-gray-500 lg:px-8 lg:ml-8 lg:mt-4">Courses Details</h4>
                <ul className="grid mt-4 lg:mx-16 lg:gap-4 lg:grid-cols-2">
                    {
                        props.data.map((option, index) => (
                            (index % 2) === 1 ?
                                <Fragment>
                                    <span>
                                        <li className={colors[index]} key={parseInt(Math.random(10) * 10000)} onClick={() => { ChangeVisibilityHandler(index, option); ChangeVisibility(null) }} >
                                            <div className="flex flex-row space-x-4">
                                                <div className="mt-3 cursor-pointer"><img width="20" height="20" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU" alt="programDetails image1"></img></div>
                                                <div className="w-auto py-1 text-xl font-medium cursor-pointer transform duration-1000 hover:text-yellow-400 hover:scale-110 ">{option}</div>
                                            </div>
                                        </li>
                                    </span>
                                    <li className={val[parseInt(index / 2)] ? "h-auto lg:col-span-2 lg:w-max" : "hidden"}>
                                        {
                                            // visible[parseInt(index / 2)]
                                        }
                                        <div className="relative w-full h-32 px-10 py-6 mt-4 rounded-lg shadow-lg lg:-mt-0 lg:mx-2 bg-blue-50">
                                            <div className="-mt-4 lg:mt-0"> {props.courseDetailsMap[option]} </div>
                                            <div className="absolute right-0 mr-3 border-2 border-blue-400 rounded-full cursor-pointer lg:top-0 top-5 lg:mt-5"
                                                onClick={() => ChangeVisibilityHandler(null)}>
                                                <svg
                                                    className="w-6 h-6 text-blue-400" fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                </Fragment>
                                :
                                <div>
                                    <span>
                                        <li key={parseInt(Math.random(10) * 10000)} className={colors[index]} onClick={() => { ChangeVisibility(index, option); ChangeVisibilityHandler(null) }}>
                                            <div className="flex flex-row space-x-4">
                                                <div className="mt-3 cursor-pointer"><img width="20" height="20" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrKTF9mSh1qte2dTX4pqUFICT4vdtPM89xg&usqp=CAU" alt="programDetails image1"></img></div>
                                                <div className="w-auto py-1 text-xl font-medium transform cursor-pointer lg:hover:text-yellow-400 lg:hover:scale-110  duration-1000">{option}</div>
                                            </div>
                                        </li>
                                    </span>

                                    <li className={valS[parseInt(index / 2)] ? "h-auto lg:col-span-2 lg:w-max" : "hidden"}>
                                        {
                                            // visible[parseInt(index / 2)]
                                        }
                                        <div className="relative w-full h-32 px-10 py-6 mt-4 rounded-lg shadow-lg lg:mx-2 bg-blue-50">
                                            <div className="-mt-4 lg:mt-0">{props.courseDetailsMap[option]}</div>
                                            <div className="absolute top-0 right-0 mt-5 mr-3 border-2 border-blue-400 rounded-full cursor-pointer"
                                                onClick={() => ChangeVisibility(null)}>
                                                <svg
                                                    className="w-6 h-6 text-blue-400" fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default ProgrammsDetails;
