import React, { useContext } from "react";

function PopUpUi(params) {
  return (
    <div>
      <div class="fixed z-50  inset-0 items-center" aria-hidden="true">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-700 opacity-75"></div>
        </div>
        <div class="flex flex-col xl:mx-6 mx-auto absolute inset-0 top-32">
          <div className="flex justify-center">
            {/*{  <Addparty tempObj={tempObj} updateParty={updateParty} addPartyToDB={addPartyToDB} close={closePopupHandler} />}*/}
            <div className="flex flex-col xl:w-3/5 w-full h-full xl:mx-48 mx-4 xl:mr-20 mr-4 space-y-3 bg-white rounded-lg shadow-2xl">
              <div className="flex justify-between  font-serif border-b-2 border-gray-300">
                <p className="mx-4 my-2 text-xl font-semibold">
               Event Details
                </p>
                <div className="px-0 py-2 mr-2 font-serif">
                  <svg
                    onClick={() => params.onClose()}
                    className="w-8 cursor-pointer h-8 font-bold"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div class="col-span-4">
                <div class="flex  flex-col justify-center items-center p-2 bg-white  rounded-sm shadow-lg">
                <div class="">
                         Title: {params.popuplist.title}
                        </div>
                        <div class="">
                         discription: {params.popuplist.discription}
                        </div>
                        <div class="">
                         Date: {params.popuplist.date}
                        </div>
                </div>
              </div>

             
              <div className="flex justify-center mx-4 my-2">
                <button
                  onClick={() => params.onClose()}
                  className="h-10 font-serif my-2 text-lg hover:shadow-lg hover:bg-blue-400 font-medium text-white bg-blue-600 shadow-md w-44 focus:outline-none cursor-pointer"
                >
                Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUpUi;
