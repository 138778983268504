import React, { useEffect, useState } from "react";
import EventPopUp from "./EventPopUp";
import Cookies from "universal-cookie";
import { apiCall } from "../../ApiServices/apiCallManager";
const cookies = new Cookies();
const cookieToken = cookies.get("TOKEN");

function Event({ events, setEvents }) {
  const tempData = {
    title: "",
    date: "",
    startTime: "",
    endTime: "",
    discription: "",
  };
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [data, setData] = useState(tempData);
  const [showModal, setShowModal] = React.useState(false);
  const [listOfEvents, setEventOfList] = useState([])

  const fatchData = async () => {
    apiCall("application/json","get","event","get/all").then((res) => {
      console.log(res.data);
      setEventOfList([...res.data])
    });
  };
  useEffect(() => {
    fatchData();
  }, []);

  const changeHandlerData = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  const validateTimingOrder = () => {};

  const HandleEventButton = () => {
    setShowModal(true);
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();

    if (validateTimingOrder()) {
      return;
    }

    apiCall("application/json","post","event","create",null,cookieToken,data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setEvents([...events, res.data]);
          setShowModal(false);
        }
      })
      .catch((err) => {
        console.log("event is not created successfully");
      });
  };

  const deleteSelectedUsers = (e) => {
    e.preventDefault();
    const userIDs = selectedUsers.map((user) => user._id);
    
    apiCall("application/json","delete","event","delete",null,cookieToken,JSON.stringify({ userIDs })).then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          setEvents(
            events.filter(
              (user) =>
                !selectedUsers.find(
                  (selectedUser) => selectedUser._id === user._id
                )
            )
          );
          setSelectedUsers([]);
        }
      })
      .catch((err) => alert("Error deleting Try again."));
  };

  const toggleUserSelection = (user) => {
    if (selectedUsers.find((selectedUser) => selectedUser._id === user._id)) {
      setSelectedUsers(
        selectedUsers.filter((selectedUser) => selectedUser._id !== user._id)
      );
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <div>
      <div class="min-h-screen bg-gray-100">
        <h1 className=" text-3xl pt-6 text-center font-bold">Event List</h1>

        {showModal ? (
          <EventPopUp
            setShowModal={setShowModal}
            changeHandlerData={changeHandlerData}
            handlerSubmit={handlerSubmit}
            data={data}
          />
        ) : null}
        <div class="py-6">
          <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 inline-block w-full sm:px-6 lg:px-8">
                          <div class="table-wrp block max-h-96">
                            <table class="w-full">
                              <thead class="bg-white border-b sticky top-0">
                                <tr>
                                  <th
                                    scope="col"
                                    class="text-md font-bold text-gray-900 px-6 py-4 text-left"
                                  >
                                    Select
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-md font-bold text-gray-900 px-6 py-4 text-left"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-md font-bold text-gray-900 px-6 py-4 text-left"
                                  >
                                    Start Time
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-md font-bold text-gray-900 px-6 py-4 text-left"
                                  >
                                    End Time
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-md font-bold text-gray-900 px-6 py-4 text-left"
                                  >
                                    Title
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-md font-bold text-gray-900 px-6 py-4 text-left"
                                  >
                                    Description
                                  </th>
                                  <th
                                    scope="col"
                                    class="text-md w-24 overflow-hidden  flex flex-row space-x-3 font-medium text-gray-900 py-4 text-left"
                                  >
                                    <div
                                      onClick={(e) => HandleEventButton(e)}
                                      className=" px-2  py-2 hover:bg-green-300 text-green-600 flex items-center justify-center cursor-pointer rounded-full"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M12 4.5v15m7.5-7.5h-15"
                                        />
                                      </svg>
                                    </div>
                                    {selectedUsers.length !== 0 ? (
                                      <button
                                        className=" text-red-600"
                                        onClick={(e) => deleteSelectedUsers(e)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          class="w-6 h-6"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                          />
                                        </svg>
                                      </button>
                                    ) : null}
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="h-10 overflow-y-auto">
                                {events.map((item, i) => (
                                  <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      <input
                                        type="checkbox"
                                        checked={selectedUsers.find(
                                          (selectedUser) =>
                                            selectedUser._id === item._id
                                        )}
                                        onChange={() =>
                                          toggleUserSelection(item)
                                        }
                                      ></input>
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {item.date}
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {item.startTime}
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                      {item.endTime}
                                    </td>
                                    <td class="text-sm font-bold text-gray-900 px-6 py-4">
                                      <div className="w-48 break-words">
                                        {item.title}
                                      </div>
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4">
                                      <div className="w-64 break-words">
                                        {item.discription}
                                      </div>
                                    </td>
                                    <td class="text-sm font-extrabold text-gray-900 px-6 py-4 whitespace-nowrap"></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Event;
