import React from "react";

const Model = ({ title, description, closeAction }) => {
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center  bg-green-800 bg-opacity-50 z-50">
        <div className="p-6 rounded-lg border-2 border-gray-500 bg-orange-200 w-1/2">
          <div className="mb-4">
            <h2 className="block mb-1 text-xl font-bold">{title}</h2>
          </div>
          <div className="mb-4">
            <label className="block mb-1">{description}</label>
          </div>
          <div className="flex justify-end">
            <button
              onClick={closeAction}
              className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded ml-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Model;
