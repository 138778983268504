import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Departments from "./Departments";
import CourseOffered from "./CourseOffered";
import Calendar from "./Calendar";

function Academic() {
  const history = useNavigate();
  const active =
    "h-full border-b-4 border-white px-3 font-semibold border-blue-500 hover:border-blue-500 text-yellow-400 cursor-pointer";
  const notactive =
    "h-full border-b-4 border-white px-3 font-semibold hover:border-blue-500 cursor-pointer";
  const [options, setOptions] = useState([
    "Departments",
    "CourseOffered",
    "Calendar",
  ]);
  let [colors, setColors] = useState([]);
  let [current, setCurrent] = useState(0);
  const { id } = useParams();


  useEffect(() => {
    basicSetup();
  }, []);

  const location = useLocation();
  const basicSetup = () => {
    var colors = [active, notactive, notactive];
    const path = location.pathname.split("/")[2];
    setColors(colors);
    if (path === "courseoffered") {
      setCurrent(1);
      setCurrentColors("courseoffered");
    }
    if (path === "calendar") {
      setCurrent(2);
      setCurrentColors("calendar");
    }

   
  };

  const setCurrentColors = (type) => {
  
    colors[0] = notactive;
    colors[1] = notactive;
    colors[2] = notactive;

    if (type === "calendar") {
      colors[2] = active;
    } else if (type === "courseoffered") {
      colors[1] = active;
    } else {
      colors[0] = active;
    }
    setColors(colors);
  };

  const optionChangeHandler = (params) => {
    if (params != current) {
      setCurrent(params);
    }
    let path = "/academics/";
    switch (params) {
      case 1:
        path = path + "courseoffered";
        RenderSwitch("courseoffered");
        //setCurrentColors("directory")
        break;
      case 2:
        path = path + "calendar";
        RenderSwitch("calendar");
        //setCurrentColors("headofdept")
        break;
      default:
        path = path + "departments";
        RenderSwitch("departments");
        //setCurrentColors("news")
        break;
    }
    history.push(path);
  };

  const RenderSwitch = (param) => {
    switch (id) {
      case "courseoffered":
        setCurrentColors("courseoffered");
        setCurrent(1);
        return <CourseOffered />;

      case "calendar":
        setCurrentColors("calendar");
        setCurrent(2);
        return <Calendar />;

      default:
        setCurrentColors("departments");
        setCurrent(0);
        return <Departments />;
    }
  };

  return (
    <div className="flex-col">
      <div className="flex flex-wrap space-x-6 lg:mx-16 border-b-2 my-12">
        <div className="mb-4 ml-2">
          <h1 className="text-xl h-full">Infrastructure</h1>
        </div>
        <div className="flex flex-row justify-between lg:space-x-4 lg:border-l-2 items-center lg:ml-2 lg:pl-8 border-blue-300">
          {options.map((option, index) => (
            <a
              onClick={() => optionChangeHandler(index)}
              className={colors[index]}
            >
              {option}
            </a>
          ))}
        </div>
      </div>
      <div>
        <RenderSwitch data={options[current]} />
      </div>
    </div>
  );
}

export default Academic;
