import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import image from "../Img/index";
import Cookies from "universal-cookie";
import Home from "./Home";
import Member from "./Member";
import Addmission from "./Addmission";
import Setting from "./Setting";
import Student from "./Student";
import Notice from "./Notice";
import Event from "./Event";
import Gallery from "./Gallery";
import constant from "../../Constant/constant.js";
import { apiCall } from "../../ApiServices/apiCallManager.js";
const cookies = new Cookies();

async function fetchData(path, params = {}) {
  try {
    const url = `${constant.BACKEND_URL}` + path;
    const queryParams = new URLSearchParams(params);
    const response = await fetch(`${url}?${queryParams}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const Loader = ({ text }) => {
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center  bg-green-800 bg-opacity-50 z-50">
        <div className="flex items-center justify-center h-screen opacity-95">
          <div className="border-4 border-green-600 p-12 w-44 h-44 rounded-full">
            <h3 className="text-orange-500 font-bold text-xl">{text}</h3>
            <div className="flex space-x-2 mt-4">
              <div className="w-4 h-4 bg-orange-600 rounded-full animate-bounce"></div>
              <div className="w-4 h-4 bg-orange-600 rounded-full animate-bounce"></div>
              <div className="w-4 h-4 bg-orange-600 rounded-full animate-bounce"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function AdminTheme1() {
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [admissions, setAdmissions] = useState([]);
  const [notices, setNotices] = useState([]);
  const [events, setEvents] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [text, setText] = useState("Saving");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  var active = "block bg-white text-black py-2";
  var notactive =
    "block border-b-2 border-gray-800 py-1 align-middle text-white py-2 hover:bg-blue-500";
  const options = [
    "Home",
    "Teacher",
    "Addmission",
    "Student",
    "Notice",
    "Event",
    "Gallery",
    "Setting",
  ];
  const [color, setColor] = useState([]);

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const token = cookies.get("TOKEN");
    if (token !== undefined || token !== "") {
      apiCall(null,"post","admin/user","validate-token",null,token)
        .then((response) => {
          if (response.status !== 200) {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Error validating token:", error);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
    basicSetup();
    fetAllData();
    const handleBeforeUnload = (event) => {
      console.log("User is closing the tab");
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const basicSetup = () => {
    var colors = [
      active,
      notactive,
      notactive,
      notactive,
      notactive,
      notactive,
      notactive,
      notactive,
    ];
    setColor(colors);
  };

  const fetAllData = async () => {
    const tempNotice = await apiCall("application/json","get","notice","get/all");
    setNotices(tempNotice.data);
    const tempEvents = await apiCall("application/json","get","event","get/all");;
    setEvents(tempEvents.data);
    const tempUsers = await apiCall("application/json","get","admin/user","get/all");;
    setUsers(tempUsers.data);
    const tempTeachers = await apiCall("application/json","get","teacher","get/all");
    const teacherData = tempTeachers.data
    setTeachers(teacherData);
  };

  // console.log(notices,events,users,teachers);


  const iconClickHandler = (index) => {
    if (index !== current) {
      setCurrent(index);
      setColor(index);
      color[current] = notactive;
      color[index] = active;
      setCurrent(index);
      setColor(color);
    }
  };

  const SwitchCase = (props) => {
    switch (props.value) {
      case "Home":
        return (
          <Home
            users={users}
            teachers={teachers}
            students={students}
            admissions={admissions}
            notices={notices}
            gallery={gallery}
            events={events}
          />
        );
      case "Teacher":
        return <Member teachers={teachers} setTeachers={setTeachers} />;
      case "Addmission":
        return (
          <Addmission admissions={admissions} setAdmissions={setAdmissions} />
        );
      case "Student":
        return <Student students={students} setStudents={setStudents} />;
      case "Notice":
        return <Notice notices={notices} setNotices={setNotices} />;
      case "Event":
        return <Event events={events} setEvents={setEvents} />;
      case "Gallery":
        return <Gallery gallery={gallery} setGallery={setGallery} />;
      case "Setting":
        return <Setting users={users} setUsers={setUsers} />;
      default:
        return <p>You are a User</p>;
    }
  };

  const isAllowedForMobileView = (item) => {
    return item !== "Member" && item !== "Student" && item !== "Setting";
  };

  const logOut = () => {
    cookies.remove("TOKEN");
    navigate("/login");
  };
  return (
    <>
      {isLoading && <Loader text={"Loading data..."} />}
      <div class="flex flex-col xl:flex-row h-screen relative">
      <div class="bg-gray-800 block xl:hidden">
          <div class=" bg-gradient-to-r from-blue-900 to-gray-800 p-4 text-2xl text-white shadow">
            <h1 class="pl-2 font-bold">Admin Dashboard</h1>
          </div>
        </div>
        <div className="hidden lg:block lg:flex-1/5 flex-shrink-0 w-44">
          <div class=" fixed bottom-0 z-40 xl:z-10  h-16 w-full content-center  bg-yellow-700 shadow-xl xl:relative xl:h-screen xl:w-full">
            <div class="flex flex-col items-center h-44 py-4">
              <div class="flex-shrink">
                <img
                  class="rounded-full w-16 h-16 bg-green-600"
                  src={image.user}
                  alt="image_icon"
                ></img>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold text-white">{"user name"}</h2>
              </div>
              <button
                onClick={() => logOut()}
                className="w-32 py-2 rounded-lg text-white hover:bg-red-600 bg-red-700 hover:text-xl hover:font-bold"
              >
                Logout
              </button>
            </div>
            <ul class="text-start hover:cursor-pointer">
              {options.map((item, indexNo) => (
                <div
                  onClick={() => iconClickHandler(indexNo)}
                  className={color[indexNo]}
                >
                  <div className="w-36">
                    <p className="items-start pl-4">{item}</p>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-1 overflow-hidden mb-12 overflow-y-scroll overflow-x-scroll   bg-orange-500">
          <SwitchCase value={options[current]} />
        </div>
        <div class="fixed inset-x-0 bottom-0 h-16 lg:hidden xl:hidden">
          <div className="btm-nav">
            {options.map(
              (item, indexNo) =>
                isAllowedForMobileView(item) && (
                  <button
                    onClick={() => iconClickHandler(indexNo)}
                    className={
                      current === indexNo
                        ? "bg-pink-200 text-pink-600 border-b-4"
                        : ""
                    }
                  >
                    <div class="flex w-full flex-col border-r-2 h-screen items-center justify-center bg-gray-200">
                      <p class="border-2 border-double border-red-500 px-2 font-extrabold ring-4">
                        {item.charAt(0)}
                      </p>
                      <span className="font-bold">{item}</span>
                    </div>
                  </button>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminTheme1;
