import React from 'react'

function AddmissionPopup(params) {
  return (
    <div>
        <div>
        <div className="fixed  inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black  opacity-75"></div>
        </div>
        <div className="bg-grey-lighter  min-h-screen flex flex-col  fixed inset-0 ">
          <div className="container overflow-y-auto max-w-xl mx-auto flex-1 flex flex-col items-center justify-center px-2">
            <div className="bg-white dark:bgcolor dark:text-white px-4 py-2 rounded text-black w-full  shadow-lg border border-gray-400 relative">
         <div className=' flex flex-row items-center py-4 xl:mb-4 justify-between'>
         <div className=' font-bold text-2xl'> Addmission Forms</div>
            
            <div onClick={()=>params.openPopup()} className=' cursor-pointer text-4xl absolute right-4 top-2 font-bold'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

            </div>
         </div>

         <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
          <div class="text-gray-600">
            <p class="font-medium text-lg">Upload Your Addmission form Pdf</p>
            <p>Please fill out all the fields.</p>
          </div>

          <div class="lg:col-span-2">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
              <div class="md:col-span-5">
                <label for="full_name">Select Class</label>
                <input onChange={(event)=>params.handleImageChange(event)} type="text" name="classes" value={params.addmission.classes} id="full_name" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
              </div>


              <div className='md:col-span-5'>
                <label class="text-gray-600 dark:text-gray-200" for="passwordConfirmation">Text Area</label>
                <textarea onChange={(event)=>params.handleImageChange(event)} name="description" value={params.addmission.description} id="textarea" rows={4} type="textarea" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"></textarea>
            </div>

              <div class="md:col-span-5">
              <div class="mt-1 w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <svg class="mx-auto h-12 w-12 text-gray-700" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span class="">Upload a file</span>
                      <input onChange={(event)=>params.handleImageChange(event)} name="image" id="file-upload" type="file" class="sr-only"></input>
                    </label>
                    <p class="pl-1 text-gray-700">or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-700">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
              </div>
      
              <div class="md:col-span-5 text-right">
                <div class="inline-flex items-end">
                  <button  onClick={(e) => params.onHandlerSubmit(e)} class="bg-blue-500 hover:bg-blue-gray-600 font-bold py-2 px-4 rounded">Submit</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  

            </div>
             </div>
        </div>
      </div>
    </div>
  )
}

export default AddmissionPopup