import React, { useEffect, useState } from "react";
import passwordHelper from "../../utils/Password";
import {useNavigate} from 'react-router-dom'
import { apiCall } from "../../ApiServices/apiCallManager";

const ResetPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");

  const setForgotPasswordLinkToEmail = async () => {
    console.log(email);
    if (!email) {
      alert("Please enter email");
      return;
    }
    console.log("forgot password mail");
    try {
      // Send a request to the server to generate a reset token
      const response =await apiCall("application/json","post","admin/user","forgot-password",null,null,{"email":email})

      alert(response.data.message);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      if(error.message==="Request failed with status code 400"){
        alert("Invalid email");
      }
    }
  };

  const resetPassword = async () => {
    console.log("reser Password");
    if (password !== confirmPassword || password.length < 8) {
      alert("please enter atlest 8 character password");
    }
    try{const res = await apiCall("application/json","post","admin/user","reset-password",null,token,{"password":password})
    if(res.status===200){
      alert("password reset successfully");
      navigate('/login')

    }
  }catch(error){
    if(error.message==="Request failed with status code 500"){
      alert("Somthing wen wrong")
    }
    if(error.message==="Request failed with status code 400"){
      alert("Authentication Failed")
    }
  }
  };

  useEffect(() => {
    if (window.location.pathname.split("/")[3] !== undefined) {
      setToken(window.location.pathname.split("/")[3]);
    }
  }, []);

  return (
    <div className="w-full flex items-center justify-center">
      {token !== "" ? (
        <div className="space-y-6 flex-col border-4 border-gray-300 px-6 py-4 w-1/4 rounded-lg">
          <div>Enter reset password to reset your password</div>
          <div>
            <input
              type="password"
              placeholder="Password"
              className="input input-bordered w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Confirm Password password"
              className="input input-bordered w-full"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <p className="text-gray-800">
            {" "}
            Sample Password <br />
            <span className="text-xl">
              {passwordHelper.generateStrongPassword()}
            </span>
          </p>
          <button
            onClick={() => resetPassword()}
            className="btn btn-active btn-secondary"
          >
            Reset Password
          </button>
          <div>{message !== "" ? message : ""}</div>
        </div>
      ) : (
        <div className="space-y-6 flex-col border-4 border-gray-300 px-6 py-4 w-1/4 rounded-lg">
          <div>
            <div className="label">
              <span className="label-text">Enter your email</span>
            </div>
            <div>
              <input
                type="email"
                placeholder="abc@xyz.com"
                className="input input-bordered w-full"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <button
            className="btn btn-active btn-secondary"
            onClick={setForgotPasswordLinkToEmail}
          >
            Send reset password link
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
