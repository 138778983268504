import constant from "../Constant/constant"
import axios from "axios"
const baseUrl = `${constant.BACKEND_URL}/api/v1`
export const apiCall = async (contentType=null,method,segment,section,id=null,token=null,formData=null)=>{
  const isId = id ? `/${id}` : ``;
  return await axios(
    `${baseUrl}/${segment}/${section}${isId}`,
    {
      data:formData,
      method:method,
      headers:{
        "Content-Type":contentType,
        Authorization: `Bearer ${token}`
      },
    }
  )
  
}