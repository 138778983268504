import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Learn from "./Learn";
import Play from "./Play";
import Study from "./Study";
import Others from "./Others";

function Student() {
  var active =
    "h-full border-b-4 border-blue-500 lg:px-3 font-semibold border-blue-500 hover:border-blue-500 text-yellow-400 cursor-pointer";
  var notactive =
    "h-full border-b-4 border-white px-3 font-semibold hover:border-blue-500 cursor-pointer";
  const [options, setOptions] = useState(["Study & Learn", "Play", "Others"]);
  const [colors, setColors] = useState([]);
  const [heading, setHeading] = useState();
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    basicSetup();
  }, []);

  const setCurrentColors = (type) => {
    colors[0] = notactive;
    colors[1] = notactive;
    colors[2] = notactive;
    colors[3] = notactive;
    colors[4] = notactive;
    if (type === "Study & Learn") {
      colors[0] = active;
    }
    else if (type === "play") {
      colors[1] = active;
    }
    else if (type === "others") {
      colors[2] = active;
    } else {
      colors[0] = active;
    }
    setColors(colors);
  };
  const basicSetup = () => {
    var colors = [active, notactive, notactive];
    setColors(colors);
    const path = location.pathname.split("/")[2];
    if (path === "Study & Learn") {
      setCurrent(1);
      setCurrentColors("Study & Learn");
    }
    if (path === "play") {
      setCurrent(2);
      setCurrentColors("play");
    }
    if (path === "others") {
      setCurrent(3);
      setCurrentColors("others");
    }};

  const optionChangeHandler = (params) => {
    console.log(params);
    if (params != current) {
      setCurrent(params);
      console.log(current);
    }
    let path = "/student/";
    switch (params) {
      case 0:
        path = path + "Study & Learn";
        RenderSwitch("Study & Learn");
        break;
      case 1:
        path = path + "play";
        RenderSwitch("play");
        break;

      default:
        path = path + "others";
        RenderSwitch("others");
        break;
    }
    navigate(path);
  };

  const RenderSwitch = (param) => {
    setHeading(id);
    switch (id) {
      case "Study & Learn":
        setCurrentColors("Study & Learn");
        setCurrent(1);
        return <Study />;
      case "play":
        setCurrentColors("play");
        setCurrent(2);
        return <Play />;
      case "others":
        setCurrentColors("others");
        setCurrent(3);
        return <Others />;
      default:
        setCurrentColors("Study & Learn");
        setCurrent(0);
        return <Study />;
    }
  };

  return (
    <div className="flex-col">
      <div className="flex xl:flex-row flex-col space-x-6 xl:mx-20 border-b-2 mb-6 mt-12">
        <div className="mb-4  xl:mt-0 mt-4 ml-6 xl:ml-2">
          <h1 className="text-xl h-full">{heading}</h1>
        </div>
        <div className="flex  lg:justify-between space-x-2 lg:space-x-4 lg:border-l-2 items-center lg:ml-2 lg:pl-8 border-blue-300">
          {options.map((option, index) => (
            <a
              onClick={() => optionChangeHandler(index)}
              className={colors[index]}
            >
              {option}
            </a>
          ))}
        </div>
      </div>
      <div className="px-4">
        <RenderSwitch data={options[current]} />
      </div>
    </div>
  );
}

export default Student;
