import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import MembersInNews from './components/faculty/FacultyInNews.js'
import { NavLink } from 'react-router-dom';
import { globleinfo } from '../../App';
import { getLanguageTemplate } from '../../utils/LanguageUtils';
import { useNavigate, useLocation } from 'react-router-dom';


function Nav() {
    const { languages,  tempLang, setTempLang } = useContext(globleinfo);
    const [show, setShow] = useState(false)

    const [tempData, setTempData]= useState({language:""})
    const fatchLangauge = ()=>{
         const cookies = document.cookie.split("; ")
         const cookiesMap = {};
         cookies.forEach(cookie=>{
          const[name, value]= cookie.split("=")
          cookiesMap[name]= value;
         })
         const myData = cookiesMap["langauge"];
         console.log(myData)
         tempData.language = myData
         setTempData(tempData)
      }
    const changebutton = (e) => {
        const name = e.target.name;
             if(name === "backgroundImage") {
            tempData[name] = e.target.files[0].path
            } else {
              tempData[name] = e.target.value;
              document.cookie = `langauge=${tempData.language}; cookiesAccepted=true; max-age=31536000;`;
            }
            setTempData({...tempData})
            console.log(tempData)
            setTempLang({...getLanguageTemplate(tempData.language)})
            console.log("+++++++++++++++++++")
            console.log(tempLang)
           
      }
      useEffect(()=>{
        fatchLangauge()
      },[])
    return (
        <div className="hidden lg:static lg:flex lg:flex-row lg:pr-2">
            <div className="w-full h-12  space-x-2 bg-great-blue-400  lg:flex lg:flex-row">
                <div className="relative py-2 group w-1/6">
                    <div className="flex  items-center px-4 py-2">
                        <span className="text-sm font-medium  uppercase text-blue-600 hover:text-yellow-400">{tempLang.nev.acedemic}</span>
                    </div>
                    <div className="absolute  top-12  animate-fade-in-downx   hidden w-full group-hover:block">
                        <li className="flex w-56 h-10 px-1 py-2 mt-2 text-white bg-blue-900 hover:bg-blue-800">
                            <div className="flex place-items-start ">
                                <Link to="/department">
                                    <span className="h-10 px-3 truncate">{tempLang.nev.acedemic_List.Classes} </span>
                                </Link>
                            </div>
                        </li>
                        <li className="flex items-center w-56 h-10 px-1 text-white bg-blue-900 hover:bg-blue-800">
                            <Link to="/calender">
                                <span className="h-8 px-3 truncate">{tempLang.nev.acedemic_List.acedemic_Calender} </span>
                            </Link>
                        </li>
                    </div>
                </div>
                
                <div className="relative py-2 group w-1/6">
                    <div className="flex items-center px-4 py-2">
                        <span className="text-sm font-medium text-white uppercase hover:text-yellow-400">{tempLang.nev.students}</span>
                    </div>
                    <div className="absolute top-12 animate-fade-in-downx hidden w-56 group-hover:block">
                        <li className="flex h-10 mt-2 bg-transparent dropdown">
                            <div className="flex  justify-between text-white px-3 items-center bg-blue-900 hover:bg-blue-800 w-56">
                                <span className=' text-white'>{tempLang.nev.campus_Life}</span>
                                <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <ul className=" animate-fade-in-down-slow absolute group-hover:block hidden  text-gray-700 ml-56 w-56">
                                <li>
                                    <Link to="/student/study">
                                        <a className=" bg-blue-900 hover:bg-blue-800 py-2 px-4 block whitespace-no-wrap text-white" href="#">{tempLang.nev. campus_List.study}</a>
                                    </Link>
                                </li>
                               
                                <li>
                                    <Link to="/student/play">
                                        <a className="bg-blue-900 hover:bg-blue-800 py-2 px-4 block whitespace-no-wrap text-white" href="#">{tempLang.nev. campus_List.play}</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/student/others">
                                        <a className="bg-blue-900 hover:bg-blue-800 py-2 px-4 block whitespace-no-wrap text-white" href="#">{tempLang.nev. campus_List.others}</a>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        

                       

                        <li className="flex items-center h-10 text-white bg-blue-900 hover:bg-blue-800">
                        <Link to="/support">
                            <span className="px-3 truncate">{tempLang.nev.supports}</span>
                            </Link>
                        </li>
                    </div>
                </div>

                <div className="relative  py-2 group w-1/6">
                    <div className="flex items-center px-4 py-2">
                        <span className="text-sm font-medium text-white uppercase hover:text-yellow-400">{tempLang.nev.faculty}</span>
                    </div>
                    <div className="absolute  top-12  animate-fade-in-downx   hidden w-full group-hover:block">
                        

                        <li className="flex items-center w-56 xl:mt-2 h-10 px-1 text-white bg-blue-900 hover:bg-blue-800">
                            <Link to={'/faculty/directory'}>
                                <span className="px-3">{tempLang.nev.faculty_List.directory}</span>
                            </Link>
                        </li>

                        <li className="flex items-center w-56 h-10 px-1 text-white bg-blue-900 hover:bg-blue-800 dropdown ">
                            <Link to={'/faculty/headofdept'}>
                                <span className="pl-3">{tempLang.nev.faculty_List.heads_of_Departments}</span>
                            </Link>
                        </li>
                    </div>
                </div>

                <div className="relative py-2 group w-1/6">
                    <div className="flex items-center px-4 py-1">
                    <NavLink to="/admission">
                        <span className="text-sm font-medium text-white hover:text-yellow-400 uppercase">{tempLang.nev.addmission}</span>
                        </NavLink>
                    </div>
                    
                </div>

                
                <div className="relative py-2 group w-1/6">
                    <div className="flex items-center px-4 py-2">
                        <span className="text-sm font-medium text-white hover:text-yellow-400 uppercase">{tempLang.nev.gallery} </span>
                    </div>
                    <ul className="absolute top-12 animate-fade-in-downx hidden w-full group-hover:block">
                        <li className="flex items-center w-56 h-10 px-1 mt-2 text-white  bg-blue-900 hover:bg-blue-800 dropdown">
                            <Link to="/gallery/collegeCampus">
                                <span className="px-3">{tempLang.nev.gallery_List.campus_Life}</span>
                            </Link>
                        </li>
                        <li className="flex items-center w-56 h-10 px-1 text-white  bg-blue-900 hover:bg-blue-800 dropdown ">
                            <Link to="/gallery/faculty">
                                <span className="pl-3">{tempLang.nev.gallery_List.faculty}</span>
                            </Link>
                        </li>
                        <li className="flex items-center w-56 h-10 px-1 text-white  bg-blue-900 hover:bg-blue-800">
                            <Link to="/gallery/events">
                                <span className="px-3 truncate">{tempLang.nev.gallery_List.events}</span>
                            </Link>
                        </li>
          
                    </ul>
                </div>
                
                <div className="relative py-2 group w-3/6">
                    <Link to="/contacts">
                        <div className="flex items-center px-4 py-2">
                            <span className="text-sm font-medium text-white truncate hover:text-yellow-400 uppercase">{tempLang.nev.contact_Us}</span>
                        </div>
                    </Link>
                </div>

                <div className="relative py-2 group w-3/6">
                    <Link  to="/about">
                        <div className="flex items-center px-4 py-2">
                            <span className="text-sm font-medium text-white truncate hover:text-yellow-400 uppercase">{tempLang.nev.about_Us}</span>
                        </div>
                    </Link>
                </div>
                 <div>
                 <div className="relative py-2 inline-flex">
                        <select value={tempData.language} onChange={(e) =>changebutton(e)} name="language" className="border-2 pt-0 pb-0 border-gray-300 rounded-sm text-gray-600 h-8 pl-2 pr-4 bg-white hover:border-gray-400 focus:outline-none ">
                        {languages.map(item => {
                    return (<option key={item.value} 
                    value={item.value}>{item.text}</option>);
                })}

                        </select>
                      </div>
                 </div>
                
            </div>
        </div>
    );
}

export default Nav;