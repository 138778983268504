import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import passwordHelper from "../../utils/Password";
import Model from "../admin/Model";
import { apiCall } from "../../ApiServices/apiCallManager";

const SignUpPage = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [description, setDescription] = useState("Description");
  const navigate = useNavigate();
  const initiatUserData = {
    email: "",
    firstname: "",
    lastname: "",
    gender: "male",
    password: "",
    confirmpassword: "",
  };
  const [userData, setUserData] = useState(initiatUserData);

  const handleChange = (e) => {
    userData[e.target.name] = e.target.value;
    setUserData({ ...userData });
  };
  const { firstname, email, lastname, dob, password, confirmpassword, gender } =
    userData;
  const handleSubmit =async  (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    e.preventDefault();
    if (!firstname || !email) {
      setShowModel(true);
      setDescription("User name or email missing.");
    } else if (!emailRegex.test(email)) {
      setShowModel(true);
      setDescription("Please enter a valid email id.");
    } else if (password.length === 0 || password !== confirmpassword) {
      setShowModel(true);
      setDescription("Password and confirm password must be same.");
    } else if (!passwordHelper.isStrongPassword(password)) {
      setShowModel(true);
      setDescription(
        "Please enter a strong password. Example : " +
          passwordHelper.generateStrongPassword()
      );
    } else {
      try{
        const obj = {
          firstname,
          lastname,
          email,
          password,
          gender,
        }
        const res = await apiCall("application/json","post","admin/user","register",null,null,obj)
        console.log(res)
        if(res.status === 201){
          setUserData(initiatUserData);
          navigate("/login");
        }
      }catch(e){
        if(e.message==="Request failed with status code 409"){
          alert("email alredy exists")
        }
      }
    }
  };

  const closeModel = () => {
    setShowModel(false);
  };

  return (
    <div>
      {showModel && (
        <Model
          title="Sign Up Error"
          description={description}
          closeAction={closeModel}
        />
      )}
      <div className="absolute z-30 left-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-blue-400 opacity-75"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="bg-grey-lighter min-h-screen m-4 mt-10 flex flex-col -top-4 fixed inset-0 z-40  overflow-y-scroll">
            <div class="container max-w-6xl mx-auto flex-1 flex flex-row items-center justify-center px-2">
              <div class="bg-white px-4 py-8 rounded text-black ">
                <div className="flex flex-row xl:justify-center xl:justify-center item-center justify-center -mt-4">
                  <div>
                    <h1 class="mb-4 text-4xl text-center -mt-2">Sign up</h1>
                  </div>
                </div>
                <div className="w-full -mt-2 text-gray-400 border-b"></div>
                <div className="flex xl:flex-row flex-col mt-2 xl:space-x-2">
                  <div className="xl:w-60 w-full">
                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 h-10 rounded mb-4 bg-gray-100"
                      name="firstname"
                      onChange={handleChange}
                      value={userData.firstname}
                      placeholder="First Name"
                    />
                  </div>

                  <div className=" xl:w-56 w-full">
                    <input
                      type="text"
                      class="block border border-grey-light w-full p-3 h-10 rounded mb-4 bg-gray-100"
                      name="lastname"
                      onChange={handleChange}
                      value={userData.lastname}
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div>
                  <input
                    type="text"
                    class="block border border-grey-light w-full p-3  h-10 rounded mb-4 bg-gray-100"
                    name="email"
                    onChange={handleChange}
                    value={userData.email}
                    placeholder="email address"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    class="block border border-grey-light w-full p-3 h-10 rounded mb-4 bg-gray-100"
                    name="password"
                    onChange={handleChange}
                    value={userData.password}
                    placeholder="Password"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    class="block border border-grey-light w-full p-3 h-10 rounded mb-4 bg-gray-100"
                    name="confirmpassword"
                    onChange={handleChange}
                    value={userData.confirmpassword}
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="flex flex-row mt-1 space-x-1">
                  <div>
                    <h2 className="text-sm text-gray-900">Gender</h2>
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="flex flex-row h-8 mt-1 text-gray-800 border w-36">
                    <div className="mt-1">
                      <label className="mt-2 ml-4 text-gray-800" for="male">
                        Female
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        className="ml-12"
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row h-8 mt-1 text-gray-800 border w-36">
                    <div className="mt-1">
                      <label className="mt-2 ml-4 text-gray-800" for="male">
                        Male
                      </label>
                    </div>
                    <div className="mt-1">
                      {" "}
                      <input
                        className="ml-16"
                        type="radio"
                        id="female"
                        name="gender"
                        value="male"
                        defaultChecked
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="w-full text-center py-1 rounded h-10 bg-green-400 text-white hover:bg-green-dark focus:outline-none my-1"
                >
                  Sign In
                </button>
                <div class="text-grey-dark mt-2">
                  Already have an account?
                  <a
                    class="no-underline border-b border-blue text-blue"
                    href="../login/"
                  >
                    <Link to="/login">Log in</Link>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignUpPage;
