import React, { useEffect, useState } from "react";
import constant from "../../Constant/constant";
import AddmissionPopup from "./AddmissionPopup";
import axios from "axios";
import Cookies from "universal-cookie";
import { apiCall } from "../../ApiServices/apiCallManager";
const cookies = new Cookies();
const cookieToken = cookies.get("TOKEN");

function Addmission({ admissions, setAdmissions }) {
  const tempObj = {
    classes: "",
    description: "",
    image: "",
  };
  const [addmission, setAddmission] = useState(tempObj);
  const [buttonText, setButtonText] = useState("Save");
  const [data, setData] = useState([])
  // const[imagePath, setImagePath] = useState();
  const [show, setShow] = useState(false)
  const fatchData = async () => {
    const res = await apiCall("application/json","get","admission","get/all");
    if (res.status === 200) {
      setData([...res.data]);
    }
    else {
      return alert(res.data)
    }
  };
  useEffect(() => {
    fatchData();
  }, [show]);

  const handleDelete = async (_id) => {
    const res = await apiCall(null,"delete","admission","delete",_id,cookieToken)
    if (res.status === 200) {
      setData(data.filter((data) => data._id !== _id))
    }
    else {
      return alert(res.data)
    }
  }

  const handleImageChange = (event) => {
    if (event.target.name === "image") {
      addmission[event.target.name] = event.target.files[0];
      console.log(event.target.files[0]);
    } else {
      addmission[event.target.name] = event.target.value;
    }
    setAddmission({ ...addmission });
    console.log(addmission);
  };

  const openPopup = () => {
    setShow(!show);
  };

  const onHandlerSubmit = async (e) => {
    e.preventDefault();
    console.log("handle click button is Active");
    setAddmission({ ...addmission });
    console.log(addmission);
    const formData = new FormData();
    formData.append("file", addmission.image);
    formData.append("addmission", JSON.stringify(addmission));
    if (buttonText === "Save") {
      const res = await apiCall('multipart/form-data',"post","admission","upload",null,cookieToken,formData);
      if (res.status === 200) {
        setAddmission(addmission)
        setShow(!show);
      }
      else {
        alert(res.data)
      }
    }
    setAddmission({ ...addmission })
    console.log("click handle");
  }
  console.log(data)
  return (
    <div className="   bg-white w-full ">
      <div class=" shadow-md w-full sm:rounded-lg">
        <div className="flex xl:flex-row bg-white flex-col  p-4 space-x-3  border-b-2 border-gray-500  rounded-r-md">
          <div className=" flex flex-row  xl:w-auto w-full items-start justify-start">
            <div className=" xl:mx-4 ml-6">
              <button
                onClick={() => openPopup()}
                className=" px-3 py-2 bg-green-600 text-black border rounded-md"
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="h-screen overflow-y-scroll">
          <table class="w-full mx-auto overflow-x-scroll text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.n
                </th>
                <th scope="col" class="px-6 py-3">
                  Class
                </th>
                <th scope="col" class="px-6 py-3">
                  Description
                </th>
                <th scope="col" class="px-6 py-3">
                  PDF details
                </th>
                <th scope="col" class="px-6 py-3">
                  <span class="">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td class="px-6 py-4">
                    <div>{item.classes}</div>
                  </td>
                  <td class="px-6 py-4">
                    <div>{item.description}</div>
                  </td>
                  <td class="px-6 py-4">
                    <div>
                      {item.content.toLowerCase().endsWith(".pdf") ? (
                        <div>
                          <iframe
                            className=" w-10 h-24"
                            title="PDF Viewer"
                            src={`${constant.BACKEND_URL}/api/upload/${item.content}`}
                            width="100%"
                            height="500px"
                            style={{ border: "none" }}
                          ></iframe>
                        </div>
                      ) : (
                        <div>
                          <a
                            href={`${constant.BACKEND_URL}/api/upload/${item.content}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              alt="item"
                              className=" w-10 h-10"
                              src={`${constant.BACKEND_URL}/api/upload/${item.content}`}
                            />
                          </a>

                          {item.filename}
                        </div>
                      )}
                    </div>
                  </td>
                  <td class="px-6 space-x-6 py-4 ">
                    {/* <a
                    onClick={() => openPopup()}
                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Add
                  </a> */}
                    <button
                      onClick={() => handleDelete(item._id)}
                      class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {show && (
        <AddmissionPopup
          openPopup={openPopup}
          addmission={addmission}
          handleImageChange={handleImageChange}
          onHandlerSubmit={onHandlerSubmit}
        />
      )}
    </div>
  );
}

export default Addmission;
