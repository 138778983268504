import React, { useState } from "react";
import constant from "../../Constant/constant";
import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();
const token = cookies.get("TOKEN");
const ImagePopup = ({ gallery, setGallery, onClose }) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = ["Campus", "Faculty", "Events"];

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const isSubmitButtonEnabled = () => {
    if (name !== "" && (type !== "") & (selectedImages.length !== 0))
      return true;
    return false;
  };

  const handleImageChange = (e) => {
    const fileList = e.target.files;
    setImages(e.target.files);
    const newImages = [];
    for (let i = 0; i < fileList.length; i++) {
      const imageURL = URL.createObjectURL(fileList[i]);
      newImages.push(imageURL);
    }
    setSelectedImages(newImages);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        formData.append("fileInput", images[i]);
      }
      formData.append("type", type);
      formData.append("name", name);
      try {
        const response = await axios.post(
          `${constant.BACKEND_URL}/api/v1/gallery/create-new`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const temp = response.data;
        setGallery([...gallery, temp]);
        setSelectedImages([]);
      } catch (error) {
        return;
      }
      setLoading(false);
      onClose(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50 ">
     <div className=" ">
     <div className="bg-white p-6 rounded-lg shadow-lg px-2 lg:px-6">
        <h2 className="text-xl font-semibold mb-4">Add New Gallery</h2>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={handleNameChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="flex items-center space-x-4">
          <label
            htmlFor="type"
            className="block text-xl font-medium text-gray-700"
          >
            {" "}
            Select Gallery Type{" "}
          </label>
          <select
            class="rounded-lg border-2 px-2 py-2"
            id="select"
            name="gType"
            onChange={handleTypeChange}
            value={type}
          >
            {options.map((val, index) => (
              <option value={val}>{val} Gallery</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="images"
            className="block text-sm font-medium text-gray-700"
          >
            Images
          </label>
          <input
            type="file"
            id="images"
            multiple
            onChange={handleImageChange}
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="flex space-x-2 w-96 overflow-scroll">
          {selectedImages.map((element, index) => (
            <img
              key={index}
              src={element}
              alt={"alternate"}
              className="w-20 h-20"
            />
          ))}
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <span className="loading loading-infinity loading-lg bg-primary"></span>
          </div>
        ) : (
          <div className="flex justify-between mt-6">
            <button
              onClick={() => onClose(false)}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            >
              Cancel
            </button>
            {isSubmitButtonEnabled() && (
              <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 items-center"
                disabled={loading}
              >
                Submit
              </button>
            )}
          </div>
        )}
      </div>
     </div>
    </div>
  );
};

export default ImagePopup;
