import constant from "../../Constant/constant";

const learn = `${constant.BACKEND_URL}/api/upload/learn.jpeg`;
const learn1 = `${constant.BACKEND_URL}/api/upload/learn1.jpeg`;
const learn2 = `${constant.BACKEND_URL}/api/upload/learn2.jpeg`;
const learn3 = `${constant.BACKEND_URL}/api/upload/learn3.jpeg`;

function Learn(params) {
  return (
    <div>
      <div className="lg:flex">
        <div className="hidden lg:block lg:h-44 lg:w-40 xl:mx-20 mb-8 lg:border-b-2  lg:border-black lg:bg-gray-100">
          <h className="font-bold p-4">Auditoriums</h>
          <p className="mt-4 p-4">A Platform for Interface</p>
          <button className="float-right mt-8">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              ></path>
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:m-4 lg:border-b-2 lg:space-x-6 lg:border-black lg:h-40">
          <div>
            <h1 className="font-semibold text-black p-4">Laboratories</h1>
            <p className="ml-3 text-sm">
              {" "}
              The Breeding Ground for Path-Breaking Ideas
            </p>
          </div>
          <div>
            <li className="hover:text-indigo-500">Biotechnology</li>
            <li className="hover:text-indigo-500">Engineering</li>
            <li className="hover:text-indigo-500">Aerospace Engineering</li>
            <li className="hover:text-indigo-500">Nanotechnology</li>
          </div>
          <div>
            <li className="hover:text-indigo-500">Forensic Science</li>
            <li className="hover:text-indigo-500">Communication</li>
            <li className="hover:text-indigo-500">Fashion Technology</li>
          </div>
          <div>
            <li className="hover:text-indigo-500">Fine Arts</li>
            <li className="hover:text-indigo-500">Architecture</li>
            <li className="hover:text-indigo-500">Hotel Managementy</li>
          </div>
        </div>
      </div>
      <div>
        <div className=" mx-2 mt-10 lg:mx-24">
          <h1 className="font-bold text-3xl mx-4 animate-fade-in-downr">
            Accommodation
          </h1>
          <p className="mt-2 my-10 mx-4 animate-fade-in-downx">
            Home Away from Home for 15,000 Students
          </p>
          <div className="mx-4 my-10">
            <p2>
              Amity offers comprehensive hostel facilities for boys and girls
              separately within the University Campus.
            </p2>
          </div>
          <div className="mx-4">
            <p3>
              Caring wardens and a vigilant security ensures a pleasant stay
              allowing students to focus on academics. The air-conditioned
              residential apartment suites consist of 4 single rooms with an
              attached bathroom and sitting lounge equipped with sofa, cable TV
              and r efrigerator. Normal Non-A/C hostel rooms are also available.
            </p3>
            <img
              className="my-10 lg:w-full animate-fade-in-down"
              src={learn}
            ></img>
            <h className="font-bold text-indigo-600 text-2xl ml-4">
              On-Campus Hostel
            </h>
          </div>
          <div className="lg:flex">
            <p className="mx-4 my-10 animate-fade-in-left-slow">
              The life in hostels enables students to spend ample time in the
              institutes, utilise library and other facilities to ensure they
              develop academically and acquire the necessary skills that can be
              obtained only through experience
            </p>
            <img
              className=" lg:w-full lg:h-60 lg:mx-4 animate-fade-in-left-slower"
              src={learn1}
              alt="collegphoto"
            ></img>
            <img
              className=" lg:w-full lg:h-60 lg:mx-4 animate-fade-in-left-slowest"
              src={learn2}
              alt="collegphoto"
            ></img>
          </div>
          <div className="my-8">
            <p1>
              In addition, the hostel life allows students to interact with
              their colleagues and peers, make friends, and develop into good
              human beings capable of independent judgement and competent in
              handling the day-today pressures of life. Some students may leave
              their homes for the first time but they can be reassured, these
              hostels would eventually turn out to be their home away from home
            </p1>
          </div>
          <div className="bg-yellow-900 h-auto py-4 w-full lg:flex">
            <img
              className="h-56 lg:w-96 lg:mx-14 mx-auto lg:h-80 animate-fade-in-downx"
              src={learn3}
              alt="lastphoto"
            ></img>
            <p className="lg:mx-14 mx-4 mt-10 text-white lg:mt-44 lg:text-xl text-center animate-bounce-slow">
              I was staying in a hostel for the first time, but the caring
              wardens, homely food and the friendly atmosphere made me feel so
              much at home.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Learn;
