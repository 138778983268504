import { useEffect, useState } from "react";
import { apiCall } from "../../ApiServices/apiCallManager";

function CourseTime() {
 const [data, setData] = useState([])
    const fatchData = async()=>{

        apiCall(null,"get","admission","get/all").then((res) => {
          console.log(res.data)
          setData([...res.data]); 
        });
      };
      useEffect(() => {
        fatchData();
      }, []);
      const imageDownload = async(item)=>{
        console.log(item)
        apiCall(null,"get","admission","download",item.classes,null,null).then((res) => {
          // if (res.ok) {
            console.log("+++++++++++++++++++++")
            // Create a temporary link element
            const link = document.createElement('a');
            console.log(link)
            console.log(res)
            link.href = res.config.url;
            // link.download =`${item.filename}`;
            link.download = res.data;
    
            // Simulate a click on the link to start the download
            link.click();
          // } else {
          //   console.error('Failed to download image');
          // }
        });
      }
    return(
        <div>
           <div className=' flex flex-col mt-12 xl:mt-1 items-center justify-items-center p-4'>
            <span className=' xl:text-6xl text-4xl text-center text-gray-900 font-bold'>
                Addmission
            </span>
            <p className=' max-w-3xl text-lg mt-4 mb-8 text-justify'>
            For admission, individuals typically need to download and complete an application form,
             a crucial step in the enrollment process. The form usually includes personal information,
              academic history, and other relevant details required by the educational institution.
               To initiate the admission process, prospective students can access the form from the
                institution's official website or designated online portal. After filling out the form,
                 it is often submitted along with the necessary supporting documents, such as transcripts
                  and letters of recommendation, as specified in the admission guidelines. Downloading and
                   completing the admission form is an essential prerequisite for aspiring students aiming
                    to pursue educational opportunities at the institution.</p>

        </div>
        

               <div class="relative overflow-x-auto w-full mx-auto ">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 flex flex-row whitespace-nowrap">
                   S.N
                </th>
                <th scope="col" class="px-6 py-3">
                  Class Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Pdf Details
                </th>
                <th scope="col" class="px-6 py-3">
                    Actions
                </th>
                
            </tr>
        </thead>
        <tbody>
        {data.map((item, index)=>(
            <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   {index+1}
                </th>
                <td class="px-6 py-4">
                {item.classes}
                </td>
                <td class="px-6 py-4">
                {item.description}
                </td>
                <td class="px-6 py-4">
                <button className=" bg-green-600 px-3 py-2 rounded-md text-white font-medium" onClick={()=>imageDownload(item)}>DownLoad</button>
                </td>
               
            </tr>
          ))}
           
        </tbody>
    </table>
</div>
                  
            
        </div>
    )
}

export default CourseTime