import React, { useState } from "react";
// import TeacherTable from './TeacherTable';
import User from "./Member";
import constant from "../../Constant/constant";
function App(params) {
  return (
    <div class="flex flex-col w-full h-screen  ">
      <table class="min-w-full divide-y divide-gray-200 w-full overflow-y-scroll overflow-x-scroll">
        <thead class="bg-gray-500 text-white w-full">
          <tr>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              <div className="flex flex-row items-center">
                <p>No</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              <div className="flex flex-row items-center justify-center">
                <p>Name</p>
              </div>
            </th>

            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              <div className="flex flex-row items-center justify-center">
                <p>Email</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider truncate"
            >
              <div className="flex flex-row items-center justify-center">
                <p>Mobile NO</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              <div className="flex flex-row items-center justify-center">
                <p>Qualification</p>
              </div>
            </th>
            <th
              scope="col"
              class="  px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              Subject
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              <div className="flex flex-row items-center justify-center">
                <p>DOB</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider truncate"
            >
              <div className="flex flex-row items-center justify-center">
                <p>join date</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider truncate"
            >
              <div className="flex flex-row items-center justify-center">
                <p>Address</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-2 py-3 text-center text-xs font-medium text-white uppercase tracking-wider truncate"
            >
              <div className="flex flex-row items-center justify-center">
                <p>Image</p>
              </div>
            </th>
            <th
              scope="col"
              class="px-7 py-3 text-center text-xs font-medium text-white uppercase tracking-wider"
            >
              <p>Action</p>
            </th>
          </tr>
        </thead>
        <tbody class="bg-gray-100 divide-y  divide-gray-200">
          {params.teacher.map((item, index) => (
            <tr>
              <td class="px-2 py-2 whitespace-nowrap text-center">
                {index + 1}
              </td>
              <td class="px-2 py-2 whitespace-nowrap text-center">
                {item.name}
              </td>
              {/* <td class="px-5 py-1 whitespace-nowrap">
                     <img class="h-10 w-10 rounded-full" src={item.src}/>
                  </td> */}
              <td>
                <div class="text-sm text-gray-900 text-center">
                  {item.email}
                </div>
              </td>

              <td>
                <div className=" px-4 text-center">{item.phone}</div>
              </td>

              <td>
                <div className=" px-4 text-center">{item.qualification}</div>
              </td>

              <td>
                <div className=" px-4 text-center ">Hindi</div>
              </td>
              <td>
                <div className="px-4 text-center">{item.dob}</div>
              </td>
              <td>
                <div className=" px-4 text-center">{item.doj}</div>
              </td>
              <td>
                <div className=" px-4 truncate  text-center w-20">
                  {item.address}
                </div>
              </td>
              <td>
                <div className=" px-4 truncate  text-center w-20">
                  <img
                    className=" w-12 h-12"
                    alt="teacher"
                    src={`${constant.BACKEND_URL}/api/upload/${item.teacherImage}`}
                  ></img>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="flex flex-row justify-between">
                  <button onClick={() => params.handlerUpdated(index)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-green-600 bg-gray-100 border-2 border-white rounded-md hover:text-indigo-900"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </button>
                  <button onClick={() => params.handlerDelete(item._id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 bg-gray-100 border-2 border-white rounded-md text-red-400 hover:text-red-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
          {params.teacher.length === 0 && (
            <span className="w-full">No records found to display!</span>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default App;
