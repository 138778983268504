import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "./logo.jpg";
function Sidebar(props) {
  const [show, setShow] = useState([]);
  const [val, setVal] = useState([]);

  const [subGroup, setSubGRoup] = useState({
    course: false,
    klm: false,
  });

  useEffect(() => {
    // console.log("USE lEFFECT" + props.data);
    if (props.data === undefined) {
      let arrayData = [
        "Academics",
        "Research",
        "Students",
        "Faculty",
        "Admission",
        "Facilities",
        "Gallery",
        "Notice",
        "About Us",
        "Contact Us",
      ];
      basicSetupNew(arrayData);
      console.log("USE --EFFECT--");
    }
  }, []);

  const basicSetupNew = (data) => {
    const size = data.length;
    var tempVisible = [];
    for (var i = 0; i < size; i++) {
      tempVisible.push(false);
    }
    setVal(tempVisible);
    console.log(tempVisible);
  };

  const setShowVar = (index) => {
    var temp = [];
    val.map((v, i) => {
      if (index === i && v === false) {
        temp.push(true);
      } else {
        temp.push(false);
      }
    });
    setVal(temp);
    setShow(!show);
  };

  const setSubSideCourse = (index) => {
    console.log("Sub Side Bar");
    if (index === 1) {
      setShow(!show);
    }
  };

  const setSubSideStudent = (index) => {
    console.log("Sub Side Bar");
    if (index === 1) {
      setShow(!show);
    }
  };

  const setSubSideAdmission = (index) => {
    console.log("Sub Side Bar");
    if (index === 1) {
      setShow(!show);
    }
  };

  const HandelItemClick = (item) => {
    console.log("item " + item);
    console.log(props);

    props.handleClick(item);
    // props.handleSideBaarItemClickHandler(item)
  };

  return (
    <div>
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>
      <div className="fixed right-80 top-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800">
        <div className=" flex flex-row items-center justify-between">
          <div>
            <Link to="/">
              <img
                className="w-10 h-10 rounded-full mt-1.5"
                src={logo}
                alt="home"
              />
            </Link>
          </div>
          <button
            onClick={() => props.HideMenu()}
            type="button"
            data-drawer-hide="drawer-disable-body-scrolling"
            aria-controls="drawer-disable-body-scrolling"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              class="w-8 h-8 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close menu</span>
          </button>
        </div>
        <div className="flex flex-col mt-2 space-y-1">
          <div
            onClick={() => setShowVar(0)}
            className="flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
              </svg>
              <div className=" text-lg">Academics</div>
            </div>
            <svg
              className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          {val[0] && (
            <div className="flex flex-col ml-16 space-y-2 text-lg font-normal ">
              <p
                onClick={() => HandelItemClick("department")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Classes{" "}
              </p>
              {/* <p onClick={() => HandelItemClick("programs")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Programs </p> */}
              {/* <p className="flex justify-between  cursor-pointer items-center p-2  text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700" onClick={() => setSubSideCourse(1)}  >
                                Courses Offered
                                <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </p>
                            {
                                // subGroup.course && 
                                show &&
                                <div className="flex flex-col ml-10  font-bold ">
                                    <p className=" flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Under Graduate </p>
                                    <p className=" flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Post Graduate </p>
                                    <p className=" flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Certificate courses for U.G/P.G </p>
                                </div>
                            } */}
              <p
                onClick={() => HandelItemClick("calender")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Academic Calendar{" "}
              </p>
            </div>
          )}

          {/* <div onClick={() => setShowVar(1)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <div className="flex flex-row w-full space-x-4">
                            <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z" clip-rule="evenodd" />
                            </svg>
                            <p className="text-lg">Research</p>
                        </div>
                        <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    {
                        val[1] &&
                        <div className="flex flex-col ml-16 space-y-2 font-normal text-lg ">
                            <p onClick={() => HandelItemClick("research/overview")} className="flex cursor-pointer items-center p-2  font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Research</p>
                            <p onClick={() => HandelItemClick("research/publications")} className="flex cursor-pointer items-center p-2  font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">College Publications </p>
                            <p onClick={() => HandelItemClick("research/guide")} className="flex cursor-pointer items-center p-2  font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Recognized Research Guides </p>
                            <p onClick={() => HandelItemClick("research/committee")} className="flex cursor-pointer items-center p-2  font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Research Committee</p>
                        </div>
                    } */}

          <div
            onClick={() => setShowVar(2)}
            className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <p className="text-lg">Students</p>
            </div>
            <svg
              className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          {val[2] && (
            <div className="flex flex-col  space-y-2 text-lg p-2 font-normal  ">
              <div className={show ? " bg-white pl-8 " : " bg-white  pl-8"}>
                <p
                  className="flex flex-row justify-between  rounded-l-sm "
                  onClick={() => setSubSideStudent(1)}
                >
                  Campus Life
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </p>
                {
                  // subGroup.course &&
                  show && (
                    <div className="flex flex-col ml-10 space-y-2 font-normal text-lg ">
                      <p
                        onClick={() => HandelItemClick("student/study")}
                        className="flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Study{" "}
                      </p>
                      {/* <p onClick={() => HandelItemClick("student/learn")} className="flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Learn </p> */}
                      <p
                        onClick={() => HandelItemClick("student/play")}
                        className="flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Play{" "}
                      </p>
                      {/* <p onClick={() => HandelItemClick("student/hostel")} className="flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">Hostel </p> */}
                      <p
                        onClick={() => HandelItemClick("student/others")}
                        className="flex cursor-pointer items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        Others{" "}
                      </p>
                    </div>
                  )
                }
              </div>
              {/* <p onClick={() => HandelItemClick("Placement")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Placements </p> */}
              <p
                onClick={() => HandelItemClick("support")}
                className="pl-8 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Support Centre
              </p>
            </div>
          )}

          <div
            onClick={() => setShowVar(3)}
            className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
              <p className="text-lg">Faculty</p>
            </div>
            <svg
              className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          {val[3] && (
            <div className="flex flex-col ml-16 space-y-2 text-lg font-normal ">
              {/* <p onClick={() => HandelItemClick("faculty/news")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Faculty Members in News</p> */}
              <p
                onClick={() => HandelItemClick("faculty/directory")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Directory
              </p>
              <p
                onClick={() => HandelItemClick("faculty/headofdept")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Heads of Departments
              </p>
            </div>
          )}

          <div
            onClick={() => HandelItemClick("admission")}
            className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path
                  fill-rule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
              <p className="text-lg">Admission</p>
            </div>
            {/* <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg> */}
          </div>
          {/* {
                        val[4] &&
                        <div className="flex flex-col ml-16 space-y-2 font-normal text-lg">
                            <p onClick={() => HandelItemClick("admission")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Procedure for Online Admission </p>
                            <p onClick={() => HandelItemClick("eligibility")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Eligibility Criteria</p>
                            <p className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Course Details </p>
                            <p onClick={() => HandelItemClick("uGadmission")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Under Graduate</p>
                            <p onClick={() => HandelItemClick("pGadmission")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Post Graduate</p>
                          
                        </div>
                    } */}

          {/* <div  onClick={() => setShowVar(5)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <div className="flex flex-row w-full space-x-4">
                            <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
                                <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
                            </svg>
                            <p className="text-lg">Facilities</p>
                        </div>
                        <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    {
                        val[5] &&
                        <div className="flex flex-col ml-16 space-y-2 font-normal text-lg ">
                            <p onClick={() => HandelItemClick("facility/antiragging")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Anti- Ragging Measures  </p>
                            
                            <p onClick={() => HandelItemClick("facility/library")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">General Library </p>
                            <p onClick={() => HandelItemClick("facility/councelling")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Career Counselling Cell </p>
                        </div>
                    } */}

          <div
            onClick={() => setShowVar(6)}
            className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  clip-rule="evenodd"
                />
              </svg>
              <p className="text-lg">Gallery</p>
            </div>
            <svg
              className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          {val[6] && (
            <div className="flex flex-col ml-16 space-y-2 font-normal text-lg ">
              <p
                onClick={() => HandelItemClick("gallery/collegeCampus")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Campus Life
              </p>
              <p
                onClick={() => HandelItemClick("gallery/event")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Events
              </p>
              <p
                onClick={() => HandelItemClick("gallery/faculty")}
                className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white"
              >
                Faculty
              </p>
              {/* <p onClick={() => HandelItemClick("gallery/alumni")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Alumni</p> */}
            </div>
          )}

          {/* <div onClick={() => setShowVar(7)} className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <div className="flex flex-row w-full space-x-4">
                            <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd" />
                            </svg>
                            <p className="text-lg">Notice</p>
                        </div>
                        <svg className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    {
                        val[7] &&
                        <div className="flex flex-col ml-16 space-y-2 font-normal text-lg">
                            <p onClick={() => HandelItemClick("notice/newsandnotice")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">News and Notices </p>
                            <p onClick={() => HandelItemClick("notice/events")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Events</p>
                            <p onClick={() => HandelItemClick("notice/seminar")} className="pl-3 rounded-l-full hover:bg-gray-700 hover:text-white">Seminars</p>
                        </div>
                    } */}

          <div className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M14.414 7l3.293-3.293a1 1 0 00-1.414-1.414L13 5.586V4a1 1 0 10-2 0v4.003a.996.996 0 00.617.921A.997.997 0 0012 9h4a1 1 0 100-2h-1.586z" />
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <p
                onClick={() => HandelItemClick("contacts")}
                className="text-lg"
              >
                Contact Us
              </p>
            </div>
          </div>

          <div className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
            <div className="flex flex-row w-full space-x-4">
              <svg
                className="w-7 h-7 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white py-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
              <p onClick={() => HandelItemClick("about")} className="text-lg">
                About Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
