import { useContext, useEffect, useState } from "react";
import { globleinfo } from "../../App";
import images from "../Img";

function BasisSchool() {
  const { tempLang } = useContext(globleinfo);
  const description = [
    "This school prioritizes an inclusive, innovative approach to education, fostering a supportive environment for diverse student growth. I'm privileged to contribute to a community dedicated to nurturing both academic excellence and individual development.",
    "This school's commitment to personalized learning and its vibrant community creates a dynamic space where each student's potential is nurtured, fostering a love for continuous discovery and achievement.",
    "At our school, the emphasis on student-centered education and a culture of continuous improvement creates an environment where both students and educators are motivated to grow, learn, and innovate together, shaping a path for success and lifelong learning",
    "I'm privileged to be part of a school that values not just academic excellence but also promotes a culture of empathy, collaboration, and individualized support, ensuring each student's success and well-being.",
  ];
  const [selectedText, setSelectedText] = useState(0);

  const handleImageClick = (textId) => {
    setSelectedText(description[textId]);
  };
  useEffect(() => {
    setSelectedText(description[0]);
  }, []);

  // <div className="absolute w-full h-24 px-1 py-8 bottom top-2">
  // </div>
  return (
    <div>
      <div className=" w-full mt-12 xl:mt-32 relative ">
        {/* <p className="flex items-center justify-center   h-12 w-full opacity-60 text-2xl font-serif font-bold text-blue-5000 lg:text-4xl">{tempLang.home_Header.teacher_of_School.teacher_of_School}</p> */}

        <div className="flex flex-col lg:flex lg:flex-row lg:justify-around  w-full mt-6 lg:mt-12">
          <div className="absolute ml-12 mt-72 lg:-mt-56 lg:-ml-56 transform hover:scale-105 duration-1000">
            <div onClick={() => handleImageClick(0)} className="relative mt-64">
              <img
                className="h-64 border-4 border-white rounded-lg object-cover lg:w-48 lg:h-56 w-72"
                src={images.teacher1}
              ></img>

              <div className="absolute py-8 mt-1 -ml-9 lg:py-6 lg:mt-1 lg:-ml-1 bottom top-32">
                <svg
                  className="h-44 lg:w-48 lg:h-32 w-80"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 850 620"
                >
                  <path
                    fill="#013220"
                    fillopacity="1"
                    d="M0,32L60,26.7C120,21,240,11,360,26.7C480,43,600,85,720,101.3C840,117,960,107,1080,96C1200,85,1320,75,1380,69.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                  ></path>
                </svg>
                <div className="absolute px-8 mt-2 ml-12 text-xl text-white lg:ml-3 bottom lg:top-12 top-16 lg:text-base whitespace-nowrap">
                  {" "}
                  <p>Dr XYZ Singh</p>
                </div>
              </div>
            </div>
          </div>
          <div className="-mt-2 lg:absolute ml-12 lg:-ml-96 lg:mt-2 transform hover:scale-105 duration-1000">
            <div
              onClick={() => handleImageClick(1)}
              className="relative  lg:-ml-60"
            >
              <img
                className="h-64 border-4 border-white object-cover rounded-lg lg:w-48 lg:h-56 w-72"
                src={images.principal1}
              ></img>

              <div className="absolute py-8 mt-1 -ml-9 lg:py-6 lg:mt-1 lg:-ml-1 bottom top-32">
                <svg
                  className="h-44 lg:w-48 lg:h-32 w-80"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 850 620"
                >
                  <path
                    fill="rgb(255, 121, 71)"
                    fillopacity="1"
                    d="M0,32L60,26.7C120,21,240,11,360,26.7C480,43,600,85,720,101.3C840,117,960,107,1080,96C1200,85,1320,75,1380,69.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                  ></path>
                </svg>
                <div className="absolute px-8 mt-2 ml-12 text-xl text-white lg:ml-3 bottom lg:top-12 top-16 lg:text-base whitespace-nowrap">
                  {" "}
                  <p>XYZ kumar</p>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute mt-64 ml-12 lg:ml-10 lg:mt-2 ">
            <div
              onClick={() => handleImageClick(2)}
              className="absolute rounded-lg"
            >
              <div className="relative h-2 mt-3 lg:w-48 w-72 lg:-mt-3 transform hover:scale-105 duration-1000">
                <img
                  className="border-4 border-white rounded-lg object-cover h-60 w-80 lg:w-48 lg:h-56"
                  src={images.maddem1}
                ></img>

                <div className="absolute py-8 mt-1 -ml-9 lg:py-6 lg:mt-1 lg:-ml-1 bottom top-32">
                  <svg
                    className="h-44 lg:w-48 lg:h-32 w-80"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 850 620"
                  >
                    <path
                      fill="#87ceeb"
                      fillopacity="1"
                      d="M0,32L60,26.7C120,21,240,11,360,26.7C480,43,600,85,720,101.3C840,117,960,107,1080,96C1200,85,1320,75,1380,69.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                    ></path>
                  </svg>
                  <div className="absolute px-8 mt-2 ml-12 text-xl text-white lg:ml-3 bottom lg:top-12 top-16 lg:text-base whitespace-nowrap">
                    {" "}
                    <p>ABC Kumari</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:px-60 ml-4">
            <div
              onClick={() => handleImageClick(3)}
              className="px-8 py-24  mt-24 lg:px-3 lg:py-11 lg:absolute lg:-mt-96 lg:ml-60"
            >
              <div className="relative h-2 lg:w-48 mt-96 w-72 transform hover:scale-105 duration-1000">
                <img
                  className="h-64 border-4 border-white rounded-lg lg:w-48 lg:h-56 w-72 object-cover"
                  src={images.maddem2}
                ></img>

                <div className="absolute py-8 mt-1 -ml-9 lg:py-6 lg:mt-1 lg:-ml-1 bottom top-32">
                  <svg
                    className="h-44 lg:w-48 lg:h-32 w-80"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 850 620"
                  >
                    <path
                      fill="purple"
                      fillopacity="1"
                      d="M0,32L60,26.7C120,21,240,11,360,26.7C480,43,600,85,720,101.3C840,117,960,107,1080,96C1200,85,1320,75,1380,69.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                    ></path>
                  </svg>
                  <div className="absolute px-8 mt-2 ml-12 text-xl text-white lg:ml-3 bottom lg:top-12 top-16 lg:text-base whitespace-nowrap">
                    {" "}
                    <p>XYZ Devi</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedText && (
          <div className=" flex item-center  justify-center absolute   inset-x-0 bottom-6 ">
            <div className="hidden xl:block  w-6/12 text-xl font-sans  text-white  text-center">
              {selectedText}
            </div>
          </div>
        )}

        <div className=" xl:block hidden shadow-sm border-b-2 border-blue-900 ">
          <svg
            className="w-full  lg:w-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1078 320"
            height="100%"
          >
            <path
              fill="#014077"
              fillopacity="1"
              d="M0,128L80,133.3C160,139,320,149,480,133.3C640,117,800,75,960,64C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className=" block xl:hidden ">
          <svg
            className="w-full lg:w-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1078 320"
            height="250"
          >
            <path
              fill="#014077"
              fillopacity="1"
              d="M0,128L80,133.3C160,139,320,149,480,133.3C640,117,800,75,960,64C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default BasisSchool;
