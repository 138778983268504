import React, { useState, useEffect } from 'react';
import ProgrammsDetails from './ProgrammsDetails';

const Programmes = () => {

    let [itemList, setItemList] = useState([])

    let programsMap = {
        graduate: ["ug1", "ug2", "ug3", "ug4", "ug5"],
        postGraduate: ["pg1", "pg2", "pg3", "pg4", "pg5"],
    }
    let courseMap = {
        ug1: ["Gratuate In Computer Science", "Honours Of Mathematics", "Honours Of Physics"],
        ug2: ["Computer Science & Engineering", "Electrical Engineering", "Civil Engineering"],
        ug3: ["Food Technology", "Bio Technology", "Production Engineering"],
        ug4: ["Electronics Engineering", "Computer Application", "Bachlore of Computer"],
        ug5: ["Bachlore of Science", "Bachlore of Comarce", "Ceramic Engineering"],
        pg1: ["Master of Science Department", "Master In Computer Application", "Master In Electrical Engineering"],
        pg2: ["Master In Mechnical Engineering", "Master In Civil Engineering", "Master In BioTechnology"],
        pg3: ["Master In Food Technology", "Master In Ceramic Technology", "Master In Bio Science"],
        pg4: ["Master In Science", "Master In Architechnology", "Master In Geography"],
        pg5: ["Master In Biological Science", "Master In Economics", "Master In Zoological Science"]
    }

    let courseDetailsMap = {
        'Gratuate In Computer Science': "Gratuate In Computer Science Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Honours Of Mathematics': "Honours Of Mathematics Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Honours Of Physics': "Honours Of Physics Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Computer Science & Engineering': "Computer Science & Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Electrical Engineering': "Electrical Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Civil Engineering': "Civil Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Food Technology': "Food Technology Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Bio Technology': "Bio Technology Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Production Engineering': "Production Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Electronics Engineering': "Electronics Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Computer Application': "Computer Application Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Bachlore of Computer': "Bachlore of Computer Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Bachlore of Science': "Bachlore of Science Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Bachlore of Comarce': "Bachlore of Comarce Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Ceramic Engineering': "Ceramic Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master of Science Department': "Master of Science Department Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Computer Application': "Master In Computer Application Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Electrical Engineering': "Master In Electrical Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Mechnical Engineering': "Master In Mechnical Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Civil Engineering': "Master In Civil Engineering Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In BioTechnology': "Master In BioTechnology Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Food Technology': "Master In Food Technology Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Ceramic Technology': "Master In Ceramic Technology Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Bio Science': "Master In Bio Science Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Science': "Master In Science Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Architechnology': "Master In Architechnology Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Geography': "Master In Geography Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Biological Science': "Master In Biological Science Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Economics': "Master In Economics Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!",
        'Master In Zoological Science': "Master In Zoological Science Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!"
    }

    const [selected, setSelected] = useState();
    let [programTypeOptions, setProgramTypeOptions] = useState([]);

    useEffect(() => {
        setProgramTypeOptions(programsMap["graduate"].concat(programsMap["postGraduate"]))
        setForAllOption()
    }, [])

    const setForAllOption = () => {
        let tempItemList = []
        programsMap.postGraduate.map(item => {
            courseMap[item].map(val => {
                tempItemList.push(val)
            })
        })

        programsMap.graduate.map(item => {
            courseMap[item].map(val => {
                tempItemList.push(val)
            })
        })
        setItemList(tempItemList)
    }

    const programTypeChangeHandler = event => {
        console.error("Program Type " + event.target.value);
        let tempItemList = []
        if (event.target.value === 'Select All') {
            programTypeOptions.map(item => {
                courseMap[item].map(val => {
                    tempItemList.push(val)
                })
            })
        }
        else {
            courseMap[event.target.value].map(val => {
                tempItemList.push(val)
            })
        }
        setItemList(tempItemList)
        setSelected(event.target.value);

    }

    const programLevelChangeHandler = event => {
        if (event.target.value === 'Graduate') {
            setProgramTypeOptions(programsMap["graduate"])
            let tempItemList = []
            programsMap.graduate.map(item => {
                courseMap[item].map(val => {
                    tempItemList.push(val)
                })
            })
            setItemList(tempItemList)
        } else if (event.target.value === 'Post Graduate') {
            setProgramTypeOptions(programsMap["postGraduate"])
            let tempItemList = []
            programsMap.postGraduate.map(item => {
                courseMap[item].map(val => {
                    tempItemList.push(val)
                })
            })
            setItemList(tempItemList)
        } else {
            setProgramTypeOptions(programsMap["graduate"].concat(programsMap["postGraduate"]))
            setForAllOption()
        }
        setSelected("Select All")
    }

    return (
        <div>
            <div className="flex flex-col my-16 xl:my-12 lg:mx-12">
                <div className="flex flex-col w-full lg:h-16 lg:justify-around lg:border-b-2 lg:flex-row">
                    <div className="ml-5 text-4xl font-bold whitespace-nowrap lg:ml-1">Programs Details</div>
                    {/* <div className="xl:flex grid grid-cols-1 w-full py-2 space-x-5 space-y-3 lg:space-x-4 lg:flex-row lg:-space-y-0">
                       <div className="ml-5 col-span-1 mr-4 xl:mr-0">
                            <select onChange={programLevelChangeHandler} className="xl:w-48 w-full h-12 px-2 py-1 border-2 border-gray-300 rounded-md shadow-md lg:h-10 focus:outline-none ">
                                <option className="h-8" value="Select All">Select All</option>
                                <option className="h-8" value="Graduate">Graduate</option>
                                <option className="h-8" value="Post Graduate">Post Graduate</option>
                            </select>

                       </div>
                       
                       <div className=' col-span-1'>
                            <select value={selected} onChange={programTypeChangeHandler} className="xl:w-48 w-full h-12 px-2 py-1 border-2 border-gray-300 rounded-md shadow-md lg:h-10 ">

                                <option className="h-8" value="Select All">Select All</option>


                                {
                                    programTypeOptions.map((val, index) => (
                                        <option className="h-8" key={index} value={val}>{val}</option>
                                    ))
                                }
                            </select>

                    </div>    
                       
                    </div> */}
                </div>
                {/* <div>
                    <ProgrammsDetails data={itemList} courseDetailsMap={courseDetailsMap} />
                </div> */}
                {
                    // <div>
                    //     {programLevelData.programs[selected] === undefined ? "Hello" : <div>{programLevelData.programs[selected]}</div>}
                    // </div>
                }

            </div>
        </div>
    );
}
export default Programmes;