import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
 import image from "../Img/index.js"
import { globleinfo } from '../../App.js';

function Studentwork() {
  const {tempLang } = useContext(globleinfo);
  return (
    <div className="h-full xl:mx-20 p-4 xl:p-0 mt-10 overflow-hidden lg:mt-12 ">
      <div className="w-full h-full pb-8 border-t-2 ">
        <div data-aos="fade-left"
              data-aos-delay="400" className="flex pt-10  space-x-4 mt-4">
          <div className="-mt-6  text-3xl xl:text-4xl font-bold uppercase text-gray-900 ">{tempLang.home_Header.student_life.student}</div>
          <div className="-mt-6 text-3xl xl:text-4xl text-blue-400 uppercase">{tempLang.home_Header.student_life.life}</div>
        </div> 
        <div className="flex flex-col lg:flex lg:flex-row  lg:space-x-2">
          <div data-aos="fade-left"
              data-aos-delay="400" className="relative transform transition duration-500 hover:scale-x-110 mt-2 lg:w-1/3">
                <img className="w-full border-2 object-cover shadow-lg p-1" src={image.study10} alt="studentLifeImage1"></img>
                <img className="w-full border-2  object-center object-cover shadow-lg p-1" src={image.study12} alt="studentLifeImage1"></img>
                <img className="w-full border-2  object-center object-cover shadow-lg p-1" src={image.learn1} alt="studentLifeImage1"></img>
               
                <button className="absolute bottom-2 right-2  px-3  bg-yellow-500 font-medium text-white"><Link to="/student/study"> {tempLang.home_Header.student_life.study} </Link></button>
          </div>
          <div className="flex flex-col">
            <div data-aos="fade-top"
              data-aos-delay="400" className="relative transform transition duration-500 hover:scale-110 z-10 h-full mb-4 mt-2">
              <img className="w-full h-full  border-2 shadow-lg p-1 " src={image.learn} alt="studentLifeImage2"></img>
              <div className="absolute bottom-1 right-2">
              <button className="  bg-yellow-500 px-3 font-medium text-white"><Link to="/student/learn">{tempLang.home_Header.student_life.learn}</Link></button></div>
            </div>
            <div data-aos="fade-bottom"
              data-aos-delay="400" className="flex flex-row ">
                <div className="relative transform transition duration-500 hover:scale-110 lg:w-8/12">
                    <img className="w-full h-48 border-2 mb-2 p-1 shadow-lg " src={image.play9} alt="studentLifeImage3"></img>
                    <button className="absolute bottom-4 right-2 px-3  bg-yellow-500 text-white font-medium"> <Link to="/student/hostel">{tempLang.home_Header.student_life.play}</Link></button>
               </div>
              <div className="relative transform transition duration-500 hover:scale-110 ml-2 lg:w-7/12">
                <img className="w-full h-48 border-2  p-1 shadow-lg" src={image.play10} alt="studentLifeImage4"></img>
                <button className="absolute bottom-4 right-2 px-3 bg-yellow-500 font-medium text-white"><Link to="/student/play">{tempLang.home_Header.student_life.play}</Link></button>
              </div>
            </div>
          </div>
          <div data-aos="fade-right"
              data-aos-delay="400" className="relative transform transition duration-500 hover:scale-110 lg:mx-16 my-2 lg:mr-8">
            <img className="lg:w-64 w-full h-96 lg:h-full border-2 object-cover shadow-lg p-1" src={image.other} alt="studentLifeImage5"></img>
            <button className="absolute bottom-0 right-0 px-3 mt-6 bg-yellow-500 text-white font-medium"><Link to="/student/others">{tempLang.home_Header.student_life.other}</Link></button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Studentwork;