import React from "react";

function NoticePopUp(params) {
  return (
    <div>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 xl:mx-auto m-2 w-full xl:max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Add Notice</h3>
                <button
                  className="p-1 border-gray-700 ml-auto bg-transparent bg-red-300 border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => params.setShowModal(false)}
                >
                  <span className=" text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <section class=" p-4  bg-white rounded-md shadow-md dark:bg-gray-800 ">
                <form>
                  <div class="grid grid-cols-1 gap-6 mt-2 sm:grid-cols-2">
                    <div>
                      <label
                        class="text-gray-700 dark:text-gray-200"
                        for="password"
                      >
                        Title
                      </label>
                      <input
                      name="title"
                      onChange={(e)=>params.changeHandlerData(e)}
                      value={params.data.title}
                        type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      ></input>
                    </div>

                    <div>
                      <label
                        class="text-gray-700 dark:text-gray-200"
                        for="passwordConfirmation"
                      >
                        Date
                      </label>
                      <input
                       name="date"
                       onChange={(e)=>params.changeHandlerData(e)}
                       value={params.data.date}
                        type="date"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <label
                      class="text-gray-700 dark:text-gray-200"
                      for="password"
                    >
                      Discription
                    </label>
                    <textarea
                     name="discription"
                     onChange={(e)=>params.changeHandlerData(e)}
                     value={params.data.discription}
                      type="text"
                      placeholder="Description..."
                      rows={4}
                      class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    ></textarea>
                  </div>
                </form>
              </section>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => params.setShowModal(false)}
                >
                  Close
                </button>
                <button onClick={(e) => params.handlerSubmit(e)} class="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </>
    </div>
  );
}

export default NoticePopUp;
