import React, { useState, useEffect, useContext } from 'react';
import constant from '../../Constant/constant';
import { apiCall } from '../../ApiServices/apiCallManager';
import { globleinfo } from '../../App';

function FacultyDirectory(props) {
  const { tempLang,} = useContext(globleinfo);
  const [filter, setFilter] = useState([])
  const [rowIndex, setrowIndex] = useState()
  const [activeState, setNoneactive] = useState({
    activeValue: '',

  });

  let activeData = ['activeValue', 'noneactive'];

  // handle change event of search input
 

  const [data, setData] = useState([]);

  const fatchData = async()=>{
    apiCall(null,"get","teacher","get/all").then((res) => {
          setData([...res.data]);
          setFilter([...res.data])
          console.log(res.data)
        });
      };

      useEffect(() => {
         fatchData();
      }, []);
  
      const getImagePath = (filename) => {
          var  basePath = `${constant.BACKEND_URL}/api/upload/`;
          return basePath.concat(filename)

      }

  const requestSearch = (searchedVal) => {
    const filteredRows = data.filter((row) => {
      return row.name
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase()) || row.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(data);
    } else {
      setFilter(filteredRows);
    }
  };

  return (
    <div className="flex flex-col mx-4 space-y-5 lg:mx-20">
      <h1 className="pb-3 text-3xl font-bold border-b-2 border-gray-400">
      {tempLang.faculty.directory.heading}
            </h1>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class=" overflow-hidden sm:rounded-lg">
              <input type="text" placeholder="Type to search..."  onChange={e => requestSearch(e.target.value)} className="h-10 px-10 mx-5 border-2 border-gray-300 shadow-md w-96 rounded-2xl focus:border-blue-900 focus:outline-none" />
              <table class="min-w-full divide-y divide-gray-600">
                <thead class="bg-gray-50 mx-5">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider">
                       {tempLang.faculty.directory.table.faculty}
                        </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider">
                        {tempLang.faculty.directory.table.department}
                        </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider">
                       {tempLang.faculty.directory.table.qualification}
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-black uppercase tracking-wider">
                        {tempLang.faculty.directory.table.image}
                        </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-400">

                  {filter.map((item, itemNo) => (
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">

                          <div class="ml-4">
                            <div class="text-sm font-medium text-gray-500">
                            {item.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-500">{item.bestInSubject}</div>

                      </td>

                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.qualification}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <img class="h-10 w-10 rounded-lg" src={getImagePath(item.teacherImage)}/>
                      </td>

                    </tr>
                  ))}
                  {data.length === 0 && <span>No records found to display!</span>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacultyDirectory;