import React, { useEffect, useState } from "react";
import PopupUI from "./PopupUI";
import Cookies from "universal-cookie";
import constant from "../../Constant/constant";
import { apiCall } from "../../ApiServices/apiCallManager";
const cookies = new Cookies();

function Student({ students, setStudents }) {
  const tempObj = {
    name: "",
    father: "",
    mother: "",
    roll: "",
    classes: "",
    section: "",
    dob: "",
    doa: "",
    phone: "",
    address: "",
    studentImage: "",
  };
  const [studentData, setStudentData] = useState(tempObj);
  const [student, setStudent] = useState([]);
  const [filter, setFilter] = useState(students);
  const [show, setShow] = useState(false);
  const [buttonText, setButtonText] = useState("Save");
  const [selectedRowINdex, setSelectedRowIndex] = useState(-1);
  const [selectedFile, setSelectedFile] = useState(null);
  const fatchData = async () => {
    const cookieToken = cookies.get("TOKEN");
    apiCall("application/json","get","student","get/all")
      .then((res) => {
        console.log(res);
        if (res?.message === "unable to get all students") {
          alert("unable to get all students");
        } else {
          setStudent([...res.data]);
          setFilter(res.data);
        }
      });
  };
  useEffect(() => {
    fatchData();
  }, [show]);

  const inputChangeHandler = (e) => {
    if (e.target.name === "studentImage") {
      setSelectedFile(e.target.files[0]);
    } else {
      studentData[e.target.name] = e.target.value;
    }

    setStudentData({ ...studentData });
  };
  const requestSearch = (searchedVal) => {
    const filteredRows = student.filter((row) => {
      return row.name
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(student);
    } else {
      setFilter(filteredRows);
    }
  };
  const openPopup = () => {
    setShow(!show);
    setButtonText("Save");
    setStudentData(tempObj);
  };
  const handleSubmit = (e) => {
    const formData = new FormData();
    e.preventDefault();
    console.log(selectedFile);
    formData.append("file", selectedFile);
    formData.append("studentData", JSON.stringify(studentData));
    const cookieToken = cookies.get("TOKEN");
    setStudentData({ ...studentData });

    if (buttonText === "Save") {
      apiCall('multipart/form-data',"post","student","create",null,cookieToken,formData)
        .then((res) => {
          console.log("created");
          console.log(res.data);
          if (res.status !== 200 && res?.message !== "error occured") {
            alert(res.data);
            openPopup();
          } else if (res?.message === "error occured") {
            alert(res.message);
            openPopup();
          } else {
            setStudentData(studentData);
            setStudents([...students, res.data]);
            setShow(!show);
          }
        })
        .catch((err) => {
          alert("Student is not created successfully");
        });
    } else if (buttonText === "Update") {
      apiCall('multipart/form-data',"post","student","update",null,cookieToken,formData)
        .then((res) => {
          console.log(res);
          if (res.status !== 200 && res?.message !== "error occured") {
            alert(res.data);
            openPopup();
          } else if (res?.message === "error occured") {
            alert(res.message);
            openPopup();
          } else {
            setStudentData(studentData);
            setShow(!show);
          }
        })
        .catch((err) => {
          alert("Student is not updated succesfully");
        });
    }
    openPopup();
  };
  const handlerUpdated = (index) => {
    setShow(!show);
    setSelectedRowIndex(index);
    setButtonText("Update");
    setStudentData(students[index]);
  };
  const handlerDeleteWithToken = (id) => {
    const cookieToken = cookies.get("TOKEN");
    apiCall(null,"DELETE","student","delete",id,cookieToken)
      .then((res) => {
        if (res.status !== 200) {
          return alert(res);
        }
        setStudents(students.filter((student) => student.id !== id));
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div class="shadow   border-b border-gray-200 sm:rounded-lg">
        <div className="">
          <div className="flex xl:flex-row flex-row-reverse py-2 bg-gray-300 border-b-2 border-gray-500  rounded-r-md">
            <div className=" mx-4">
              <button
                onClick={() => openPopup()}
                className=" truncate px-3 py-2 bg-green-600 text-black border rounded-md"
              >
                Add Students
              </button>
            </div>

            <div>
              <input
                type="text"
                placeholder="Type to search..."
                onChange={(e) => requestSearch(e.target.value)}
                className="w-56 h-10 px-10 mx-2 border border-black rounded-lg focus:outline-none"
              />
            </div>
            <div>
              {/* <img src={tempImage}></img> */}
              {/* { image === undefined && image.map((i) => (
        <img  key={i._id} src={i.path} alt={i.filename}></img>
      ))} */}
            </div>
          </div>
          <div className="flex flex-col w-full overflow-y-auto h-screen">
            <table class="w-full divide-y  overflow-x-scroll  divide-gray-200">
              <thead class="bg-gray-200">
                <tr>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex flex-row items-center">
                      <p>No</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex flex-row items-center">
                      <p>Student Id</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex flex-row">
                      <div className="flex flex-row items-center">
                        <p>Name</p>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex flex-row items-center">
                      <p>Name of Parent's</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider truncate"
                  >
                    <div className="flex flex-row items-center">
                      <p>Mobile NO</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex flex-row items-center">
                      <p>Class/Section</p>
                    </div>
                  </th>

                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider truncate"
                  >
                    <div className="flex flex-row items-center">
                      <p>roll no</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider truncate"
                  >
                    <div className="flex flex-row truncate items-center">
                      <p>Year of Admission</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider truncate"
                  >
                    <div className="flex flex-row items-center">
                      <p>Date of Birth</p>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="bg-gray-100 divide-y divide-gray-200">
                {filter &&
                  filter.map((item, index) => (
                    <tr>
                      <td class="px-4 py-1 whitespace-nowrap">{index + 1}</td>
                      <td class="px-4 py-1 whitespace-nowrap">{item.id}</td>
                      <td class="px-4 py-1 whitespace-nowrap">{item.name}</td>

                      {/* src="././uploads/1683444675496.JPG" */}
                      <td>
                        <div class="text-sm text-gray-900">
                          {item.father}/{item.mother}
                        </div>
                      </td>
                      <td>
                        <div className=" text-center px-2">{item.phone}</div>
                      </td>

                      <td>
                        <div className=" text-center px-2">
                          {item.classes}/{item.section}
                        </div>
                      </td>

                      <td>
                        <div className=" text-center px-2">{item.roll}</div>
                      </td>
                      <td>
                        <div className=" text-center px-2">{item.doa}</div>
                      </td>
                      <td>
                        <div className=" text-center px-2">{item.dob}</div>
                      </td>
                      <td>
                        <div className=" text-center px-2">
                          <img
                            className=" w-12 h-12"
                            alt="student"
                            src={`${constant.BACKEND_URL}/api/upload/${item.studentImage}`}
                          ></img>
                        </div>
                      </td>
                      <td class="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
                        <div className="">
                          <span class="inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            <p className=" truncate text-center w-20">
                              {item.address}
                            </p>
                          </span>
                        </div>
                      </td>
                      <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <button onClick={() => handlerUpdated(index)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-green-600 bg-gray-100 border-2 border-white rounded-md hover:text-indigo-900"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </button>
                          {/* <button onClick={()=>handlerDelete(item._id)}><svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 bg-gray-100 border-2 border-white rounded-md text-red-400 hover:text-red-800" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>
</button>  */}
                          <button
                            onClick={() => handlerDeleteWithToken(item.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 bg-gray-100 border-2 border-white rounded-md text-red-400 hover:text-red-800"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                {filter.length === 0 && (
                  <span>No records found to display!</span>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {show && (
        <PopupUI
          type="student"
          buttonText={buttonText}
          handleSubmit={handleSubmit}
          // uploadImage={uploadImage}
          handleInputChange={inputChangeHandler}
          studentData={studentData}
          openPopup={openPopup}
          selectedFile={selectedFile}
        />
      )}
    </div>
  );
}

export default Student;
