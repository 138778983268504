import React, { Fragment, useState, useEffect, useContext } from "react";
import { globleinfo } from "../../App";
import NoticeBoard from "./NoticeBoard";
import constant from "../../Constant/constant";
const imageUrl = `${constant.BACKEND_URL}/api/upload/`
const sl1 = `${imageUrl}/sl1.jpeg`;
const sl4 = `${imageUrl}/sl4.jpeg`;
const sl5 = `${imageUrl}/sl5.jpeg`;
const sl6 = `${imageUrl}/sl6.jpeg`;


function Carausal(params) {
  let imageList = [sl1, sl6, sl5, sl4];
  const {tempLang } = useContext(globleinfo);
  let [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('next');
  let [time, setTime] = useState(null);
  const [show, setShow] = useState(true);
  let imagetext = [
    "Student Image",
    "Teacher Image",
    "Sports Image",
    "Match Event",
  ];
  

  useEffect(() => {
    basicSetup();
  }, []);

  const basicSetup = () => {
    var myVar = setInterval(myTimer, 20000);
    // console.log(options)
  };

  const myTimer = () => {
    var d = new Date();
    if (time === null) setTime(d.toLocaleTimeString());

    if (d.toLocaleTimeString() !== time) {
      currentIndex =
        imageList.length !== currentIndex + 1 ? currentIndex + 1 : 0;
      setCurrentIndex(currentIndex);
      setTime(d.toLocaleTimeString());
    }
  };

  const rightClickHanlder = () => {
    const newIndex = (currentIndex + imageList.length - 1) % imageList.length;
    setCurrentIndex(newIndex);
    // currentIndex = imageList.length !== currentIndex + 1 ? currentIndex + 1 : 0;
    // setCurrentIndex(currentIndex);
  };

  const leftClickHandler = () => {
    const newIndex = (currentIndex + 1) % imageList.length;
    setCurrentIndex(newIndex);
    // currentIndex = currentIndex === 0 ? imageList.length - 1 : currentIndex - 1;
    // setCurrentIndex(currentIndex);
  };

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };


  return (
    <div>
      <div className="relative w-full mt-14 lg:mt-0 md:mt-0 xl:mt-0 ">
        <div
          className=""
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className=" duration-700 ease-in-out">
            <img
            alt="iamges"
              className="  w-full h-screen object-cover "
              src={imageList[currentIndex]}
            ></img>
          </div>
          <header className=" text-center absolute  bottom-0 w-full">
            <div className=" ">
              <div className=" flex xl:items-center items-start justify-start bg-opacity-20  h-screen xl:justify-start">
                <div className="  w-full xl:hidden block  ">
                  <h1 className="font-semibold text-white font-rammetto lg:text-5xl sm:text-4xl text-2xl">
                    {imagetext[currentIndex]}
                  </h1>
                  <p className="text-base leading-normal text-center text-white mt-6">
                    Manage your School professionally with Expericence Teacher,{" "}
                    <br /> inventory & accounting needs. Be a part of 1 Cr+ SMEs
                    in India who trust Our Teacher.
                  </p>
                </div>
              </div>
            </div>
          </header>

          <div className=" absolute bg-blue-800 bottom-0 xl:grid xl:grid-cols-3 grid grid-cols-4 w-full ">
            <div className=" flex ani animate-fade-in-down-slow items-start border-b-2 xl:border-r-2 xl:col-span-1  h-full col-start-1 col-span-2 justify-start">
              <div className="  bg-blue-800 p-2 pl-4">
                <div className=" flex flex-row items-center justify-between">
                  <h1 className="font-semibold text-white font-rammetto lg:text-2xl sm:text-lg">
                   {tempLang.home_Header.scholarship_facility.heading}
                  </h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-8 h-8 text-yellow-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                    />
                  </svg>
                </div>
                <p className="text-sm leading-normal text-white mt-2">
                {tempLang.home_Header.scholarship_facility.discription}
                </p>
              </div>
            </div>
            <div className=" flex animate-fade-in-downx-slow items-start xl:col-span-1 w-full h-full col-start-3 col-span-2  border-b-2 xl:border-r-2 justify-start">
              <div className="  bg-blue-800 p-2 pl-4">
                <div className=" flex flex-row items-center justify-between">
                  <h1 className="font-semibold text-white font-rammetto lg:text-2xl sm:text-lg">
                  {tempLang.home_Header.bus_facility.heading}
                  </h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-8 h-8 text-yellow-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                    />
                  </svg>
                </div>
                <p className="text-sm leading-normal text-white mt-2">
                {tempLang.home_Header.bus_facility.discription}
                </p>
              </div>
            </div>
            <div className=" flex animate-fade-in-downx-slow items-start xl:col-span-1 col-start-1 col-span-4 border-b-2 justify-start">
              <div className="  bg-blue-800 p-2 pl-4">
                <div className=" flex flex-row items-center justify-between">
                  <h1 className="font-semibold text-white font-rammetto lg:text-2xl sm:text-lg">
                   {tempLang.home_Header.skilled_Lecturers.heading}
                  </h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-8 h-8 text-yellow-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    />
                  </svg>
                </div>

                <p className="text-sm leading-normal text-white mt-2">
                {tempLang.home_Header.skilled_Lecturers.discription}
                </p>
              </div>
            </div>
            <div className=" col-span-4"><NoticeBoard /></div>
          </div>
          {/* <div>
          <NoticeBoard />
          </div> */}

          <div className="flex flex-row justify-between z-10">
            <div>
              {show && (
                <button
                  onClick={leftClickHandler}
                  type="button"
                  className="absolute top-0 left-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  data-carousel-prev
                >
                  <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      ></path>
                    </svg>
                    <span className="sr-only">Previous</span>
                  </span>
                </button>
              )}
            </div>
            <div>
              {show && (
                <button
                  onClick={rightClickHanlder}
                  type="button"
                  className="absolute top-0 right-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  data-carousel-next
                >
                  <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                    <span className="sr-only">Next</span>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carausal;
