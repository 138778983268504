import constant from "../../Constant/constant";

 const imageUrl = `${constant.BACKEND_URL}/api/upload`
 const customer  = `${imageUrl}/customer.png`;
 const study10 = `${imageUrl}/study10.jpeg`
 const learn = `${imageUrl}/learn.jpeg`
 const learn3 = `${imageUrl}/learn3.jpeg`
 const play10 = `${imageUrl}/play10.jpeg`
 const other = `${imageUrl}/other.jpeg`
 const play9 = `${imageUrl}/play9.jpeg`
 const study12 = `${imageUrl}/study12.jpeg`
 const learn1 = `${imageUrl}/learn1.jpeg`
 const user = `${imageUrl}/user.png`
 const library = `${imageUrl}/librery.jpeg`
 const library1 = `${imageUrl}/library1.jpeg`
 const principal = `${imageUrl}/teacher/principal_magic.jpg`
 const principal1 = `${imageUrl}/teacher/principle1.JPG`
 const teacher1 = `${imageUrl}/teacher/teacher1.JPG`
 const maddem1 = `${imageUrl}/teacher/maddem1.JPG`
 const maddem2 = `${imageUrl}/teacher/maddem2.JPG`
 const mess = `${imageUrl}/teacher/mess.JPG`
 const laboratories = `${imageUrl}/teacher/laboratories.JPG`
 const parking = `${imageUrl}/teacher/parking.JPG`
 const students = `${imageUrl}/teacher/students.JPG`
 const nccImage = `${imageUrl}/student/ncc.jpg`
 const nccImage2 = `${imageUrl}/student/nccImage2.jpg`
 const womenkabbadi = `${imageUrl}/student/womankabbadi.jpg`
 const bhalaphak = `${imageUrl}/student/bhalaphek.jpg`
 const athelete = `${imageUrl}/student/athelete.jpg`
 const childrenPenting = `${imageUrl}/teacher/childrenPenting.jpg`
 const student = `${imageUrl}/student/student.jpg`
 const sport = `${imageUrl}/student/sports.jpg`
 const sport1 = `${imageUrl}/student/sport1.jpg`
 const sport3 = `${imageUrl}/student/sport3.jpg`
 const sport4 = `${imageUrl}/student/sport4.jpg`
 const sport5 = `${imageUrl}/student/sport5.jpg`
 const study = `${imageUrl}/teacher/study.JPG`
 const study1 = `${imageUrl}/teacher/study1.JPG`
 const study2 = `${imageUrl}/teacher/study2.JPG`
 const study3 = `${imageUrl}/teacher/study3.JPG`
 const studentlibrary = `${imageUrl}/teacher/library.JPG`
 const userIcon = `${imageUrl}/user.png`


 const images = { customer, study10, learn, play10, other, play9, study12, learn1,learn3, user, library,
     library1, principal,principal1, teacher1, maddem1, maddem2, mess, laboratories, parking, students,
      nccImage, nccImage2, womenkabbadi, bhalaphak, athelete, childrenPenting, student,
       sport, sport1, sport3, sport4, sport5, study, study1, study2, study3, studentlibrary, userIcon}

 export default images;