import React, { useContext } from 'react'
import { globleinfo } from '../../App';
import images from '../Img';
import { Link } from 'react-router-dom';

function AcademicWorkComponent() {
  const {tempLang} = useContext(globleinfo);
    return (
        <div className=" border-t-2 p-4 xl:p-0 xl:mx-20 overflow-hidden mt-16">
            <div className="flex justify-between   py-6">
                <div data-aos="fade-left"
              data-aos-delay="400">
                    <span className="text-xl text-black lg:text-4xl uppercase lg:font-extrabold">{tempLang.home_Header.student}</span>
                    <span className="ml-2 text-xl text-blue-400 lg:font-normal uppercase lg:text-4xl">{tempLang.home_Header.work}</span>
                </div>
                <div className="flex justify-center items-center ">
                  <Link className=' flex' to='/student/study'>

                    <button className="font-medium text-blue-700">{tempLang.home_Header.view_all}</button>
                    <svg class="w-6 h-6 text-blue-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </Link></div>
            </div>
            <div className="lg:flex lg:space-x-4  relative">
                    <div data-aos="fade-left"
                      data-aos-delay="400" className="border-2 lg:mt-2 transform hover:scale-105 duration-1000 shadow-lg">
                        <img className="w-full h-54  h-64 lg:h-full  " src={images.student}></img>
                        {/* <p className=" bg-white px-5 py-2 text-sm"> {tempLang.home_Header.discription}
                       </p> */}
                    </div>
                    <div data-aos="fade-right"
                        data-aos-delay="400">
                    <div className="lg:flex lg:space-x-4 mt-2 relative ">
                       <div  className='relative w-full'>
                       <img className="lg:w-full h-full border-2 transform hover:scale-105 duration-1000  shadow-lg" src={images.learn3} alt="abc"/> 
                        {/* <div className="absolute lg:bottom-0 bottom-0 h-10 py-2  bg-blue-800 opacity-50 w-full lg:w-full">
                          <p className=" text-white text-center text-sm">1 Design and Fabrication of Pneumatic Car Bumper</p>
                        </div> */}
                       </div>
                       <div  className='relative w-full'>
                       <img className="lg:w-full h-72 lg:mt-0 mt-4 border-2 transform hover:scale-105 duration-1000 object-fill  shadow-lg" src={images.laboratories} alt="abcd"/> 
                         {/* <div className="absolute lg:bottom-0 bottom-0 h-10 py-2  bg-blue-800 opacity-50 w-full lg:w-full">
                         <p className=" text-white text-sm">2 Design and Fabrication of Pneumatic Car Bumper</p>
                        </div> */}
                       </div>
                    </div>
                    <div ata-aos="fade-right"
                        data-aos-delay="400">
                    <div className="lg:flex lg:space-x-4 mt-2 relative ">
                      
                           <div className=' relative w-full'>
                        <img className="lg:w-full h-full lg:mt-0 mt-4 border-2 transform hover:scale-105 duration-1000  shadow-lg" src={images.childrenPenting} alt="abcd"/> 
                         
                        
                         {/* <div className="absolute bottom-0 h-10 py-2 w-full  bg-blue-800 opacity-50 lg:w-full ">
                           <p className="mb-2 text-white text-sm">4 Design and Fabrication of Pneumatic Car Bumper</p>
                         </div> */}
                        </div>
                      <div className=' relative w-full'>
                      <img className="lg:w-full h-full border-2 mt-4 xl:mt-0  transform hover:scale-105 duration-1000 shadow-lg" src={images.childrenPenting} alt="abc"/>   
                      {/* <div className="absolute bottom-0  h-10 py-2 w-full  bg-blue-800 opacity-50 lg:w-full ">
                          <p className=" text-white text-sm">3 Design and Fabrication of Pneumatic Car Bumper</p>
                        </div> */}
                      </div>
                        </div>
                   
                       
                    </div>
                    </div>
                    
                </div>
               
        </div>
    )
}

export default AcademicWorkComponent
