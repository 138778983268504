import React, { Fragment, useState } from 'react';

function ApplyOnline() {
    var isVisible = false
    const [show, setshow] = useState(0)

    const ShowPopUp = () => {
        setshow(!show)
    }

    const HidePopUp = () => {
        setshow(!show)
    }

    return (
        <div className="flex flex-col h-screen mx-16">
            <div className="static flex flex-col h-screen mx-16">
                <div className="my-10 text-center ">
                    <p className="text-5xl font-bold text-black lg:text-7xl">Application Form</p>
                    <p className="my-8 text-2xl font-bold text-blue-800 lg:text-4xl">Admission packs are available at Cost of Rs. 1,100 /-</p>
                </div>
                <div className="flex flex-col items-center space-x-6 border border-gray-300 lg:flex-row lg:justify-center">
                    <img className="" src="https://i.dlpng.com/static/png/6864305_preview.png" alt=""></img>
                    <img src="https://smsliv.org/pictures/2018/1/apply-now.png" alt=""></img>

                    <div className="flex flex-col mb-4 space-y-4 text-left">
                        <p className="px-10 py-3 mt-5 text-xl font-semibold border-b border-yellow-700 lg:text-xl">Apply online, Upload Documents, Pay Online</p>
                        <p>1. Fill and submit application form online Application.</p>
                        <p>2. Upload softcopy of class X mark sheet and recent color passport size photograph.</p>
                        <p>3. Pay online through Credit/Debit Card.</p>

                        <button onClick={ShowPopUp} className="h-10 font-semibold text-white bg-yellow-500 w-36 hover:bg-yellow-400">Apply Online</button>
                        <div>
                            {show &&

                                <div className="fixed inset-0 z-10 overflow-y-auto">
                                    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                                            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                                        </div>
                                        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                <div class="sm:flex sm:items-start">

                                                    <div className="flex flex-col">
                                                        <button className="mx-10 ml-96" onClick={HidePopUp}>
                                                            {/* <button className="mx-10 ml-96" onClick={props.ButtonClose} */}
                                                            <svg class="w-6 h-6 text-blue-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"></path></svg>
                                                        </button>
                                                        <p className="font-semibold text-indigo-600">Name :</p>
                                                        <input className="py-1 bg-gray-200 rounded-md" type={Text} placeholder="Name Title"></input>
                                                        <p className="font-semibold text-indigo-600">Email Address :</p>
                                                        <input className="py-1 bg-gray-200 rounded-md" type={Text} placeholder="abc@gmail.com"></input>

                                                        <button className="w-20 h-8 mt-3 text-white bg-yellow-500 rounded-lg">Confirm</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplyOnline;