import { GoogleMap, LoadScript } from "@react-google-maps/api";
import images from "../Img/index";
import constant from "../../Constant/constant";
import { globleinfo } from "../../App";
import { useContext } from "react";
const contact = `${constant.BACKEND_URL}/api/upload/contact.jpg`


function NewContacts(params) {
  const { tempLang,} = useContext(globleinfo);
  const mapStyles = {
    height: "70vh",
    width: "100%",
    margin: "1%",
  };

  const defaultCenter = {
    lat: 22.5726,
    lng: 88.3639,
  };

  return (
    <div>
      <div className="mb-14 ">
        <div className="relative">
          <img
            className="h-80 object-cover object-top w-full "
            src={contact}
            alt="abc"
          />

          <div className="flex flex-col space-y-5 lg:flex-row xl:mx-20 mx-4 lg:space-x-5 -my-12 text-center sm:text-left">
            <div className="bg-white border shadow-xl w-full lg:h-64 lg:w-1/2 py-4 mt-4 rounded-md ">
              <div className=" flex flex-col items-center justify-between">
                <svg
                  className="w-8 h-8 text-indigo-700"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M17.924 2.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0017 2h-4a1 1 0 100 2h1.586l-3.293 3.293a1 1 0 001.414 1.414L16 5.414V7a1 1 0 102 0V3a.997.997 0 00-.076-.383z"></path>
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                </svg>
                <div>
                  <h1 className="  pt-2 font-bold text-gray-700">{tempLang.contact.heading}</h1>
                </div>
                <div>
                  <p className="  pt-2 font-normal text-center text-gray-700">{tempLang.contact.discription}</p>
                </div>
                <div>
                  <p className="  pt-6 font-semibold text-lg text-center text-blue-900">{tempLang.contact.details}</p>
                </div>
              </div>
            </div>
            <div className="bg-white border shadow-xl w-full lg:h-64 lg:w-1/2 py-4 mt-4 rounded-md ">
              <div className=" flex flex-col items-center justify-between">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-blue-900">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                </svg>

                <div>
                  <h1 className="  pt-2 font-bold text-gray-700">{tempLang.contact.heading1}</h1>
                </div>
                <div>
                  <p className="  pt-2 font-normal text-center text-gray-700">{tempLang.contact.discription1}</p>
                </div>
                <div>
                  <p className="  pt-6 font-semibold text-lg text-center text-blue-900">{tempLang.contact.details1}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" text-gray-100 xl:mx-20  lg:py-6 px-2 py-2">
        <div class="text-center w-full">
          {/* <div>
            <h className="flex justify-center items-center font-bold text-2xl text-gray-600 mt-8 font-serif">
              Location of College
            </h>
          </div> */}
        </div>
        <div class="  px-8 grid xl:gap-32 gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-24  py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
          <div class="">
            <LoadScript googleMapsApiKey="AIzaSyD5SMQ_NREttDaqqgUs6H_d4WS3-WpYOkw">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={10}
                center={defaultCenter}
              />
            </LoadScript>
          </div>
          <div class="">
            <div>
              <span class="uppercase text-sm text-gray-600 font-bold">
              {tempLang.contact.fullname}
              </span>
              <input
                class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Name"
              ></input>
            </div>
            <div class="mt-8">
              <span class="uppercase text-sm text-gray-600 font-bold">
              {tempLang.contact.email}
              </span>
              <input
                class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Email"
              ></input>
            </div>
            <div class="mt-8">
              <span class="uppercase text-sm text-gray-600 font-bold">
              {tempLang.contact.message}
              </span>
              <textarea placeholder="Enter your Message..." class="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
            </div>
            <div class="mt-8">
              <button class="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
              {tempLang.contact.send_Message}
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
export default NewContacts;
