import { useContext } from "react";
import constant from "../../Constant/constant";
import images from "../Img/index";
import { globleinfo } from "../../App";
const study  = `${constant.BACKEND_URL}/api/upload/study.jpeg`;
const study1 = `${constant.BACKEND_URL}/api/upload/study1.jpeg`;
const study2 = `${constant.BACKEND_URL}/api/upload/study2.jpeg`;
const learn  = `${constant.BACKEND_URL}/api/upload/learn.jpeg`;
const learn1 = `${constant.BACKEND_URL}/api/upload/learn1.jpeg`;
const learn2 = `${constant.BACKEND_URL}/api/upload/learn2.jpeg`;
const learn3 = `${constant.BACKEND_URL}/api/upload/learn3.jpeg`;

function Study() {
  const { tempLang,} = useContext(globleinfo);
  return (
    <div className="h-auto  lg:text-sm xl:mx-20 mb-8">
      <div className="text-3xl font-bold lg:text-5xl mt-5 lg:h-20 animate-fade-in-downx">
        {tempLang.student.study.heading}
      </div>
      <div className="h-20 animate-fade-in-downr">
        <p className="font-bold mt-3 text-blue-600 lg:text-2xl"> {tempLang.student.study.subheading}</p>
        <p className="text-sm  text-gray-800"> {tempLang.student.study. subheading_list}</p>
      </div>
      <div>
        <div className="text-gray-600 text-lg text-justify mb-3">
        {tempLang.student.study. discription}
        </div>
        <div className=" flex xl:flex-row flex-col space-x-6 w-full">
          <img
            className="h-96 xl:w-1/2 w-full animate-bounce"
            src={images.study}
          ></img>
          <img
            className="h-96 xl:w-1/2 xl:block hidden w-full animate-bounce"
            src={study}
          ></img>
        </div>
        <div className="text-gray-600 mt-3 text-lg mb-10">
        {tempLang.student.study. discription1}
        </div>
      </div>
      <div>
        <div className="  mt-10 lg:mx-0">
          <h1 className="font-bold text-3xl  animate-fade-in-downr">
          {tempLang.student.study. subheading1}
          </h1>
          <p className="mt-2 my-10 text-justify text-lg text-lg animate-fade-in-downx">
          {tempLang.student.study.  subheading1_list}
          </p>

          <div className=" text-justify text-lg">
            <p3>
            {tempLang.student.study.  discription2}
            </p3>
            <img
              className="my-10 lg:w-full animate-fade-in-down"
              src={learn}
            ></img>
            <h className="font-bold text-indigo-600 text-2xl ml-0 xl:ml-4">
              {tempLang.student.study.subheading2}
            </h>
          </div>
          <div className="lg:flex">
            <p className="xl:mx-4 mx-0 xl:my-10 text-lg mt-4 text-justify animate-fade-in-left-slow">
              {tempLang.student.study.discription4}
            </p>
            <img
              className=" lg:w-full lg:h-60 lg:mx-4 animate-fade-in-left-slower"
              src={images.study1}
              alt="collegphoto"
            ></img>
            <img
              className=" lg:w-full xl:block hidden lg:h-60 lg:mx-4 animate-fade-in-left-slowest"
              src={images.studentlibrary}
              alt="collegphoto"
            ></img>
          </div>
          <div className="my-8">
            {/* <p1>
                            In addition, the hostel life allows students to interact with their colleagues and peers, make friends, and develop into good
                            human beings capable of independent judgement and competent in handling the day-today pressures of life. Some students may leave their homes for the
                            first time but they can be reassured, these hostels would eventually turn out to be their home away from home
                            </p1> */}
          </div>
          {/* <div className="bg-yellow-900 h-auto py-4 w-full lg:flex items-center justify-center ">
                        <img className="h-56 lg:w-96 lg:mx-14 mx-auto lg:h-80 animate-fade-in-downx" src={learn3} alt="lastphoto"></img>
                        <p className="lg:mx-14 mx-4 mt-10 text-white lg:mt-4 lg:text-xl text-center animate-bounce-slow">I was staying in a hostel for the first time, but the caring wardens, homely food and the friendly atmosphere made me feel so much at home.</p>
                    </div> */}
        </div>
      </div>
      {/* <div className="mt-10 flex flex-col space-y-4 lg:flex-row lg:justify-between">
        <div className="flex flex-col animate-fade-in-downr">
        <div className="font-bold text-blue-900 text-xl">Let's do great things together</div>
            <div className="mt-3 lg:mt-10 lg:w-1/2">
                Located in the UK and want to donate the equipment you don’t use anymore? Head to the Otter website to find out how you can make a difference.
            </div>
            <div className="mt-5 font-bold">
                We see the results of our method.
            </div>            
        </div>
        <div className="animate-fade-in-downx">
               <img className="w-full h-full" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6Jx-OMueFVc8g5JsXQbh4Vn4uOigBqREZFQ&usqp=CAU" alt=""></img>
            </div>
        </div> */}
    </div>
  );
}

export default Study;
