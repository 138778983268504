import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PopUpUi from "../popup/PopUpUi";
import { globleinfo } from "../../App";
import { apiCall } from "../../ApiServices/apiCallManager";

function NoticeList() {
  const { tempLang,} = useContext(globleinfo);
  const [showpopup, setShowPopup] = useState(false);
  const [popuplist, setPopuplist] = useState();
  const [noticeList, setNoticeList] = useState([]);

  const location = useLocation();
  console.log(location)
  const noticeData = location.state?.noticeData;
  console.log(noticeData);

  const handlePopupUi = (item) => {
    setShowPopup(!showpopup);
    setPopuplist(item);
  };
  const onClose = () => {
    setShowPopup(false);
  };

  const fatchData = async () => {
    apiCall("application/json","get","notice","get/all")
      .then(async (res) => {
        var noticeData = await res.data;
        setNoticeList(noticeData);
       
      });
  };
   useEffect(()=>{
    fatchData()
   },[])
  return (
    <div className=" w-full">
      <div className=" flex flex-col  p-6 items-center justify-items-center">
        <span className=" text-6xl text-center text-gray-900">{tempLang.home_Header.NoticeList.noticeDetails.heading}</span>
        <p className=" max-w-3xl mt-4 mb-8 text-justify">
        {tempLang.home_Header.NoticeList.noticeDetails.description}
        </p>
      </div>
      <div className=" flex xl:flex xl:flex-row xl:w-full lg:flex-wrap flex-col xl:m-0 p-4 items-start w-full justify-between">
      {noticeList?.map((element, index) => (
     
          <div
            key={index}
            onClick={() => handlePopupUi(element)}
            className=" flex xl:w-1/5 w-full cursor-pointer flex-row items-center justify-start border border-gray-300 p-1 xl:m-3 pl-4 mt-4 pr-4 pr-4"
          >
            <div>
              <div className="flex px-4 space-x-2">
                <svg
                  className="w-4 h-4 mt-1 text-sm font-semibold text-green-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <p className="text-sm font-medium text-gray-500">
                  {element.date}{" "}
                </p>
              </div>
              <p className="px-4 text-sm font-medium text-gray-500">
                {element.title}{" "}
              </p>
              <p className="px-4 text-sm font-medium text-gray-500">
                {element.discripton}{" "}
              </p>
            </div>
          </div>
       
      ))}
       </div>
      {showpopup && <PopUpUi popuplist={popuplist} onClose={onClose} />}
    </div>
  );
}

export default NoticeList;
