import React, { useEffect, useState } from "react";

 function BtButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="fixed z-40 bottom-4 right-28">
         { isVisible && 
            <button className="flex items-center justify-center w-10 h-10 bg-blue-500 rounded-full focus:outline-none" onClick={scrollToTop}><svg className="w-6 h-6 font-extrabold text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path></svg></button>
            }
        </div>
  );
}
export default BtButton











/*import React, {useState} from 'react'; 





  
const BtButton = () =>{ 

  

  const [visible, setVisible] = useState(false) 

  

  const toggleVisible = () => { 

    const scrolled = document.documentElement.scrollTop; 

    if (scrolled > 300){ 

      setVisible(true) 

    }  

    else if (scrolled <= 300){ 

      setVisible(false) 

    } 

  }; 

  

  const scrollToTop = () =>{ 

    window.scrollTo({ 

      top: 0,  

      behavior: 'smooth'

      /* you can also use 'auto' behaviour 

         in place of 'smooth' */

   /* }); 

  }; 

  

  window.addEventListener('scroll', toggleVisible);
    return (
       
        <div className="fixed z-40 bottom-4 right-28">
         { setVisible && 
            <button className="flex items-center justify-center w-12 h-12 bg-gray-500 rounded-full focus:outline-none animate-bounce" onClick={scrollToTop}><svg className="w-6 h-6 font-extrabold text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path></svg></button>
            }
        </div>
        
    )
}

export default BtButton*/
