import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/common/Footer.js";
import NavBar from "./components/common/Navbar.js";
import Programms from "./components/programms/Programms.js";
import Department from "./components/programms/Department.js";
import Admission from "./components/admission/Admission.js";
import About from "./components/about/About.js";
import PageNotFound from "./components/common/Test.js";
import Home from "./components/home/Home.js";
import Student from "./components/students/Student.js";
import Support from "./components/students/Support.js";
import ApplyOnline from "./components/apply/Apply.js";
import Gallery from "./components/Gallery/Gallery.js";
import Event from "./components/Gallery/Events.js";
import Faculty from "./components/faculty/Faculty.js";
import Alumni from "./components/alumni/Alumni.js";
import CollegeCampus from "./components/Gallery/CollegeCampus.js";
import NewContacts from "./components/contacts/NewContacts.js";
import Academic from "./components/academics/Academic.js";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import AdminTheme1 from "./components/admin/AdminTheme1";
import React, { createContext, useEffect, useState } from "react";
import Library from "./components/library/Library.js";
import HelpBox from "./components/home/HelpBox.js";
import ScrollToTop from "./components/ScrollToTop.js";
import BtButton from "./components/home/BtButton.js";
import Login from "./components/common/LogInPage.js";
import { english, bangali } from "./locales/Language.js";
import { getLanguageTemplate } from "./utils/LanguageUtils.js";
import CookiesConsent from "./components/CookiesConsent.js";
import TableCalender from "./components/programms/TableCalender.js";
import SignUpPage from "./components/common/SignUpPage.js";
import Cookies from "universal-cookie";
import EventList from "./components/home/EventList.js";
import NoticeDetails from "./components/home/NoticeList.js";
import NoticeList from "./components/home/NoticeList.js";
import ResetPassword from "./components/admin/ResetPassword.js";
import VerifyMail from "./components/admin/VerifyMail.js";
import addNotification from "react-push-notification";
import Notification from "./Notification.js";
const cookies = new Cookies();
export const globleinfo = createContext();

function App() {

  // useEffect(()=>{
  //   addNotification({
  //     title: "Welcome to Ramashish",
  //     message: "Visit our website for more information",
  //     duration:4000,
  //     native: true,
  //     onClick: ()=> console.log("Welcome to Ramashish")
  //   })
  // },[])
  const isAdminRoute = window.location.pathname === "/admin";
  const [isLogin, setIsLogin] = useState(false);
  const [tempLang, setTempLang] = useState(english);
  const [showHeader, setShowHeader] = useState(false);

  const languages = [
    { value: "en", text: "English" },
    { value: "hi", text: "Hindi" },
    { value: "bn", text: "Bengali" },
  ];

  Aos.init({
    duration: 1000,
    offset: 0,
  });

  useEffect(() => {
    if (
      window.location.pathname === "/admin" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/sign-up" ||
      window.location.pathname.includes("/reset/password")
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    const userToken = cookies.get("TOKEN");
    const fatchLangauge = () => {
      const cookies = document.cookie.split(";");
      const cookiesMap = [];
      cookies.forEach((cookie) => {
        const [name, value] = cookie.split("=");
        cookiesMap[name] = value;
      });
      const myData = cookiesMap["langauge"];
      if (myData !== undefined) setTempLang({ ...getLanguageTemplate(myData) });
    };

    fatchLangauge();
  }, []);

  return (
    <globleinfo.Provider
      value={{
        languages: languages,
        tempLang: tempLang,
        setTempLang: setTempLang,
      }}
    >
      <Router>
        {showHeader && <NavBar />}
        <Notification/>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/programs" element={<Programms />} />
          <Route path="/department" element={<Department />} />
          <Route path="/calender" element={<TableCalender />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/student/:id" element={<Student />} />
          <Route path="/support" element={<Support />} />
          <Route path="/apply" element={<ApplyOnline />} />
          <Route path="/gallery/:id" element={<Gallery />} />
          <Route path="/event" element={<Event />} />
          <Route path="/eventlist" element={<EventList />} />
          <Route path="/noticelist" element={<NoticeList />} />
          <Route path="/collegeCampus" element={<CollegeCampus />} />
          <Route path="/faculty/:id" exact element={<Faculty />} />
          <Route path="/alumni" element={<Alumni />} />
          <Route path="/library" element={<Library />} />
          <Route path="/noticeDetails/:id" element={<NoticeDetails />} />
          <Route path="/contacts" element={<NewContacts />} />
          {/* <Route path="/research/:id" element={<Research />} /> */}
          <Route path="/academics/:id" element={<Academic />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/admin" exact element={<AdminTheme1 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset/password/:id" element={<ResetPassword />} />
          <Route path="/reset/password" element={<ResetPassword />} />
          <Route path="/verify/:token" element={<VerifyMail />} />
        </Routes>
        {window.location.pathname === "/admin" ? null : <HelpBox />}
        <CookiesConsent />
        <ScrollToTop />
        <BtButton />
        {showHeader && <Footer />}
      </Router>
    </globleinfo.Provider>
  );
}

export default App;
