function Alumni(){
  return (
         <div>
               <div className="flex flex-row lg:divide-x">
                  <div className="hidden lg:rotate-90 lg:transform lg:mt-56 lg:text-4xl lg:origin-top lg:block">Alumni</div> 
                  <div>
                       <div className="flex flex-row mt-10 ml-8">
                              <div className="text-4xl text-gray-400 lg:text-2xl">Home</div>
                              <div className="text-4xl text-blue-400 lg:text-2xl">/</div>
                              <div className="text-4xl lg:text-2xl">Alumni</div>
                       </div>


                       <div className="mt-6 text-4xl">
                          <p className="ml-5 text-2xl lg:ml-8 lg:text-4xl"> MIT’s alumni — entrepreneurs, researchers,<br/> policymakers, and above all, leaders — have helped to <br/>shape the world we know today. From 3-D printing to <br/> bionic prostheses, many of the products and services <br/> that define the modern world have sprung from the <br/> minds and hands of MIT’s brilliant alumni community.</p> 
                       </div>
                         
                        <div className="flex-col lg:flex lg:flex-row">
                              <div className="my-8 lg:my-4">
                                  <p className="px-4 text-xl lg:px-8 lg:text-lg">MIT’s 139,000-plus alumni represent one of the most talented, <br/>innovative, and networked communities on the planet. Many <br/> remain deeply involved in the life of the Institute. They <br/>volunteer,serve on boards, head up regional alumni clubs,<br/>and generously support MIT’s students, faculty, and staff. As<br/> individuals, their contributions are extraordinary. As a <br/> community, their impact is infinite.</p>
                              </div>
                            
                              <div className="ml-4 mr-4 lg:mt-4 lg:ml-12">
                                  <video width="560" height="560" controls className="w-full mt-4 lg:border-4 lg:border-blue-400">
                                      <source src="http://techslides.com/demos/sample-videos/small.ogv" type="video/webm"/> 
                                  </video>
                              </div>


                        </div>

                        <div>
                        <div>
                          <h4 className="px-3 py-4 font-normal lg:px-8 lg:text-4xl lg:py-4">
                                <span className="text-4xl lg:ml-3 lg:text-4xl"> Get Involved</span>
                          </h4>
                                
                          <div className="flex-col lg:flex lg:flex-row">
                                <div className="lg:px-8">
                                      <p className="ml-4 text-2xl lg:ml-3 lg:text-base lg:mt-3">Join a club. Start a group. Make an annual gift. Attend an event.<br/> MIT draws great strength from its graduates’ ongoing<br/> engagement — with each other and with the Institute. Our<br/> alumni can always find community at MIT, no matter where in <br/>the world they are.</p>
                                </div>
                                <div className="mt-4 ml-4 mr-4 lg:w-6/12 lg:ml-20">
                                      <img  className="w-full lg:w-11/12 lg:h-72" src="https://www.fabhotels.com/blog/wp-content/uploads/2020/05/road-trip-hacks-tips-600.jpg"></img>
                                      <p className="text-lg lg:text-base">An MIT Alumni Travel Program trip to Tanzania</p>
                                </div>
                                
                          </div>
        
                          <div className="lg:px-8 top-24">
                                <h4 className="mt-4 ml-4 text-2xl lg:ml-3 lg:text-base">Top Resources</h4>
                                      <div className="flex flex-row mt-4 lg:mt-4">
                                      
                                            <div className="ml-4 lg:ml-3">
                                            <div className="text-2xl text-blue-400 lg:text-base">01</div>
                                            <div className="text-2xl text-blue-400 lg:text-base">02</div>
                                            <div className="text-2xl text-blue-400 lg:text-base">03</div>
                                            <div className="text-2xl text-blue-400 lg:text-base">04</div>
                                            <div className="text-2xl text-blue-400 lg:text-base">05</div>
                                            </div>
              
                                            <div className="ml-3">
                                            <div className="text-2xl border-b border-blue-400 lg:text-base">MIT Alumni Association</div>
                                            <div className="text-2xl border-b border-blue-400 lg:text-base">Alumni Directory</div>
                                            <div className="text-2xl border-b border-blue-400 lg:text-base">Alumni Career Resources</div>
                                            <div className="text-2xl border-b border-blue-400 lg:text-base">Lifelong Learning</div>
                                            <div className="text-2xl border-b border-blue-400 lg:text-base">Give to MIT</div>
                                            </div>
                                      </div>
                          </div>
                          
                          <div className="mt-4">
                                <h4 className="ml-4 text-3xl lg:px-10 lg:text-4xl">MIT Alumni News</h4>
                                <div className="lg:flex lg:flex-row">
                                     <div>
                                         <p className="px-4 py-4 text-2xl lg:px-10 lg:py-6 lg:text-base">In their communities and their professions, locally and around <br/> the globe, MIT’s alumni are vital to our vision of making a better<br/> world. Through MIT News, the Alumni Association’s Slice of MIT<br/> blog, Resource Development’s Spectrum, and MIT News<br/> in Technology Review, you can keep up with our <br/> graduates’ remarkable impact around the globe. </p>
                                     </div>
                                      <div className="flex flex-row py-8 ml-4 mr-4 space-x-2 lg:px-12 lg:space-x-6">
                                      
                                       <div className="">
                                            <img className="w-full h-full lg:h-48 lg:w-64" src="https://resize.indiatvnews.com/en/resize/newbucket/715_-/2018/05/trip-1526620844.jpg"></img>
                                      </div>
                                      <div className="">
                                            <img className="w-full h-full lg:h-48 lg:w-64" src="https://resize.indiatvnews.com/en/resize/newbucket/715_-/2018/05/trip-1526620844.jpg" ></img>
                                      </div>
                                      </div>
                                </div>
                          </div>
                          <div><h4 className="ml-4 text-2xl lg:px-7 lg:text-base">Top Resources</h4></div>
                          <div className="flex flex-col lg:flex lg:flex-row">
                          <div className="flex flex-row mt-4 lg:mt-4">
                                      
                                <div className="ml-4 lg:ml-10">
                                      <div className="text-2xl text-blue-400 lg:text-base">01</div>
                                      <div className="text-2xl text-blue-400 lg:text-base">02</div>
                                      <div className="text-2xl text-blue-400 lg:text-base">03</div>
                                      <div className="text-2xl text-blue-400 lg:text-base">04</div>
                                </div>

                                <div className="ml-3">
                                <div className="text-2xl border-b border-blue-400 lg:text-base">Slice of MIT Blog</div>
                                <div className="text-2xl border-b border-blue-400 lg:text-base">MIT News Office</div>
                                <div className="text-2xl border-b border-blue-400 lg:text-base">Alumni Career Resources</div>
                                <div className="text-2xl border-b border-blue-400 lg:text-base">Spectrum</div>
                              
                                </div>
                    </div>
                               <div className="flex flex-col mt-4 space-y-4 lg:ml-20">
                                     <div className="flex flex-row lg:ml-64">
                                            <span className="ml-4 text-2xl lg:text-base">a.</span>
                                            <p className="text-2xl lg:text-base">The MIT Student/Alumni Externship<br/> Program in action</p>
                                     
                                     </div>
                                      
                                     <div className="flex flex-row lg:ml-64">
                                      <span className="ml-4 text-2xl lg:text-base">b.</span>
                                      <p className="text-2xl lg:text-base">Presenting at the annual Alumni <br/> Leadership Conference (ALC)</p>
                                     </div>
                                      
                                     
                         
                              </div>
                          </div>
                         
                          
                   </div>
        
                        </div>
                      
                   </div>

                 
               </div>

            
             
         </div>
  )
}
export default Alumni;