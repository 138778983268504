import React, { useState, useEffect } from "react";
import SignUpPage from "./SignUpPage";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../ApiServices/apiCallManager";
const cookies = new Cookies();

function LogInPage() {
  const navigate = useNavigate();
  const [showSignUp, setShowSignUp] = useState(false);
  const [closeLogIn, setCloseLogIn] = useState(true);
  const userData = {
    email: "",
    password: "",
  };
  const [loginUser, setLoginUser] = useState(userData);

  const handleChange = (e) => {
    loginUser[e.target.name] = e.target.value;
    setLoginUser({
      ...loginUser,
    });
  };
  useEffect(() => {
    userData.email="";
    userData.password="";
    const token = cookies.get("TOKEN");
    console.log(token)
    if (token !== undefined) {  
        apiCall("application/json","post","admin/user","validate-token",null,token).then((response) => {
          console.log(response);
          if (response.status === 200) {
            navigate("/admin");
          }

        })
        .catch((error) => {
          console.error("Error validating token:", error);
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loginUser.email || !loginUser.password|| loginUser.email.length<5||loginUser.password.length<5) {
      alert("please enter valid mail or password");
      return;
    }
    try{
      const res = await apiCall("application/json","post","admin/user","login",null,null,loginUser)
      if (res.status === 202) {
        cookies.set("TOKEN", res.data.token, {
          path: "/",
        });
        navigate("/admin");
      }
      else if (res.status === 401) {
        alert("invalid password or email");
      }
      else if (res.status === 500) {
        alert("Email does not exist");
      }
    }catch(e){
      if(e.message==="Request failed with status code 401"){
        alert("invalid email or password");
      }
      else if(e.message==="Request failed with status code 402"){
        alert("Email is not verfied");
      }
      else if(e.message==="Request failed with status code 400"){
        alert("invalid credentials");
      }
      else if(e.message==="Request failed with status code 403"){
        alert("Not authorized to login");
      }
    }
    
  }
  const signUpPopUpHandler = (e) => {
    window.location.pathname = "/sign-up";
    setShowSignUp(!showSignUp);
  };

  const forgotPassword = async () => {
    navigate("/reset/password");
  };

  return (
    <div>
      {closeLogIn && (
        <div>
          {showSignUp === false ? (
            <div className="absolute z-30 left-0">
              <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-800 opacity-75"></div>
              </div>
              <div className="fixed inset-0 z-40 overflow-hidden">
                <div className="">
                  <div className="pt-24 pb-5">
                    <div className="flex flex-col  justify-center mx-5 mb-5 space-y-8 sm:w-96 sm:m-auto">
                      <div className="flex flex-col p-6 relative space-y-6 bg-white rounded-lg shadow">
                        <form onSubmit={handleSubmit}>
                          <div className="space-x-2">
                            <div>
                              <h1 className=" text-4xl font-bold text-center text-yellow-500">
                                Log<span className="text-blue-500">In</span>
                              </h1>
                            </div>
                            <div>
                              <h1 className="mt-2 text-xl font-bold text-center">
                                Sign in to your account
                              </h1>
                            </div>
                          </div>
                          <div className="flex mt-4 flex-col space-y-1">
                            <input
                              type="text"
                              name="email"
                              value={loginUser.email}
                              onChange={handleChange}
                              className="w-full h-12 px-3 py-2 border-2 rounded-lg focus:outline-none focus:border-blue-400 focus:shadow"
                              placeholder="Email"
                            />
                          </div>

                          <div className="flex flex-col mt-4 space-y-2">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              value={loginUser.password}
                              onChange={handleChange}
                              className="w-full h-12 px-3 py-2 border-2 rounded-lg focus:outline-none focus:border-blue-400 focus:shadow"
                              placeholder="Password"
                            />
                          </div>

                          {/* <div className="relative space-x-2">
                            <input
                              type="checkbox"
                              name="remember"
                              id="remember"
                              checked
                              className="inline-block align-middle"
                            />
                            <label
                              className="inline-block align-middle"
                              for="remember"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className="flex items-center space-y-2 lg:flex-col flex-col sm:justify-between">
                            <div className="w-full">
                              {" "}
                              <button
                                type="submit"
                                className="w-full px-5 py-3 mt-3 font-bold text-white transition-colors bg-blue-500 rounded shadow focus:outline-none hover:bg-blue-700"
                              >
                                Log In
                              </button>
                            </div>
                            <div
                              onClick={() => forgotPassword()}
                              className="text-cmyellow-900 font-semibold hover:cursor-pointer"
                            >
                              Forgot your password?
                            </div>
                          </div>
                        </form>
                        <div className="w-full">
                          {" "}
                          <button
                            type="button"
                            className="w-full px-5 py-3 font-bold text-white transition-colors bg-green-500 rounded shadow focus:outline-none hover:bg-green-700"
                            onClick={signUpPopUpHandler}
                          >
                            Register
                          </button>
                        </div>

                        <div className="flex justify-center text-sm text-gray-500">
                          <p>&copy;2021. All right reserved.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <SignUpPage />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default LogInPage;
